import React, { useState } from "react";
import MapContainer from "./MapContainer";
import { Visibility } from "@mui/icons-material";
import { Button } from "@mui/material";

export const MapViewer = ({ onUpdateValues, longitude, latitude }) => {
  const [visible, setVisible] = useState(false);

  console.log(longitude, latitude);

  return (
    <div className="flexColumn gap20">
      <div>
        <Button
          onClick={() => setVisible(!visible)}
          variant="contained"
          disableElevation
          startIcon={<Visibility fontSize="small" />}
        >
          {visible ? "Hide" : "View"} Map
        </Button>
      </div>

      {visible && (
        <MapContainer
          onUpdateValues={onUpdateValues}
          longitude={longitude}
          latitude={latitude}
        />
      )}
    </div>
  );
};
