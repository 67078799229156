import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  COMMERCIAL_ROAD_USER,
  STATUS_APPROVED,
  STATUS_ASSESSED,
  STATUS_DEACTIVATED,
  STATUS_DEFFERED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
} from "../../constants/webapp";
import {
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import { ObjectionsButton } from "../trading-license/useTradingLicense";
import { Tooltip } from "antd";
import { DownloadLink } from "../../components/common/Button";
import {
  INVOICE_API,
  PARKS_API,
  ROUTES_API,
  STAGES_API,
  URA_VEHICLE_DETAILS_API,
  VEHICLE_ASSESSMENT_API,
  VEHICLE_CLASSIFICATION_API,
} from "../../constants/api";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";
import DetailsCard from "../../components/common/cards/DetailsCard";

const useUserParkFees = () => {
  const parkTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        owner_name: item?.owner_name,
        email: item?.email,
        mobile_number: item?.mobile_number,
        number_plate: item?.number_plate,
        owner_firstname: item?.owner_firstname,
        make_year: item?.make_year,
        model: item?.model,
        color: item?.color,
        engine_number: item?.engine_number,
        chasis_number: item?.chasis_number,
        tin: item?.tin,
        municipality_name: item?.municipality_name,
        division_name: item?.division_name,
        parish_name: item?.parish_name,
        village_name: item?.village_name,
        street_name: item?.street_name,
        vehicle_type: item?.vehicle_classification_name,
        park: item?.park,
        stage: item?.stage,
        route_name: item?.route_name,
        route_code: item?.route_code,
        status: item?.status_details,
        // route: (item?.route_category).replaceAll("_", " ").toUpperCase(),
        created_by: item?.created_by_name,
        assessments: (
          <Link
            to={`/assessments?tax_id=${item.id}&tax=park_user_fees`}
            style={{ color: configColor }}
          >
            Assessment
          </Link>
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id}&table_name=park_user_fees`,
                "certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${COMMERCIAL_ROAD_USER}/view/${item.id}`}
                state={{ tax: "commercial_vehicle" }}
              >
                <FaEye />
              </Link>
              <Visibility show={item?.status_name === "DEFERRED"}>
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${COMMERCIAL_ROAD_USER}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={["APPROVED", "PAID"].includes(item?.status_name)}
              >
                <Tooltip title="Amendment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/amendment/park_user_fees/${item.id}`}
                  >
                    <FaGem />
                  </Link>
                </Tooltip>

                <Tooltip title="Reassessment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/reassessment/park_user_fees/${item.id}`}
                  >
                    <FaMoneyBill />
                  </Link>
                </Tooltip>
                <Tooltip title="Business Transfer">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`../business-transfers/request/commercial-vehicles/${item.branch_code}`}
                  >
                    <FaAngleDoubleRight />
                  </Link>
                </Tooltip>

                {/* <ObjectionsButton
                  branch_code={item?.branch_code}
                  revenue_source="park_user_fees"
                />
                <Visibility show={item?.outstanding_balance == 0}>
                  <Tooltip title="Deactivate">
                    <Link
                      style={{ padding: 5, paddingBottom: 0 }}
                      class=" mr-2"
                      to={`${COMMERCIAL_ROAD_USER}/business_deactivation/${item.id}`}
                    >
                      <FaBan />
                    </Link>
                  </Tooltip>
                </Visibility> */}
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const stopOverViewData = (item) => {
    return {
      name: item?.name,
    };
  };

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
        "Branch Code": item?.branch_code,
      }),
      "Number Plate": item?.number_plate,
      "Tax Payer Name": item?.owner_name,
      Email: item?.email,
      "Mobile Number": item?.mobile_number,
      "Make Year": item?.make_year,
      Model: item?.model,
      Color: item?.color,
      "Engine number": item?.engine_number,
      "Chasis Number": item?.chasis_number,
      "Seating Capacity": item?.seating_capacity,
      TIN: item?.tin,
      "Driver Surname": item?.driver_surname,
      "Driver First Name": item?.driver_first_name,
      // ...returnObject(item?.driver_other_name, {
      "Driver Other Name": item?.driver_other_name,
      // }),
      "Driver Mobile Number": item?.driver_mobile_number,
      // ...returnObject(item?.driver_alternative_mobile_number, {
      "Driver Alternative Mobile Number":
        item?.driver_alternative_mobile_number,
      // }),
      "Municipality/City/District": item?.municipality_name
        ? item?.municipality_name
        : item?.municipality_details?.name,
      "Division/Town Council/Sub County": item?.division_name
        ? item?.division_name
        : item?.division_details?.name,
      "Parish/Ward": item?.parish_name
        ? item?.parish_name
        : item?.parish_details?.name,
      "Village/Cell/Zone": item?.village_name
        ? item?.village_name
        : item?.village_details?.name,
      Street: item?.street_name
        ? item?.street_name
        : item?.street_details?.name,
      Latitude: item?.r_lat,
      Longitude: item?.r_lng,
      "Vehicle Type": item?.vehicle_classification_name
        ? item?.vehicle_classification_name
        : item?.vehicle_classification_details?.name,
      Park: item?.park,
      Stage: item?.stage,
      "Route Name": item?.route_name
        ? item?.route_name
        : item?.route_details?.route_name,
      "Starting Division": item?.route_details?.start_division_name,
      "Ending Division": item?.route_details?.end_division_name,
      "Route Code": item?.route_code
        ? item?.route_code
        : item?.route_details?.route_code,
      Route: item?.route_category
        ? item?.route_category
        : item?.route_details?.route_category
            ?.replaceAll("_", " ")
            .toUpperCase(),
      "Stop Over Local Governments": (
        item?.stop_over_municipality_details || []
      ).map((it) => <DetailsCard viewData={stopOverViewData(it)} />),
      "Stop Over Divisions": (item?.stop_over_division_details || []).map(
        (it) => <DetailsCard viewData={stopOverViewData(it)} />
      ),

      ...returnObject(!isFormData, {
        Amount: Number(item?.amount)?.toLocaleString(),
      }),
      ...returnObject(!isFormData, {
        // outstanding_balance: Number(
        //   item?.outstanding_balance
        // )?.toLocaleString(),
        Status: item?.status_details,
      }),
      created_by: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    };
  };

  const parkTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "owner_name", header: "Tax Payer Name", filter: {} },
    { id: "mobile_number", header: "Mobile Number ", filter: {} },
    { id: "number_plate", header: "Number Plate ", filter: {} },
    { id: "make_year", header: "Make Year ", filter: {} },
    { id: "model", header: "Model", filter: {} },
    { id: "color", header: "Color", filter: {} },
    { id: "engine_number", header: "Engine Number", filter: {} },
    { id: "chasis_number", header: "Chasis Number", filter: {} },
    { id: "tin", header: "TIN", filter: {} },
    { id: "municipality_name", header: "Municipality ", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "parish_name", header: "Parish ", filter: {} },
    { id: "village_name", header: "Village", filter: {} },
    { id: "street_name", header: "Street ", filter: {} },
    {
      id: "vehicle_type",
      header: "Vehicle Type",
      filter: {},
    },
    { id: "park", header: "Park", filter: {} },
    { id: "stage", header: "Stage", filter: {} },
    // { id: "route_name", header: "Route", filter: {} },
    // { id: "route_code", header: "Route Code ", filter: {} },
    // { id: "route", header: "Route Category", filter: {} },
    // { id: "created_by", header: "Created By", filter: {} },
    // { id: "status", header: "Status", filter: {} },
    ...returnObject(status === STATUS_APPROVED, [
      { id: "assessments", header: "Invoices" },

      // { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_APPROVED}`;
        break;

      case STATUS_APPROVED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_APPROVED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_REJECTED}`;
        break;

      case STATUS_DEFFERED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_DEFFERED}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const reassessFields = [
    {
      id: "Route Name",
      field: "route",
      type: "select",
      options: "routes",
    },
    {
      id: "Vehicle Type",
      field: "vehicle_classification",
      type: "select",
      options: "vehicleTypes",
      props: { disabled: true },
    },
    {
      id: "Stop Over Local Governments",
      field: "stop_overs",
      type: "stop_overs",
    },
    {
      id: "Route Code",
      field: "route_code",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Route",
      field: "route_category",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Starting Division",
      field: "start_division",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Ending Division",
      field: "end_division",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Amount",
      field: "amount",
      type: "text",
      props: { readOnly: true },
    },
  ];

  const amendableFields = [
    {
      id: "Tax Payer Name",
      field: "owner_name",
      type: "text",
      props: { readOnly: true },
    },
    { id: "Email", field: "email", type: "text", props: { required: true } },
    {
      id: "Mobile Number",
      field: "mobile_number",
      type: "text",
      props: { required: true },
    },
    {
      id: "Number Plate",
      field: "number_plate",
      type: "text",
      props: { readOnly: true },
    },
    { id: "Surname", field: "owner_surname", type: "text" },
    { id: "First Name", field: "owner_firstname", type: "text" },
    {
      id: "Latitude",
      field: "r_lat",
      type: "text",
      props: { required: true },
    },
    {
      id: "Longitude",
      field: "r_lng",
      type: "text",
      props: { required: true },
    },
    {
      id: "Make Year",
      field: "make_year",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Model",
      field: "model",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Color",
      field: "color",
      type: "text",
      props: { required: true },
    },
    {
      id: "Engine number",
      field: "engine_number",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Chasis Number",
      field: "chasis_number",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Seating Capacity",
      field: "seating_capacity",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "TIN",
      field: "tin",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Vehicle Type",
      field: "vehicle_classification",
      type: "select",
      options: "vehicleTypes",
      props: { disabled: true },
    },
    {
      id: "Driver Surname",
      field: "driver_surname",
      type: "text",
      props: { required: true },
    },
    {
      id: "Driver First Name",
      field: "driver_first_name",
      type: "text",
      props: { required: true },
    },
    {
      id: "Driver Other Name",
      field: "driver_other_name",
      type: "text",
    },
    {
      id: "Driver Mobile Number",
      field: "driver_mobile_number",
      type: "text",
      props: { required: true },
    },
    {
      id: "Driver Alternative Mobile Number",
      field: "driver_alternative_mobile_number",
      type: "text",
    },
    {
      id: "Municipality/City/District",
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: "Division/Town Council/Sub County",
      field: "division",
      type: "select",
      options: "divisions",
    },
    {
      id: "Parish/Ward",
      field: "parish",
      type: "select",
      options: "parishes",
    },
    {
      id: "Village/Cell/Zone",
      field: "village",
      type: "select",
      options: "villages",
    },
    {
      id: "Street",
      field: "street",
      type: "select",
      options: "streets",
    },
    {
      id: "Park",
      field: "park",
      type: "select",
      options: "parks",
    },
    {
      id: "Stage",
      field: "stage",
      type: "select",
      options: "stages",
    },
  ];

  const stopOversViewData = (item) => ({
    Municipality: item.municipality_name,
    Division: item.division_name,
  });

  return {
    parkTableColumns,
    parkTableData,
    amendableFields,
    viewData,
    reassessFields,
    getRedirect,
    getActions,
    stopOversViewData,
  };
};

export const useParkUserFeesFormSelect = (form, updateFormValue) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();

  const { data: vehicleTypes, loadData: getVehicleTypes } = useGenericGet();
  const { data: parks, loadData: getParks } = useGenericGet();
  const { data: stages, loadData: getStages } = useGenericGet();
  const { data: routes, loadData: getRoute } = useGenericGet();
  const { data: amountPayable, loadData: getAmountPayable } = useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getVehicleTypes({ api: VEHICLE_CLASSIFICATION_API });
  }, []);

  useEffect(() => {
    if (form?.vehicle_classification) {
      vehicleTypes?.results?.map((item) => {
        if (item?.id === form?.vehicle_classification) {
          updateFormValue("class_code", item.class_code);
        }
      });
    }
  }, [vehicleTypes]);

  useEffect(() => {
    if (form.route_code && form?.class_code) {
      getAmountPayable({
        api: VEHICLE_ASSESSMENT_API,
        params: {
          vehicle_classification: form?.class_code,
          route_category: form?.route_category,
        },
      });
    }
  }, [form?.route_code, form?.class_code]);

  useEffect(() => {
    if (amountPayable) {
      amountPayable?.results?.map((item) => {
        updateFormValue("amount", item.amount);
      });
    }
  }, [amountPayable]);

  useEffect(() => {
    if (form.municipality_name) {
      getParks({
        api: PARKS_API,
        params: { municipality: form?.municipality },
      });
    }
  }, [form?.municipality_name]);

  useEffect(() => {
    if (form.park) {
      getStages({
        api: STAGES_API,
        params: { park: form.park },
      });
    }
  }, [form.park]);

  useEffect(() => {
    if (form?.vehicle_classification) {
      getRoute({
        api: ROUTES_API,
        // params: { id: form?.stage_name },
      });
    }
  }, [form?.vehicle_classification]);

  useEffect(() => {
    if (form?.route_name) {
      updateFormValue("route_number", form?.route_number);
    }
  }, [form?.route_name]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    routes: routes?.results || [],
    vehicleTypes: vehicleTypes?.results || [],
    parks: parks?.results || [],
    stages: stages?.results || [],
  };
};

export default useUserParkFees;
