import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  LOCAL_SERVICE_TAX,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_ASSESSED,
  STATUS_DEFFERED,
  MUN_NAME,
  DIV_NAME,
  PAR_NAME,
  VILL_NAME,
  STR_NAME,
  STATUS_DEACTIVATED,
  AUDIT_AUTHORITY,
} from "../../constants/webapp";
import {
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import useLstCategories from "../configurables/lst-categories/useLstCategories";
import useGenericGet from "../common/useGenericGet";
import { INVOICE_API, LST_NATURE_OF_BUSINESS_API } from "../../constants/api";
import useLocationData from "../common/useLocationData";
import { DownloadLink } from "../../components/common/Button";
import { Tooltip } from "antd";
import {
  sDivision,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
} from "../../components/layouts/LeftMenu";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { MakePaymentRevenueButton } from "../trading-license/useTradingLicense";
import { useAppContext } from "../../context/AppContext";

const useLocalServiceTax = () => {
  const { contextData } = useAppContext();
  const userRole = contextData?.role_name;
  const navigate = useNavigate();

  const Assess = (item) => {
    navigate(`/assessments?tax_id=${item.id}&tax=lst`, {
      state: { document_data: item },
    });
  };

  const localServiceTaxTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        tin: item?.tin,
        mobile_number: item?.mobile_number,
        email: item?.email,
        owner_name: item?.owner_name,
        nin: item?.nin,
        brn: item?.brn,
        trade_title: item?.trade_title,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        latitude: item?.r_lat,
        longitude: item?.r_lng,
        category: item?.category_name,
        nature: item?.nature_name,
        status: item?.status,
        created_by: item?.created_by_name,
        assessments: (
          <a
            class="mr-2"
            href=""
            style={{ color: configColor }}
            onClick={() => {
              Assess(item);
            }}
          >
            Assessment
          </a>
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=lst`,
                "Registration Certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        prn: (
          <MakePaymentRevenueButton
            api={LOCAL_SERVICE_TAX}
            id={item?.id}
            revenue_source={"lst"}
          />
        ),

        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${LOCAL_SERVICE_TAX}/view/${item.id}`}
                // state={{ tax: "lst" }}
              >
                <FaEye />
              </Link>
              <Visibility
                show={
                  item?.status_details === "DEFERRED" &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${LOCAL_SERVICE_TAX}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={
                  ["APPROVED", "PAID"].includes(item?.status_details) &&
                  userRole !== AUDIT_AUTHORITY
                }
              >
                {item?.active_status === 1 && status === "assessed" ? (
                  <>
                    <Tooltip title="Amendemt">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/amendment/lst/${item.id}`}
                      >
                        <FaGem />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Reassessment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/assessments?tax_id=${item.id}&tax=lst&has_arrears=${item.has_arrears_balance}&tax_api=${LOCAL_SERVICE_TAX}`}
                      >
                        <FaMoneyBill />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Business Transfer">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`../business-transfers/request/lsts/${item.branch_code}`}
                      >
                        <FaAngleDoubleRight />
                      </Link>
                    </Tooltip>

                    <Tooltip title="Objection">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/objection/lst/${item.id}`}
                      >
                        <PanTool sx={{ fontSize: 16 }} />
                      </Link>
                    </Tooltip>
                    <Visibility show={item?.outstanding_balance == 0}>
                      <Tooltip title="Deactivate">
                        <Link
                          style={{ padding: 5, paddingBottom: 0 }}
                          class=" mr-2"
                          to={`${LOCAL_SERVICE_TAX}/business_deactivation/${item.id}`}
                        >
                          <FaBan />
                        </Link>
                      </Tooltip>
                    </Visibility>
                  </>
                ) : null}
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      tin: item?.tin,
      nin: item?.cin,
      brn: item?.brn,
      business_name: item?.trade_title,
      mobile_number: item?.mobile_number,
      email: item?.email,
      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),
      ...returnObject(isFormData, {
        [sTaxpayerName]: !item?.is_individual
          ? item?.legal_name
          : `${item?.first_name} ${item?.surname}`,
      }),
      [sMunicipality]: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      [sDivision]: isFormData ? item?.division_name : item?.division_name,
      [sParish]: isFormData ? item?.parish_name : item?.parish_name,
      [sVillage]: isFormData ? item?.village_name : item?.village_name,
      [sStreet]: isFormData ? item?.street_name : item?.street_name,
      plot_no: item?.r_plot_no,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      category: isFormData ? item?.category_name : item?.category_name,
      nature: isFormData ? item?.nature_name : item?.nature_name,
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        // Status: item?.status_details,
      }),
      created_by: item?.created_by_name,
    };
  };

  const localServiceTaxTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "trade_title", header: "Business Name", filter: {} },
    { id: "mobile_number", header: "Mobile Number ", filter: {} },
    { id: "owner_name", header: "Taxpayer Name", filter: {} },
    { id: "nin", header: "NIN", filter: {} },
    { id: "brn", header: "BRN", filter: {} },
    { id: MUN_NAME, header: "Municipality ", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish ", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street ", filter: {} },
    { id: "category", header: "Category", filter: {} },
    { id: "nature", header: "Nature", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },
      { id: "prn", header: "PRNs" },

      { id: "certificate", header: "Registration Certificate" },
    ]),

    { id: "actions", header: "Actions" },
  ];

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${LOCAL_SERVICE_TAX}/${STATUS_SUBMITTED}`;
        break;

      case STATUS_DEFFERED:
        redirect = `${LOCAL_SERVICE_TAX}/${STATUS_DEFFERED}`;
        break;

      case STATUS_ASSESSED:
        redirect = `${LOCAL_SERVICE_TAX}/${STATUS_ASSESSED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${LOCAL_SERVICE_TAX}/${STATUS_REJECTED}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  const amendableFields = [
    ...taxpayerAmdendables,

    { id: "business_name", field: "trade_title", type: "text" },
    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    {
      id: "category",
      field: "category",
      options: "categories",
      type: "select",
    },
    {
      id: "nature",
      field: "nature",
      options: "natureofbusiness",
      type: "select",
    },
  ];

  const objectionFields = [
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "tin", field: "tin", type: "number" },
    { id: "nin", field: "nin", type: "text" },
    { id: "email", field: "email", type: "text" },

    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    {
      id: "category",
      field: "category",
      options: "categories",
      type: "select",
    },
    {
      id: "nature",
      field: "nature",
      options: "natureofbusiness",
      type: "select",
    },
  ];

  const reassessFields = [];

  return {
    localServiceTaxTableColumns,
    localServiceTaxTableData,
    viewData,
    getRedirect,
    getActions,
    amendableFields,
    objectionFields,
    reassessFields,
  };
};

export const useLocalServiceTaxFormSelect = (form) => {
  const { categories, getLstCateogories } = useLstCategories();
  const { data: natureofbusiness, loadData: getNatureOfBusiness } =
    useGenericGet();
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getLstCateogories();
  }, []);

  useEffect(() => {
    if (form.category) {
      getNatureOfBusiness({
        api: LST_NATURE_OF_BUSINESS_API,
        params: { lst_category: form.category },
      });
    }
  }, [form.category]);

  return {
    categories: categories || [],
    natureofbusiness: natureofbusiness?.results || [],
    districts,
    divisions,
    parishes,
    villages,
    streets,
  };
};

export default useLocalServiceTax;
