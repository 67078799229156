import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import useGenericGet from "../../hooks/common/useGenericGet";
import { PRN_STATUS_API } from "../../constants/api";
import { AlertMessage } from "../common/alert/AlertMessage";

export default function CheckPRNDialog() {
  const [prn, setPrn] = React.useState(null);

  const { contextData, updateContext } = useAppContext();
  var { openPRN } = contextData;
  const { loadData: getPRN, loading, data: prnData } = useGenericGet();

  const handleChange = (event) => {
    setPrn(event.target.value);
  };

  const setClose = () => {
    updateContext("openPRN", false);
  };

  const handleClose = () => {
    setClose();
  };

  // call api
  const handleCheck = () => {
    getPRN({ api: PRN_STATUS_API, params: { prn: prn } });
  };

  const status =
    prnData?.statusDesc === "AVAILABLE" ? "NOT PAID" : prnData?.statusDesc;

  return (
    <div>
      <Dialog open={openPRN} onClose={handleClose} sx={{ zIndex: 10000 }}>
        <DialogTitle>Check PRN Status</DialogTitle>
        <DialogContent>
          <p className="font13 greyColor">
            To check your payment status, please enter your (PR) number here.
            <br />
            We will send updates immediately.
          </p>
          <br />
          <TextField
            autoFocus
            margin="dense"
            id="prn"
            label="Payment Reference Number (PRN)"
            type="text"
            name="prn"
            fullWidth
            required
            onChange={handleChange}
          />

          {/* Status Details */}
          <div className="font13 flexCenter" style={{ marginTop: 10 }}>
            {/* Loading View */}
            {loading ? (
              <CircularProgress size="30px" />
            ) : (
              <AlertMessage
                width={400}
                message={prnData?.statusDesc? (prnData?.statusDesc === "AVAILABLE"? "NOT YET PAID": prnData?.statusDesc): prnData?.errorDesc}
                type={(prnData?.statusDesc && "success") || "error"}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCheck}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
