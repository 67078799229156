import React from "react";
import LogoComponent from "../../common/logo/LogoComponent";
import styled from "styled-components";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

export const SecondaryAppbar = () => {
  return (
    <Wrapper className="whiteBg">
      <div className="container flexSpaceCenter">
        <LogoComponent />
        <Link to="/">
          <Button
            type="primary"
            className="font12"
            icon={
              <ArrowBack
                sx={{ mr: 0.5, fontSize: 15, verticalAlign: "middle" }}
              />
            }
          >
            Home
          </Button>
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  box-shadow: 0px 1px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  z-index: 900;
`;
