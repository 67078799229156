import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useForm from "../../../hooks/common/useForm";
import useGenericGet from "../../../hooks/common/useGenericGet";
import {
  LHT_RATES_API,
  LHT_REASSESSMENT_API,
  LHT_ROOMS_API,
  LOCAL_HOTEL_TAX_API,
} from "../../../constants/api";
import Loader from "../../common/Loader";
import { SubmitButton } from "../../common/Button";
import TextArearInput from "../../common/TextArearInput";
import useGenericSet from "../../../hooks/common/useGenericSet";

const LocalHotelTaxAssessment = ({
  tax_id,
  formId,
  proceed,
  invoice_id,
  date,
}) => {
  const { form, onChange, updateFormValue, updateFormValues } = useForm();
  const { loadData: getLhtRooms, data: lht_rooms } = useGenericGet();
  const [rowCount, SetRowCount] = useState([]);
  const [rowDates, SetDates] = useState([]);
  const [lhtRooms, SetLhtRooms] = useState([]);

  const {
    loadData: getLocalHotelTax,
    data: localhoteltax,
    loading,
  } = useGenericGet();

  const { loadData: getLHTRates, data: lht_rates } = useGenericGet();
  const { submitting, error, uploadData, success, data } = useGenericSet();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const executive = lht_rates?.results?.filter(
    (item) => item.type === "executive"
  );
  const ordinary = lht_rates?.results?.filter(
    (item) => item.type === "ordinary"
  );
  const extra = lht_rates?.results?.filter((item) => item.type === "extra");
  const dollar = lht_rates?.results?.filter((item) => item.type === "dollar");

  useEffect(() => {
    getLHTRates({ api: LHT_RATES_API });
  }, []);

  useEffect(() => {
    getLocalHotelTax({ api: LOCAL_HOTEL_TAX_API, id: tax_id });

    if (invoice_id) {
      getLhtRooms({ api: LHT_ROOMS_API, params: { invoice_id: invoice_id } });
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (
      (form?.ordinary_total || 0) +
        (form?.executive_total || 0) +
        (form?.extra_total || 0) !==
      0
    ) {
      if (lht_rooms) {
        uploadData({
          api: LHT_REASSESSMENT_API,
          params: {
            new_amount:
              (form?.ordinary_total || 0) +
              (form?.executive_total || 0) +
              (form?.extra_total || 0),
            reassessment_items: form?.reassessment_items,
            reassessment_reason: form?.reassessment_reason,
            municipality: localhoteltax?.municipality_details?.id,
            division: localhoteltax?.division_details?.id,
            parish: localhoteltax?.parish_details?.id,
            village: localhoteltax?.village_details?.id,
            street: localhoteltax?.street_details?.id,
            invoice: invoice_id,
          },
          redirect: `/assessments?tax_id=${tax_id}&tax=lht`,
        });
      } else {
        proceed({
          amount:
            (form?.ordinary_total || 0) +
            (form?.executive_total || 0) +
            (form?.extra_total || 0),
          lht_rooms: lhtRooms,
        });
      }
    } else {
      alert("Invalid amount, please submit an amount greater than zero");
    }
  };

  const addRowTotal = (dollars, id, field, e) => {
    var values = {};
    var field_name = field + id;
    var total_name = "row_total_" + id;
    var ordinary_total = 0;
    var executive_total = 0;
    var extra_total = 0;
    var ordinary_no_rooms = 0;
    var executive_no_rooms = 0;
    var extra_no_rooms = 0;

    var current_value = e.target.value ? e.target.value : 0;

    values[field_name] = e.target.value;

    if (field === "row_ordinary_") {
      form?.reassessment_items?.map((item) => {
        if (item.key === id && item.room_type === "ordinary") {
          item.new_value = current_value;
        }
      });

      values[total_name] =
        parseInt(current_value) * ordinary?.[0]?.rate +
        parseInt(form["row_executive_" + id] || 0) * executive?.[0]?.rate +
        parseInt(form["row_extra_" + id] || 0) * extra?.[0]?.rate;
      rowCount.map((item) => {
        if (id === item) {
          ordinary_total =
            ordinary_total + parseInt(current_value) * ordinary?.[0]?.rate;
          ordinary_no_rooms = ordinary_no_rooms + parseInt(current_value);
        } else {
          ordinary_total =
            ordinary_total +
            parseInt(form["row_ordinary_" + item] || 0) * ordinary?.[0]?.rate;
          ordinary_no_rooms =
            ordinary_no_rooms + parseInt(form["row_ordinary_" + item] || 0);
        }
      });

      values["ordinary_total"] = ordinary_total;
      values["ordinary_no_rooms"] = ordinary_no_rooms;

      lhtRooms.map((item, key) => {
        if (id - 1 === key) {
          item.ordinary = parseInt(current_value);
        }
      });
    } else if (field === "row_executive_") {
      form?.reassessment_items?.map((item) => {
        if (item.key === id && item.room_type === "executive") {
          item.new_value = current_value;
        }
      });

      if (dollars === true) {
        values[total_name] =
          parseInt(form["row_ordinary_" + id] || 0) * ordinary?.[0]?.rate +
          parseInt(current_value) * dollar?.[0]?.rate * 3600 +
          parseInt(form["row_extra_" + id] || 0) * extra?.[0]?.rate;
      } else {
        values[total_name] =
          parseInt(form["row_ordinary_" + id] || 0) * ordinary?.[0]?.rate +
          parseInt(current_value) * executive?.[0]?.rate +
          parseInt(form["row_extra_" + id] || 0) * extra?.[0]?.rate;
      }
      rowCount.map((item) => {
        if (id === item) {
          executive_no_rooms = executive_no_rooms + parseInt(current_value);
          if (dollars === true) {
            executive_total =
              executive_total +
              parseInt(current_value) * dollar?.[0]?.rate * 3600;
          } else {
            executive_total =
              executive_total + parseInt(current_value) * executive?.[0]?.rate;
          }
        } else {
          executive_no_rooms =
            executive_no_rooms + parseInt(form["row_executive_" + item] || 0);
          if (dollars === true) {
            executive_total =
              executive_total +
              parseInt(form["row_executive_" + item] || 0) *
                dollar?.[0]?.rate *
                3600;
          } else {
            executive_total =
              executive_total +
              parseInt(form["row_executive_" + item] || 0) *
                executive?.[0]?.rate;
          }
        }
      });

      values["executive_total"] = executive_total;
      values["executive_no_rooms"] = executive_no_rooms;

      lhtRooms.map((item, key) => {
        if (id - 1 === key) {
          item.executive = parseInt(current_value);
        }
      });
    } else if (field === "row_extra_") {
      form?.reassessment_items?.map((item) => {
        if (item.key === id && item.room_type === "extra") {
          item.new_value = current_value;
        }
      });

      values[total_name] =
        parseInt(form["row_ordinary_" + id] || 0) * ordinary?.[0]?.rate +
        parseInt(form["row_executive_" + id] || 0) * executive?.[0]?.rate +
        parseInt(current_value) * extra?.[0]?.rate;
      rowCount.map((item) => {
        if (id === item) {
          extra_no_rooms = extra_no_rooms + parseInt(current_value);
          extra_total =
            extra_total + parseInt(current_value) * extra?.[0]?.rate;
        } else {
          extra_total =
            extra_total +
            parseInt(form["row_extra_" + item] || 0) * extra?.[0]?.rate;
          extra_no_rooms =
            extra_no_rooms + parseInt(form["row_extra_" + item] || 0);
        }
      });

      values["extra_total"] = extra_total;
      values["extra_no_rooms"] = extra_no_rooms;

      lhtRooms.map((item, key) => {
        if (id - 1 === key) {
          item.extra = parseInt(current_value);
        }
      });
    }

    updateFormValues(values);
  };

  useEffect(() => {
    const rows = [];
    const dates = [];
    const lht_rooms = [];

    var date = new Date(getDate());

    var new_date = new Date(date.getFullYear(), date.getMonth(), 1);

    for (let i = 1; i <= 31; i++) {
      if (new_date.getMonth() === date.getMonth()) {
        let year = new_date.getFullYear();
        let month = new_date.getMonth() + 1;
        let day = new_date.getDate();

        if (month < 10) {
          month = "0" + month;
        }

        if (day < 10) {
          day = "0" + day;
        }

        const current_date = year + "-" + month + "-" + day;
        new_date.setDate(new_date.getDate() + 1);

        var lht_room = {
          night: current_date,
          ordinary: 0,
          executive: 0,
          extra: 0,
        };

        rows.push(i);
        dates.push(current_date);
        lht_rooms.push(lht_room);
      }
    }

    SetRowCount([...rows]);
    SetDates([...dates]);
    SetLhtRooms([...lht_rooms]);
  }, []);

  useEffect(() => {
    if (lht_rooms) {
      var rooms = [];
      var values = {};
      var ordinary_total = 0;
      var executive_total = 0;
      var extra_total = 0;
      var ordinary_no_rooms = 0;
      var executive_no_rooms = 0;
      var extra_no_rooms = 0;
      var reassessment_items = [];

      lht_rooms?.map((item, index) => {
        var exec = {
          key: index + 1,
          night: item.night,
          room_type: "executive",
          old_value: item?.executive,
          new_value: item?.executive,
        };
        reassessment_items.push(exec);

        var ord = {
          key: index + 1,
          night: item.night,
          room_type: "ordinary",
          old_value: item?.ordinary,
          new_value: item?.ordinary,
        };
        reassessment_items.push(ord);

        var ext = {
          key: index + 1,
          night: item.night,
          room_type: "extra",
          old_value: item?.extra,
          new_value: item?.extra,
        };
        reassessment_items.push(ext);

        var room = {
          night: item.night,
          ordinary: item.ordinary,
          executive: item.executive,
          extra: item.extra,
        };

        rooms.push(room);

        values["row_ordinary_" + (index + 1)] = item.ordinary;
        values["row_extra_" + (index + 1)] = item.extra;
        values["row_executive_" + (index + 1)] = item.executive;
        values["row_total_" + (index + 1)] =
          localhoteltax?.classification_name !== "Hotel - 4 & 5 Star"
            ? parseInt(item.executive) * executive?.[0]?.rate +
              parseInt(item.extra) * extra?.[0]?.rate +
              parseInt(item.ordinary) * ordinary?.[0]?.rate
            : parseInt(item.executive) * dollar?.[0]?.rate * 3600;

        ordinary_total =
          ordinary_total + parseInt(item.ordinary) * ordinary?.[0]?.rate;
        ordinary_no_rooms = ordinary_no_rooms + parseInt(item.ordinary);
        executive_total =
          executive_total +
          parseInt(item.executive) *
            (item?.ordinary ? executive?.[0]?.rate : dollar?.[0]?.rate * 3600);
        executive_no_rooms = executive_no_rooms + parseInt(item.executive);
        extra_total = extra_total + parseInt(item.extra) * extra?.[0]?.rate;
        extra_no_rooms = extra_no_rooms + parseInt(item.extra);
      });

      values["ordinary_total"] = ordinary_total;
      values["ordinary_no_rooms"] = ordinary_no_rooms;
      values["executive_total"] = executive_total;
      values["executive_no_rooms"] = executive_no_rooms;
      values["extra_total"] = extra_total;
      values["extra_no_rooms"] = extra_no_rooms;
      values["reassessment_items"] = reassessment_items;

      updateFormValues(values);

      SetLhtRooms([...rooms]);
    }
  }, [lht_rooms]);

  function getDate() {
    let year = date?.slice(date.length - 4);
    let month_name = date?.slice(0, date.length - 4);

    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var month = months.indexOf(month_name) + 0;
    return new Date(year, month, 1);
  }

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Local Hotel Tax</div>
        </div>

        <div className="panel-body table-responsive">
          <table style={{ width: "100%" }}>
            {localhoteltax?.classification_name === "Hotel - 4 & 5 Star" ? (
              <tr>
                <th>
                  <p>#</p>
                </th>
                <th>
                  <p>
                    <b>Night</b>
                  </p>
                </th>
                <th style={{ textAlign: "center", marginRight: 10 }}>
                  <p>
                    <b>Number of Rooms Occupied</b>
                  </p>
                </th>
                <th>
                  <p>
                    <b>Line Total</b>
                  </p>
                </th>
              </tr>
            ) : (
              <tr>
                <th>
                  <p>#</p>
                </th>
                <th>
                  <p>
                    <b>Night</b>
                  </p>
                </th>
                <th style={{ textAlign: "center" }}>
                  <p>
                    <b>Above Shs. 50,000</b>
                  </p>
                </th>
                <th style={{ textAlign: "center" }}>
                  <p>
                    <b>Shs 10,000 up to 50,000</b>
                  </p>
                </th>
                <th style={{ textAlign: "center" }}>
                  <p>
                    <b>Below Shs. 10,000</b>
                  </p>
                </th>
                <th>
                  <p>
                    <b>Line Total</b>
                  </p>
                </th>
              </tr>
            )}

            {rowCount.map((item) => {
              return localhoteltax?.classification_name ===
                "Hotel - 4 & 5 Star" ? (
                <tr>
                  <td style={{ paddingLeft: 10, verticalAlign: "middle" }}>
                    <div className="form-group">
                      <p style={{ marginTop: 26 }}>{item}</p>
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10 }}>
                    <div className="form-group">
                      <FormInput
                        type="date"
                        value={rowDates[item - 1]}
                        disabled
                      />
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10 }}>
                    <div className="form-group">
                      <FormInput
                        type="number"
                        onChange={(e) =>
                          addRowTotal(true, item, "row_executive_", e)
                        }
                        value={form["row_executive_" + item]}
                        onWheelCapture={(e) => {
                          e.currentTarget.blur();
                        }}
                        required
                      />
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10, verticalAlign: "middle" }}>
                    <div className="form-group">
                      <p style={{ marginTop: 26 }}>
                        {form["row_total_" + item] || 0}
                      </p>
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td style={{ paddingLeft: 10, verticalAlign: "middle" }}>
                    <div className="form-group">
                      <p style={{ marginTop: 26 }}>{item}</p>
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10 }}>
                    <div className="form-group">
                      <FormInput
                        type="date"
                        value={rowDates[item - 1]}
                        disabled
                      />
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10 }}>
                    <div className="form-group">
                      <FormInput
                        type="number"
                        onChange={(e) =>
                          addRowTotal(false, item, "row_executive_", e)
                        }
                        value={form["row_executive_" + item]}
                        onWheelCapture={(e) => {
                          e.currentTarget.blur();
                        }}
                        required
                      />
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10 }}>
                    <div className="form-group">
                      <FormInput
                        type="number"
                        onChange={(e) =>
                          addRowTotal(false, item, "row_ordinary_", e)
                        }
                        value={form["row_ordinary_" + item]}
                        onWheelCapture={(e) => {
                          e.currentTarget.blur();
                        }}
                        required
                      />
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10 }}>
                    <div className="form-group">
                      <FormInput
                        type="number"
                        onChange={(e) =>
                          addRowTotal(false, item, "row_extra_", e)
                        }
                        value={form["row_extra_" + item]}
                        onWheelCapture={(e) => {
                          e.currentTarget.blur();
                        }}
                        required
                      />
                    </div>
                  </td>
                  <td style={{ paddingLeft: 10, verticalAlign: "middle" }}>
                    <div className="form-group">
                      <p style={{ marginTop: 26 }}>
                        {form["row_total_" + item] || 0}
                      </p>
                    </div>
                  </td>
                </tr>
              );
            })}

            {localhoteltax?.classification_name === "Hotel - 4 & 5 Star" ? (
              <tr>
                <td></td>
                <td style={{ textAlign: "center" }}>
                  <p>
                    <b>Totals</b>
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>
                  <p>
                    <b>{form?.executive_no_rooms || 0}</b>
                  </p>
                </td>
                <td style={{ paddingLeft: 10 }}>
                  <p>
                    <b>
                      {(form?.ordinary_total || 0) +
                        (form?.executive_total || 0) +
                        (form?.extra_total || 0) || 0}
                    </b>
                  </p>
                </td>
              </tr>
            ) : (
              <tr>
                <td></td>
                <td style={{ textAlign: "center" }}>
                  <p>
                    <b>Totals</b>
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>
                  <p>
                    <b>{form?.executive_no_rooms || 0}</b>
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>
                  <p>
                    <b>{form?.ordinary_no_rooms || 0}</b>
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>
                  <p>
                    <b>{form?.extra_no_rooms || 0}</b>
                  </p>
                </td>
                <td style={{ paddingLeft: 10 }}>
                  <p>
                    <b>
                      {(form?.ordinary_total || 0) +
                        (form?.executive_total || 0) +
                        (form?.extra_total || 0) || 0}
                    </b>
                  </p>
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
      {lht_rooms ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <TextArearInput
                label="Reassessment Reason"
                type="text"
                name="reassessment_reason"
                row="3"
                id="reassessment_reason"
                onChange={onChange}
                value={form?.reassessment_reason}
                error={error}
                required
              />
            </div>
          </div>

          <SubmitButton submitting={submitting} />
        </>
      ) : null}
    </form>
  );
};

LocalHotelTaxAssessment.propTypes = {
  tradinglicense: PropTypes.shape({}),
};

export default LocalHotelTaxAssessment;
