import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { configTertiary } from "../../styled/Theme";
import { numberFormatter } from "../../../utils/formatters";
import { TagSpan } from "../../elements/Styled";
import { useLocation } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: configTertiary,
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export function createData(name, registrations, assessments, collections) {
  return { name, registrations, assessments, collections };
}

export default function StatTable({ table }) {
  const { rows, totals, columns, ammend_row_keys, ammend_column_keys } = table;
  const location = useLocation();

  function checkColumn(key) {
    var visible = true;
    if (columns) {
      if (!columns.includes(key)) {
        visible = false;
      }
    }
    return visible;
  }

  function getAmmendKeys(row, key) {
    var ammendKey = "";
    if (ammend_row_keys) {
      ammendKey = row?.[ammend_row_keys[key]];
    }
    return ammendKey;
  }

  function getAmmendKeys(row, key) {
    var ammendKey = "";
    if (ammend_row_keys) {
      ammendKey = row?.[ammend_row_keys[key]];
    }
    return ammendKey;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 450 }} className="overflow">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {location?.pathname === "/reports/ifms-lgs" ? (
                <StyledTableCell>Local Governments</StyledTableCell>
              ) : (
                <StyledTableCell>Tax Head</StyledTableCell>
              )}
              {ammend_column_keys ? (
                <>
                  {ammend_column_keys.map((val, i) => (
                    <StyledTableCell align="right">
                      {val} {i !== 0 && <TagSpan light>UGX</TagSpan>}
                    </StyledTableCell>
                  ))}
                </>
              ) : (
                <>
                  {checkColumn("registrations") && (
                    <StyledTableCell align="right">
                      Registrations
                    </StyledTableCell>
                  )}

                  {checkColumn("assessments") && (
                    <StyledTableCell align="right">
                      Assessments <TagSpan light>UGX</TagSpan>
                    </StyledTableCell>
                  )}

                  {checkColumn("collections") && (
                    <StyledTableCell align="right">
                      Collections <TagSpan light>UGX</TagSpan>
                    </StyledTableCell>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {location?.pathname === "/reports/ifms-lgs"
                    ? row?.lg
                    : row.name}
                </StyledTableCell>

                {checkColumn("registrations") && (
                  <StyledTableCell align="right">
                    {numberFormatter(
                      getAmmendKeys(row, 0) || row?.registrations
                    )}
                  </StyledTableCell>
                )}
                {checkColumn("vote code") && location?.pathname === "/reports/ifms-lgs" && (
                  <StyledTableCell align="right">
                    {getAmmendKeys(row, 0) || row?.vote_code}
                  </StyledTableCell>
                )}
                {checkColumn("chart of accounts codes") && location?.pathname === "/reports/ifms" && (
                  <StyledTableCell align="right">
                    {getAmmendKeys(row, 0) || row?.account}
                  </StyledTableCell>
                )}
                {checkColumn("assessments") && (
                  <StyledTableCell align="right">
                    {numberFormatter(getAmmendKeys(row, 1) || row?.assessments)}
                  </StyledTableCell>
                )}

                {checkColumn("collections") && (
                  <StyledTableCell align="right">
                    {numberFormatter(getAmmendKeys(row, 2) || row?.collections)}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
          <TableHead sx={{ position: "sticky", bottom: 0, zIndex: 2 }}>
            <TableRow>
              <StyledTableCell component="th" scope="row" className="extraBold">
                Total
              </StyledTableCell>

              {checkColumn("registrations") && (
                <StyledTableCell align="right" className="extraBold">
                  {numberFormatter(totals?.registrations)}
                </StyledTableCell>
              )}
              {checkColumn("vote code") && location?.pathname === "/reports/ifms-lgs" && (
                <StyledTableCell align="right">
                </StyledTableCell>
              )}
              {checkColumn("chart of accounts codes") && location?.pathname === "/reports/ifms" && (
                <StyledTableCell align="right">
                  {numberFormatter(totals?.accounts)}
                </StyledTableCell>
              )}
              {checkColumn("assessments") && (
                <StyledTableCell align="right" className="extraBold">
                  {numberFormatter(totals?.assessments)}
                </StyledTableCell>
              )}
              {checkColumn("collections") && (
                <StyledTableCell align="right" className="extraBold">
                  {numberFormatter(totals?.collections)}
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </>
  );
}
