import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import useForm from "../../hooks/common/useForm";
import { DetailsTimeline } from "../pages/outputs/OutputDetails";
import { useState } from "react";

export const revenueSources = [
  { name: "Trade License", value: "tl" },
  { name: "Operational Permit", value: "op" },
];

export default function CertificateDetails() {
  const { form, onChange } = useForm();

  const { contextData, updateContext } = useAppContext();
  var { openCertificate: open } = contextData;
  const [reload, setReload] = useState(false);

  const setClose = () => {
    updateContext("openCertificate", false);
  };

  const handleClose = () => {
    setClose();
  };

  // call api
  const handleCheck = () => {
    setReload(!reload);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Check Certificate Details</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 1, minWidth: 300 }}>
            <InputLabel>Revenue Source</InputLabel>
            <Select
              value={form?.revenue_source}
              name="revenue_source"
              label="Revenue Source"
              required
              onChange={onChange}
            >
              {revenueSources?.map(({ name, value }) => (
                <MenuItem sx={{ fontSize: 14 }} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            sx={{ my: 3 }}
            autoFocus
            margin="dense"
            id="branch_code"
            label="Branch Code"
            type="text"
            name="branch_code"
            fullWidth
            required
            onChange={onChange}
          />

          <DetailsTimeline
            key={reload}
            source={form?.revenue_source}
            reload={reload}
            id={form?.branch_code}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCheck}>Check Status</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
