import React from "react";

const PeriodsList = React.lazy(() => import("./PeriodRegister"));
const SetPeriod = React.lazy(() => import("./SetPeriod"));

export const PeriodRoutes = [
  {
    path: "/periods",
    component: <PeriodsList />,
    exact: true,
  },
  {
    path: "/periods/create",
    component: <SetPeriod />,
    exact: true,
  },
];
