import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "./Button";
import { BrowserRouter } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    minWidth: "40%",
    minHeight: "20%",
    maxHeight: "90%",
    maxWidth: "90%",
    borderRadius: 15,
  },
  "& .MuiDialogContent-root": {
    padding: useTheme().spacing(2),
    width: "100%",
    height: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: useTheme().spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  // eslint-disable-next-line
  const { children, onClose, ...other } = props;

  return (
    // eslint-disable-next-line
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            // color: () => useTheme().palette.grey[500],
          }}
        >
          <CloseCircleOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const GenericModal = (props) => {
  const {
    show,
    dismiss,
    title,
    body,
    okText,
    cancelText,
    type,
    proceed,
    formId,
    hideActions,
  } = props;

  const onOkayClick = () => {
    if (!(type === "form" && formId)) {
      proceed();
    }
  };

  const renderBody = (ele, properties) =>
    ele ? cloneElement(ele, properties) : null;

  return (
    <BootstrapDialog onClose={dismiss} open={show}>
      {title ? (
        <BootstrapDialogTitle>
          {/* <div className="panel panel-blue">
            <div className="blue-title">
              <div className="panel-title">{title}</div>
            </div>
          </div> */}
          <div className="container">
            <h4>{title}</h4>
          </div>
        </BootstrapDialogTitle>
      ) : null}
      <BrowserRouter>
        {
          body ? <DialogContent dividers>{renderBody(body, props)}</DialogContent> : null
        }
      </BrowserRouter>
      {!hideActions ? (
        <DialogActions>
          <Button theme="danger" title={cancelText} onClick={dismiss} />

          <Button
            theme="success"
            title={okText}
            style={{ marginLeft: "1em" }}
            onClick={onOkayClick}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(formId ? { form: formId } : {})}
            type={type === "form" && formId ? "submit" : "button"}
          />

          <div style={{ clear: "both" }} />
        </DialogActions>
      ) : null}
    </BootstrapDialog>
  );
};

GenericModal.defaultProps = {
  okText: "Submit",
  cancelText: "Cancel",
  type: "confirm",
};

GenericModal.propTypes = {
  hideActions: PropTypes.bool,
  show: PropTypes.bool,
  dismiss: PropTypes.func,
  title: PropTypes.string.isRequired,
  body: PropTypes.node,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  type: PropTypes.string,
  proceed: PropTypes.func,
  formId: PropTypes.string,
};

export default GenericModal;
