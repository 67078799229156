import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.config.url !== "/api/auth/login/" &&
      error.response.status === 401
    ) {
      localStorage.removeItem("token");
      const loginUrl = "/login";
      if (window.location.href !== loginUrl) {
        window.location.href = loginUrl;
        return;
      }
    } else if (error.response && [402, 500].includes( error.response.status)) {
      return Promise.reject("Internal server error occured");
    }
    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  }
);

export default axiosInstance;
