import React, { useEffect, useState } from "react";
import { SecondaryAppbar } from "../../nav-bar/landing/SecondaryAppbar";
import { TimelineView } from "../../common/TimelineView";
import axios from "axios";
import { Alert } from "antd";
import Loader from "../../common/Loader";
import moment from "moment";
import styled from "styled-components";
import { BASE_API } from "../../../constants/api";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { Box } from "@mui/material";

const apis = {
  tl: "revenues/trade-licenses-validation/details/",
  op: "revenues/operational-permits-validation/details/",
};

export function getValidationUrl(source) {
  if (source && apis[source]) {
    return `${BASE_API}${apis[source]}`;
  }
}

export function useGetOutputDetails() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  function loadData({ source, id }) {
    const url = getValidationUrl(source);
    const params = { branch_code: id };
    setLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_IRAS_API_TOKEN}`,
        },
        params,
      })
      .then((response) => {
        setLoading(false);
        setData(response?.data);
      })
      .catch((err) => {
        setData({});
        setLoading(false);
        const error = err?.response?.data;
        if (error) {
          setError(error?.error || error?.detail || "An error occurred");
        }
      });
  }
  return { loadData, loading, data, error };
}

const OutputDetails = () => {
  const { loggedIn } = useAuth();
  const { source, id } = useParams();

  return (
    <div className="whiteBg radius6">
      <SecondaryAppbar />
      {!loggedIn && <Box height={70} />}
      <div className="container p20">
        <DetailsTimeline source={source} id={id} />
      </div>
    </div>
  );
};

export default OutputDetails;

export const DetailsTimeline = ({ source, id, reload }) => {
  const { fields } = useOutputDetails();
  const { loadData, loading, data, error } = useGetOutputDetails();

  useEffect(() => {
    loadData({ source, id });
  }, [reload]);

  if (Object.keys(data).length === 0 && !error) return;

  return (
    <Wrapper className="">
      {loading ? (
        <Loader />
      ) : (
        <div className="flexColumn gap20">
          {error ? (
            <Alert message={error} type="error" showIcon />
          ) : (
            <div id="timeline">
              <TimelineView
                data={fields({ data })}
                title="Certificate Details"
              />
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

function useOutputDetails() {
  const fields = ({ data }) => {
    data = {
      ...data,
      ...{
        registration_date: moment(data?.registration_date).format("YYYY-MM-DD"),
      },
    };
    return Object.keys(data).map((key) => {
      return { label: key, value: data[key] || "N/A" };
    });
  };
  return { fields };
}

const Wrapper = styled.div`
  width: 100%;
  // padding: 20px;

  #timeline {
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 20px;
    min-width: 500px;

    @media (max-width: 960px) {
      min-width: 200px;
    }
  }

  @media (max-width: 960px) {
    display: inherit;
  }
`;
