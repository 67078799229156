import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useForm from "../../../hooks/common/useForm";
import SelectInput from "../../common/SelectInput";
import useLocationData from "../../../hooks/common/useLocationData";

const StopOversForm = (props) => {
  const { formId, proceed } = props;
  const { form, updateFormValues } = useForm();

  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
  } = useLocationData();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({
      ...form,
    });
  };

  return (
    <form onSubmit={onSubmit} id={formId}>
      <div className="row">
        <div className="col-sm-12">
          <SelectInput
            label="Municipality"
            placeholder="Municipality"
            name="municipality"
            onChange={(value, obj) =>
              updateFormValues({
                municipality: value,
                municipality_name: obj?.label,
              })
            }
            options={districts.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={form?.municipality}
            required
          />
        </div>
        <div className="col-sm-12">
          <SelectInput
            label="Division"
            placeholder="Division"
            name="division"
            onChange={(value, obj) =>
              updateFormValues({
                division: value,
                division_name: obj?.label,
              })
            }
            options={divisions.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={form?.division}
            required
          />
        </div>
      </div>
    </form>
  );
};

StopOversForm.propTypes = {
  error: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  proceed: PropTypes.func.isRequired,
};

export default StopOversForm;
