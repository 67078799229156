import React from "react";
import { UserProfileView } from "../components/pages/users/UserProfileView";
import { LedgerGeneralView } from "../components/pages/payments/LedgerGeneralView";
import { loginPath, registerPath, otpPath, setPasswordPath } from "./paths";
import { LogoutRoute } from "../context/authContext";
import {
  CONST_REPORTS,
  STATUS_DEVELOPMENT_CONTROL,
  STATUS_IFMS,
  STATUS_IFMS_LG,
  STATUS_SUMMARY,
  TYPE_ADD,
  TYPE_EDIT,
  URL_PERMIT_QUESTION,
  URL_SITE_INSPECTION_ANSWER,
  URL_SITE_INSPECTION_QUESTION,
} from "./webapp";
import { DashboardContent } from "../components/pages/dashboard/DashboardContent";
import ValuationObjectionHandler from "../components/pages/valuation-objections/ValuationObjectionHandler";
import { PeriodRoutes } from "../components/pages/periods/PeriodRoutes";

const OutputDetails = React.lazy(
  () => import("../components/pages/outputs/OutputDetails")
);

const ArrearsManagementList = React.lazy(
  () => import("../components/pages/arrears-management/ArrearsManagementList")
);

const ArrearManagementDetails = React.lazy(
  () => import("../components/pages/arrears-management/ArrearManagementDetails")
);

const NewsRegister = React.lazy(
  () => import("../components/pages/news/NewsRegister")
);
const SetNews = React.lazy(() => import("../components/pages/news/SetNews"));
const NewsDetails = React.lazy(
  () => import("../components/pages/news/NewsDetails")
);
const TaxHeadsRegister = React.lazy(
  () => import("../components/pages/tax-heads/TaxHeadsRegister")
);
const TaxHeadDetails = React.lazy(
  () => import("../components/pages/tax-heads/TaxHeadDetails")
);
const SetTaxHead = React.lazy(
  () => import("../components/pages/tax-heads/SetTaxHead")
);

const News = React.lazy(() => import("../components/pages/news/News"));

const LogoSignatureRegister = React.lazy(
  () => import("../components/pages/logos-and-signatures/LogoSignatureRegister")
);
const LogoSignatureForm = React.lazy(
  () => import("../components/pages/logos-and-signatures/LogoSignatureForm")
);
const LogoSignatureDetails = React.lazy(
  () => import("../components/pages/logos-and-signatures/LogoSignatureDetails")
);
const ReturnPeriodsRegister = React.lazy(
  () => import("../components/pages/payments/ReturnPeriodsRegister")
);

const EnquiresFormExternal = React.lazy(
  () => import("../components/pages/enquiries/EnquiresFormExternal")
);

const ReportsSummaryFys = React.lazy(
  () => import("../components/pages/reports/ReportsSummaryFys")
);

const OntrFormExternal = React.lazy(
  () => import("../components/pages/ontr/OntrFormExternal")
);

const PafMultiple = React.lazy(
  () => import("../components/pages/billing/PafMultiple")
);

const AssessedReturnPeriods = React.lazy(
  () => import("../components/pages/payments/AssessedReturnPeriods")
);

const VehicleTaxRatesRegister = React.lazy(
  () => import("../components/pages/vehicle-tax-rates/VehicleTaxRatesRegister")
);
const SetVehicleTaxRates = React.lazy(
  () => import("../components/pages/vehicle-tax-rates/SetVehicleTaxRates")
);
const VehicleTaxRatesDetails = React.lazy(
  () => import("../components/pages/vehicle-tax-rates/VehicleTaxRatesDetails")
);

const SetVehiclePurpose = React.lazy(
  () =>
    import(
      "../components/pages/configurables/vehicle-purpose/SetVehiclePurpose"
    )
);
const VehiclePurposeDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/vehicle-purpose/VehiclePurposeDetails"
    )
);
const VehiclePurposeRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/vehicle-purpose/VehiclePurposeRegister"
    )
);

// Use React.lazy to dynamically import the components
const SetVehicleCategory = React.lazy(
  () =>
    import(
      "../components/pages/configurables/vehicle-category/SetVehicleCategory"
    )
);
const VehicleCategoryDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/vehicle-category/VehicleCategoryDetails"
    )
);
const VehicleCategoryRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/vehicle-category/VehicleCategoryRegister"
    )
);

const LGPostView = React.lazy(
  () => import("../components/pages/local-government-profile/LGPostView")
);

const TaxPayerRegister = React.lazy(
  () => import("../components/pages/taxpayer/TaxpayerRegister")
);

const LedgerDetails = React.lazy(
  () => import("../components/pages/payments/LedgerDetails")
);

const GeneralLedgerDetails = React.lazy(
  () => import("../components/pages/payments/GeneralLedgerDetails")
);

const ArrearsLedgerDetails = React.lazy(
  () => import("../components/pages/payments/ArrearsLedgerDetails")
);
const ReassessmentDetails = React.lazy(
  () => import("../components/pages/reassessments/ReassessmentDetails")
);

const ReassessmentRegister = React.lazy(
  () => import("../components/pages/reassessments/ReassessmentRegister")
);

const LhtReassessmentRegister = React.lazy(
  () => import("../components/pages/lht-reassessments/ReassessmentRegister")
);

const LhtReassessmentDetails = React.lazy(
  () => import("../components/pages/lht-reassessments/ReassessmentDetails")
);

const LstReassessmentRegister = React.lazy(
  () => import("../components/pages/lst-reassessments/ReassessmentRegister")
);

const LstReassessmentDetails = React.lazy(
  () => import("../components/pages/lst-reassessments/ReassessmentDetails")
);

const ReportsSummary = React.lazy(
  () => import("../components/pages/reports/ReportsSummary")
);
const RevenueForecast = React.lazy(
  () => import("../components/pages/reports/RevenueForecast")
);

const BudgetPerformance = React.lazy(
  () => import("../components/pages/reports/BudgetPerformance")
);

const InstantTinRegister = React.lazy(
  () => import("../components/pages/instant-tin/InstantTinRegister")
);
const EntityTypeRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/entity-settings/entity-types/EntityTypeRegister"
    )
);
const SetEntityType = React.lazy(
  () =>
    import(
      "../components/pages/configurables/entity-settings/entity-types/SetEntityType"
    )
);
const EntityTypeDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/entity-settings/entity-types/EntityTypeDetails"
    )
);
const EntitySubTypeRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/entity-settings/entity-sub-types/EntitySubTypeRegister"
    )
);
const SetEntitySubType = React.lazy(
  () =>
    import(
      "../components/pages/configurables/entity-settings/entity-sub-types/SetEntitySubType"
    )
);
const EntitySubTypeDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/entity-settings/entity-sub-types/EntitySubTypeDetails"
    )
);

const SetEconomicGrowth = React.lazy(
  () => import("../components/pages/economic-growth/SetEconomicGrowth")
);
const EconomicGrowthDetails = React.lazy(
  () => import("../components/pages/economic-growth/EconomicGrowthDetails")
);
const EconomicGrowthRegister = React.lazy(
  () => import("../components/pages/economic-growth/EconomicGrowthRegister")
);

const SetForecastConfig = React.lazy(
  () => import("../components/pages/forecast-config/SetForecastConfig")
);
const ForecastConfigDetails = React.lazy(
  () => import("../components/pages/forecast-config/ForecastConfigDetails")
);
const ForecastConfigRegister = React.lazy(
  () => import("../components/pages/forecast-config/ForecastConfigRegister")
);

const SetRevenueSourc = React.lazy(
  () => import("../components/pages/revenue-source/SetRevenueSource")
);
const RevenueSourcDetails = React.lazy(
  () => import("../components/pages/revenue-source/RevenueSourceDetails")
);
const RevenueSourcRegister = React.lazy(
  () => import("../components/pages/revenue-source/RevenueSourceRegister")
);

const SetRevenueShareRatio = React.lazy(
  () => import("../components/pages/revenue-share-ratio/SetRevenueShareRatio")
);
const RevenueShareRatioDetails = React.lazy(
  () =>
    import("../components/pages/revenue-share-ratio/RevenueShareRatioDetails")
);
const RevenueShareRatioRegister = React.lazy(
  () =>
    import("../components/pages/revenue-share-ratio/RevenueShareRatioRegister")
);

const DemandDateRegister = React.lazy(
  () =>
    import("../components/pages/revenue-sources/demand-date/DemandDateRegister")
);
const SetDemandDate = React.lazy(
  () => import("../components/pages/revenue-sources/demand-date/SetDemandDate")
);
const DemandDateDetails = React.lazy(
  () =>
    import("../components/pages/revenue-sources/demand-date/DemandDateDetails")
);

const LocalGovernmentProfileRegister = React.lazy(
  () =>
    import(
      "../components/pages/local-government-profile/LocalGovernmentProfileRegister"
    )
);

const SetLocalGovernmentProfile = React.lazy(
  () =>
    import(
      "../components/pages/local-government-profile/SetLocalGovernmentProfile"
    )
);

const LocalGovernmentProfilePosts = React.lazy(
  () =>
    import(
      "../components/pages/local-government-profile/LocalGovernmentProfilePosts"
    )
);

const SetInstantTinForm = React.lazy(
  () => import("../components/pages/instant-tin/SetInstantTinForm")
);

const ProfileTypesRegister = React.lazy(
  () =>
    import(
      "../components/pages/local-government-profile/profile-types/ProfileTypesRegister"
    )
);

const SetProfileTypes = React.lazy(
  () =>
    import(
      "../components/pages/local-government-profile/profile-types/SetProfileTypes"
    )
);

const AmendmentDetails = React.lazy(
  () => import("../components/pages/amendments/AmendmentDetails")
);

const SetRevenueBudget = React.lazy(
  () => import("../components/pages/revenue-budget/SetRevenueBudget")
);

//
const VoteCodesRegister = React.lazy(
  () => import("../components/pages/vote-codes/VoteCodesRegister")
);
const SetVoteCodes = React.lazy(
  () => import("../components/pages/vote-codes/SetVoteCodes")
);

const RevenueBudgetRegister = React.lazy(
  () => import("../components/pages/revenue-budget/RevenueBudgetRegister")
);

const RevenueBudgetDetails = React.lazy(
  () => import("../components/pages/revenue-budget/RevenueBudgetDetails")
);
const LstCategoriesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lst-categories/LstCategoriesDetails"
    )
);

const TradeLicenseAssessment = React.lazy(
  () => import("../components/pages/trade-license/TradeLicenseAssessment")
);

const SetPermitQuestion = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/permit-questions/SetPermitQuestion"
    )
);
const PermitQuestionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/permit-questions/PermitQuestionDetails"
    )
);

const AdvertisementClassificationRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/advertisements/AdvertisementClassificationRegister"
    )
);
const AdvertisementClassificationDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/advertisements/AdvertisementClassificationDetails"
    )
);
const SetAdvertisementClassification = React.lazy(
  () =>
    import(
      "../components/pages/configurables/advertisements/SetAdvertisementClassification"
    )
);

//Scrutiny Workflows
const ScrutinyWorkflowRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/scrutiny-workflows/ScrutinyWorkflowRegister"
    )
);
const SetScrutinyWorkflow = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/scrutiny-workflows/SetScrutinyWorkflow"
    )
);
const ScrutinyWorkflowDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/scrutiny-workflows/ScrutinyWorkflowDetails"
    )
);

//Scrutiny Workflow Config
const ScrutinyWorkflowConfigRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/scrutiny-workflow-config/ScrutinyWorkflowConfigRegister"
    )
);
const SetScrutinyWorkflowConfig = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/scrutiny-workflow-config/SetScrutinyWorkflowConfig"
    )
);
const ScrutinyWorkflowConfigDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/scrutiny-workflow-config/ScrutinyWorkflowConfigDetails"
    )
);

const AddServiceActors = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/scrutiny-workflow-config/AddServiceActors"
    )
);

//DEVELOPMENT CONTROL ROLES
const DevelopmentControlRolesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/development-control-roles/DevelopmentControlRolesRegister"
    )
);

const SetDevelopmentControlRoles = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/development-control-roles/SetDevelopmentControlRoles"
    )
);

const DevelopmentControlRolesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/development-control-roles/DevelopmentControlRolesDetails"
    )
);

const AddActors = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/development-control-roles/AddActors"
    )
);

const MultipleWorkflowsForm = React.lazy(
  () =>
    import(
      "../components/pages/configurables/scrutiny/development-control-roles/MultipleWorkflowsForm"
    )
);

//SITE INSPECTION QUESTION
const SiteInspectionQuestionsRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/site-inspection-questions/SiteInspectionQuestionsRegister"
    )
);
const SetSiteInspectionQuestion = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/site-inspection-questions/SetSiteInspectionQuestion"
    )
);
const SiteInspectionQuestionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/site-inspection-questions/SiteInspectionQuestionDetails"
    )
);

//SITE INSPECTION ANSWERS
const SiteInspectionAnswersRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/site-inspection-answers/SiteInspectionAnswersRegister"
    )
);
const SetSiteInspectionAnswer = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/site-inspection-answers/SetSiteInspectionAnswer"
    )
);
const SiteInspectionAnswerDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/site-inspection-answers/SiteInspectionAnswerDetails"
    )
);

const OutdoorRateRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/outdoor-rates/OutdoorRateRegister"
    )
);
const OutdoorRatesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/outdoor-rates/OutdoorRatesDetails"
    )
);
const SetOutdoorRates = React.lazy(
  () =>
    import("../components/pages/configurables/outdoor-rates/SetOutdoorRates")
);
const SetNatureOfMarket = React.lazy(
  () =>
    import(
      "../components/pages/configurables/nature-of-business/SetNatureofMarket"
    )
);
const NatureofMarketRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/nature-of-business/NatureofMarketRegister"
    )
);
const NatureOfMarketDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/nature-of-business/NatureofMarketDetails"
    )
);

const SetTenderer = React.lazy(
  () => import("../components/pages/tenderer/SetTenderer")
);

const TendererRegister = React.lazy(
  () => import("../components/pages/tenderer/TendererRegister")
);
const TendererDetails = React.lazy(
  () => import("../components/pages/tenderer/TendererDetails")
);

const SetOperationalPermitSubCategories = React.lazy(
  () =>
    import(
      "../components/pages/operational-permit-sub-categories/SetOperationalPermitSubCategories"
    )
);

const OperationalPermitSubCategoriesRegister = React.lazy(
  () =>
    import(
      "../components/pages/operational-permit-sub-categories/OperationalPermitSubCategoriesRegister"
    )
);
const OperationalPermitSubCategoriesDetails = React.lazy(
  () =>
    import(
      "../components/pages/operational-permit-sub-categories/OperationalPermitSubCategoriesDetails"
    )
);

const SetOperationalPermitCategories = React.lazy(
  () =>
    import(
      "../components/pages/operational-permit-categories/SetOperationalPermitCategories"
    )
);

const OperationalPermitCategoriesRegister = React.lazy(
  () =>
    import(
      "../components/pages/operational-permit-categories/OperationalPermitCategoriesRegister"
    )
);
const OperationalPermitCategoriesDetails = React.lazy(
  () =>
    import(
      "../components/pages/operational-permit-categories/OperationalPermitCategoriesDetails"
    )
);

const SetVehicleClassification = React.lazy(
  () =>
    import(
      "../components/pages/vehicle-classification/SetVehicleClassification"
    )
);

const VehicleClassificationRegister = React.lazy(
  () =>
    import(
      "../components/pages/vehicle-classification/VehicleClassificationRegister"
    )
);
const VehicleClassificationDetails = React.lazy(
  () =>
    import(
      "../components/pages/vehicle-classification/VehicleClassificationDetails"
    )
);

const SetVehicleAssessment = React.lazy(
  () => import("../components/pages/vehicle-assessment/SetVehicleAssessment")
);

const VehicleAssessmentRegister = React.lazy(
  () =>
    import("../components/pages/vehicle-assessment/VehicleAssessmentRegister")
);
const VehicleAssessmentDetails = React.lazy(
  () =>
    import("../components/pages/vehicle-assessment/VehicleAssessmentDetails")
);

const SetLstCategory = React.lazy(
  () =>
    import("../components/pages/configurables/lst-categories/SetLstCategory")
);
const LstCategoryRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lst-categories/LstCategoryRegister"
    )
);
const LstCategoryDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lst-categories/LstCategoriesDetails"
    )
);

const SetLstNatureOfBusiness = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lst-nature-of-business/SetLstNatureOfBusiness"
    )
);
const LstNatureOfBusinessRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lst-nature-of-business/LstNatureOfBusinessRegister"
    )
);
const LstNatureOfBusinessDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lst-nature-of-business/LstNatureOfBusinessDetails"
    )
);

const PermitQuestionsRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/permit-questions/PermitQuestionsRegister"
    )
);

const Landing = React.lazy(() => import("../components/pages/Landing"));
const Dashboard = React.lazy(
  () => import("../components/pages/dashboard/Dashboard")
);
const Login = React.lazy(() => import("../components/pages/login/Login"));
const HorizontalLinearStepper = React.lazy(
  () => import("../components/pages/registration/Stepper")
);
const ChangePasswordForm = React.lazy(
  () => import("../components/pages/users/ChangePasswordForm")
);
const SetUser = React.lazy(() => import("../components/pages/users/SetUser"));
const UserDetails = React.lazy(
  () => import("../components/pages/users/UserDetails")
);
const UsersRegister = React.lazy(
  () => import("../components/pages/users/UserRegister")
);
const LocalGovernmentsRegister = React.lazy(
  () => import("../components/pages/local-governments/LocalGovernmentsRegister")
);
const SetLocalGovernment = React.lazy(
  () => import("../components/pages/local-governments/SetLocalGovernment")
);
const LocalGovernmentsDetails = React.lazy(
  () => import("../components/pages/local-governments/LocalGovernmentsDetails")
);

const NatureOfBusinessRegister = React.lazy(
  () =>
    import("../components/pages/nature-of-business/NatureOfBusinessRegister")
);
const NatureOfBusinessDetails = React.lazy(
  () => import("../components/pages/nature-of-business/NatureOfBusinessDetails")
);
const SetNatureOfBusiness = React.lazy(
  () => import("../components/pages/nature-of-business/SetNatureOfBusiness")
);
const RevenueSourcesRegister = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-category/RevenueCategoryRegister"
    )
);
const SetRevenueSource = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-category/SetRevenueCategory"
    )
);

const SetMarkets = React.lazy(
  () => import("../components/pages/markets/SetMarkets")
);
const MarketsDetails = React.lazy(
  () => import("../components/pages/markets/MarketsDetails")
);

const MarketsRegister = React.lazy(
  () => import("../components/pages/markets/MarketsRegister")
);

const SetRevenueCategory = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-category/SetRevenueCategory"
    )
);
const RevenueCategoryDetails = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-category/RevenueCategoryDetails"
    )
);

const RevenueCategoryRegister = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-category/RevenueCategoryRegister"
    )
);

const SetRevenueSourceItems = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-source-items/SetRevenueSourceItems"
    )
);
const RevenueSourceItemsDetails = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-source-items/RevenueSourceItemsDetails"
    )
);

const RevenueSourceItemsRegister = React.lazy(
  () =>
    import(
      "../components/pages/revenue-sources/revenue-source-items/RevenueSourceItemsRegister"
    )
);

const SetTendererRevenueSourceItem = React.lazy(
  () =>
    import(
      "../components/pages/tenderer-revenue-source-item/SetTendererRevenueSourceItem"
    )
);
const TendererRevenueSourceItemDetails = React.lazy(
  () =>
    import(
      "../components/pages/tenderer-revenue-source-item/TendererRevenueSourceItemDetails"
    )
);

const TendererRevenueSourceItemRegister = React.lazy(
  () =>
    import(
      "../components/pages/tenderer-revenue-source-item/TendererRevenueSourceItemRegister"
    )
);
const SetMarketDuesItem = React.lazy(
  () => import("../components/pages/market-dues-items/SetMarketDuesItem")
);
const MarketDuesItemsDetails = React.lazy(
  () => import("../components/pages/market-dues-items/MarketDuesItemsDetails")
);

const MarketDuesItemsRegister = React.lazy(
  () => import("../components/pages/market-dues-items/MarketDuesItemsRegister")
);

const SetEnquiries = React.lazy(
  () => import("../components/pages/enquiries/SetEnquiries")
);
const EnquiriesDetails = React.lazy(
  () => import("../components/pages/enquiries/EnquiriesDetails")
);

const EnquiriesRegister = React.lazy(
  () => import("../components/pages/enquiries/EnquiriesRegister")
);

const AssignedEnquiries = React.lazy(
  () => import("../components/pages/enquiries/AssignedEnquires")
);

const UserGrievancesRegister = React.lazy(
  () => import("../components/pages/enquiries/UserGrievancesRegister")
);

const OverdueRegister = React.lazy(
  () => import("../components/pages/enquiries/OverdueRegister")
);

const SetAssignQuery = React.lazy(
  () => import("../components/pages/enquiries/SetAssignQuery")
);

const SetReportQuery = React.lazy(
  () => import("../components/pages/enquiries/SetReportQuery")
);

const SetOntr = React.lazy(() => import("../components/pages/ontr/SetOntr"));
const OntrDetails = React.lazy(
  () => import("../components/pages/ontr/OntrDetails")
);
const OntrRegister = React.lazy(
  () => import("../components/pages/ontr/OntrRegister")
);

const SetMarketStockTypes = React.lazy(
  () => import("../components/pages/market-stock-types/SetMarketStockTypes")
);
const MarketStockTypesDetails = React.lazy(
  () => import("../components/pages/market-stock-types/MarketStockTypesDetails")
);
const MarketStockTypesRegister = React.lazy(
  () =>
    import("../components/pages/market-stock-types/MarketStockTypesRegister")
);
const SetPermission = React.lazy(
  () => import("../components/pages/permissions/SetPermission")
);
const PermissionRegister = React.lazy(
  () => import("../components/pages/permissions/PermissionRegister")
);
const PermissionDetails = React.lazy(
  () => import("../components/pages/permissions/PermissionDetails")
);
const MarketDuesRatesDetails = React.lazy(
  () => import("../components/pages/market-dues-rates/MarketDuesRateDetails")
);
const SetMarketDuesRates = React.lazy(
  () => import("../components/pages/market-dues-rates/SetMarketDuesRates")
);
const MarketDuesRateRegister = React.lazy(
  () => import("../components/pages/market-dues-rates/MarketDuesRateRegister")
);
const SetMarketFacilityTypes = React.lazy(
  () =>
    import("../components/pages/market-facilities-types/SetMarketFacilityTypes")
);
const MarketFacilitiesTypesDetails = React.lazy(
  () =>
    import(
      "../components/pages/market-facilities-types/MarketFacilitiesTypesDetails"
    )
);
const MarketFacilityTypesRegister = React.lazy(
  () =>
    import(
      "../components/pages/market-facilities-types/MarketFacilityTypesRegister"
    )
);

const AdhocReports = React.lazy(
  () => import("../components/pages/reports/AdhocReports")
);
const SetMarketFacilities = React.lazy(
  () => import("../components/pages/market-facilities/SetMarketFacilities")
);
const MarketFacilitiesDetails = React.lazy(
  () => import("../components/pages/market-facilities/MarketFacilitiesDetails")
);
const MarketFacilitiesRegister = React.lazy(
  () => import("../components/pages/market-facilities/MarketFacilitiesRegister")
);

const RoleDetails = React.lazy(
  () => import("../components/pages/roles/RoleDetails")
);
const RoleRegister = React.lazy(
  () => import("../components/pages/roles/RoleRegister")
);
const SetRole = React.lazy(() => import("../components/pages/roles/SetRole"));

const SetVehicleOpertationCategory = React.lazy(
  () =>
    import(
      "../components/pages/vehicle-operation-category/SetVehicleOperationCategory"
    )
);
const VehicleOperationCategoryDetails = React.lazy(
  () =>
    import(
      "../components/pages/vehicle-operation-category/VehicleOperationCategoryDetails"
    )
);
const VehicleOperationCategoryRegister = React.lazy(
  () =>
    import(
      "../components/pages/vehicle-operation-category/VehicleOperationCategoryRegister"
    )
);
const SetParks = React.lazy(() => import("../components/pages/parks/SetParks"));
const ParksDetails = React.lazy(
  () => import("../components/pages/parks/ParksDetails")
);
const ParksRegister = React.lazy(
  () => import("../components/pages/parks/ParksRegister")
);
const BusinessCategoriesRegister = React.lazy(
  () =>
    import("../components/pages/business-categories/BusinessCategoriesRegister")
);
const SetBusinessCategory = React.lazy(
  () => import("../components/pages/business-categories/SetBusinessCategory")
);
const BusinessCategoryDetails = React.lazy(
  () =>
    import("../components/pages/business-categories/BusinessCategoryDetails")
);
const HotelClassificationRegister = React.lazy(
  () =>
    import(
      "../components/pages/hotel-classification/HotelClassificationRegister"
    )
);
const SetHotelClassification = React.lazy(
  () =>
    import("../components/pages/hotel-classification/SetHotelClassification")
);
const HotelClassificationDetails = React.lazy(
  () =>
    import(
      "../components/pages/hotel-classification/HotelClassificationDetails"
    )
);
const HotelFacilityRatingRegister = React.lazy(
  () =>
    import(
      "../components/pages/hotel-facility-rating/HotelFacilityRatingRegister"
    )
);
const SetHotelFacilityRating = React.lazy(
  () =>
    import("../components/pages/hotel-facility-rating/SetHotelFacilityRating")
);
const SetTradingLicense = React.lazy(
  () => import("../components/pages/trade-license/SetTradingLicense")
);
const TradingLicenseDetails = React.lazy(
  () => import("../components/pages/trade-license/TradeLicenseDetails")
);
const TradingLicenseRegister = React.lazy(
  () => import("../components/pages/trade-license/TradingLicenseRegister")
);
const TradingLicenseBusinessDeactivation = React.lazy(
  () => import("../components/pages/trade-license/BusinessDeactivation")
);

const ParkUserFeesBusinessDeactivation = React.lazy(
  () => import("../components/pages/park-user-fees/BusinessDeactivation")
);

const HotelFacilityRatingDetails = React.lazy(
  () =>
    import(
      "../components/pages/hotel-facility-rating/HotelFacilityRatingDetails"
    )
);
const SetLocalHotelTax = React.lazy(
  () => import("../components/pages/local-hotel-tax/SetLocalHotelTax")
);
const LocalHotelTaxDetails = React.lazy(
  () => import("../components/pages/local-hotel-tax/LocalHotelTaxDetails")
);
const LocalHotelTaxRegister = React.lazy(
  () => import("../components/pages/local-hotel-tax/LocalHotelTaxRegister")
);
const LocalHotelBusinessDeactivation = React.lazy(
  () => import("../components/pages/local-hotel-tax/BusinessDeactivation")
);

const SetLocalServiceTax = React.lazy(
  () => import("../components/pages/local-service-tax/SetServiceTax")
);
const LocalServiceTaxDetails = React.lazy(
  () => import("../components/pages/local-service-tax/LocalServiceTaxDetails")
);
const LocalServiceTaxRegister = React.lazy(
  () => import("../components/pages/local-service-tax/LocalServiceTaxRegister")
);
const LocalServiceTaxBusinessDeactivation = React.lazy(
  () => import("../components/pages/local-service-tax/BusinessDeactivation")
);

const LHTRatesRegister = React.lazy(
  () => import("../components/pages/lht-rates/LHTRatesRegister")
);
const SetLHTRates = React.lazy(
  () => import("../components/pages/lht-rates/SetLHTRates")
);
const LHTRatesDetails = React.lazy(
  () => import("../components/pages/lht-rates/LHTRatesDetails")
);

const LSTRatesRegister = React.lazy(
  () => import("../components/pages/configurables/lst-rates/LSTRatesRegister")
);
const SetLSTRates = React.lazy(
  () => import("../components/pages/configurables/lst-rates/SetLSTRates")
);
const LSTRatesDetails = React.lazy(
  () => import("../components/pages/configurables/lst-rates/LSTRatesDetails")
);

const SetOperationalPermit = React.lazy(
  () => import("../components/pages/operational-permit/SetOperationalPermit")
);
const OperationalPermitRegister = React.lazy(
  () =>
    import("../components/pages/operational-permit/OperationalPermitRegister")
);
const OperationalPermitDetails = React.lazy(
  () =>
    import("../components/pages/operational-permit/OperationalPermitDetails")
);
const OperationalPermitBusinessDeactivation = React.lazy(
  () => import("../components/pages/operational-permit/BusinessDeactivation")
);

const LandUseRegister = React.lazy(
  () => import("../components/pages/land-use/LandUseRegister")
);
const SetLandUse = React.lazy(
  () => import("../components/pages/land-use/SetLandUse")
);
const LandUseDetails = React.lazy(
  () => import("../components/pages/land-use/LandUseDetails")
);

const AllConfigurablesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/all-configurables/AllConfigurablesRegister"
    )
);
const SetAllConfigurables = React.lazy(
  () =>
    import(
      "../components/pages/configurables/all-configurables/SetAllConfigurables"
    )
);
const AllConfigurablesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/all-configurables/AllConfigurablesDetails"
    )
);

const ProfessionalsRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/professionals/ProfessionalsRegister"
    )
);
const SetProfessional = React.lazy(
  () =>
    import("../components/pages/configurables/professionals/SetProfessional")
);
const ProfessionalDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/professionals/ProfessionalDetails"
    )
);

const RatesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/self-employment-rates/RatesRegister"
    )
);
const SetRate = React.lazy(
  () =>
    import("../components/pages/configurables/self-employment-rates/SetRates")
);
const RateDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/self-employment-rates/RateDetails"
    )
);

const YearlyRatesRegister = React.lazy(
  () => import("../components/pages/configurables/annual-rates/RatesRegister")
);
const SetYearlyRates = React.lazy(
  () => import("../components/pages/configurables/annual-rates/SetRates")
);
const YearlyRateDetails = React.lazy(
  () => import("../components/pages/configurables/annual-rates/RateDetails")
);

const PlanningRatesRegister = React.lazy(
  () => import("../components/pages/configurables/planning-rates/RatesRegister")
);
const SetPlanningRates = React.lazy(
  () => import("../components/pages/configurables/planning-rates/SetRates")
);
const PlanningRateDetails = React.lazy(
  () => import("../components/pages/configurables/planning-rates/RateDetails")
);

const EscalationPerodsRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/escalation-periods/PeriodRegister"
    )
);
const SetEscalationPerods = React.lazy(
  () => import("../components/pages/configurables/escalation-periods/SetPeriod")
);
const EscalationPerodDetails = React.lazy(
  () =>
    import("../components/pages/configurables/escalation-periods/PeriodDetails")
);

const RateablePercentagesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/rateable-percentages/RateablePercentagesRegister"
    )
);
const SetRateablePercentages = React.lazy(
  () =>
    import(
      "../components/pages/configurables/rateable-percentages/SetRateablePercentages"
    )
);
const RateablePercentagesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/rateable-percentages/RateablePercentagesDetails"
    )
);

const GrossValuePercentagesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/gross-value-percentages/GrossValuePercentagesRegister"
    )
);
const SetGrossValuePercentages = React.lazy(
  () =>
    import(
      "../components/pages/configurables/gross-value-percentages/SetGrossValuePercentages"
    )
);
const GrossValuePercentageDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/gross-value-percentages/GrossValuePercentagesDetails"
    )
);

const ValuationPeriodRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/valuation-periods/ValuationPeriodRegister"
    )
);
const SetValuationPeriod = React.lazy(
  () =>
    import(
      "../components/pages/configurables/valuation-periods/SetValuationPeriod"
    )
);
const ValuationPeriodDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/valuation-periods/ValuationPeriodDetails"
    )
);

const AreaPercentageRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lettable-area-percentages/AreaPercentageRegister"
    )
);
const SetAreaPercentage = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lettable-area-percentages/SetAreaPercentage"
    )
);
const AreaPercentageDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lettable-area-percentages/AreaPercentageDetails"
    )
);

const ParishRegister = React.lazy(
  () => import("../components/pages/parishes/ParishRegister")
);
const SetParish = React.lazy(
  () => import("../components/pages/parishes/SetParish")
);
const ParishDetails = React.lazy(
  () => import("../components/pages/parishes/ParishDetails")
);

const MunicipalitiesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/municipalities/MunicipalitiesRegister"
    )
);
const SetMunicipality = React.lazy(
  () =>
    import("../components/pages/configurables/municipalities/SetMunicipality")
);
const MunicipalityDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/municipalities/MunicipalityDetails"
    )
);

const VillageRegister = React.lazy(
  () => import("../components/pages/villages/ViilageRegister")
);

const DivisionRegister = React.lazy(
  () => import("../components/pages/divisions/DivisionRegister")
);
const SetDivision = React.lazy(
  () => import("../components/pages/divisions/SetDivision")
);
const DivisionDetails = React.lazy(
  () => import("../components/pages/divisions/DivisionDetails")
);

const UsageRegister = React.lazy(
  () => import("../components/pages/configurables/usage/UsageRegister")
);
const SetUsage = React.lazy(
  () => import("../components/pages/configurables/usage/SetUsage")
);
const UsageDetails = React.lazy(
  () => import("../components/pages/configurables/usage/UsageDetails")
);

const SubUsageRegister = React.lazy(
  () => import("../components/pages/configurables/sub-usage/SubUsageRegister")
);
const SetSubUsage = React.lazy(
  () => import("../components/pages/configurables/sub-usage/SetSubUsage")
);
const SubUsageDetails = React.lazy(
  () => import("../components/pages/configurables/sub-usage/SubUsageDetails")
);

const PermitFeesRegister = React.lazy(
  () =>
    import("../components/pages/configurables/permit-fees/PermitFeesRegister")
);
const SetPermitFees = React.lazy(
  () => import("../components/pages/configurables/permit-fees/SetPermitFees")
);
const PermitFeesDetails = React.lazy(
  () =>
    import("../components/pages/configurables/permit-fees/PermitFeesDetails")
);

const OtherPermitFeesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/other-permit-fees/PermitFeesRegister"
    )
);
const SetOtherPermitFees = React.lazy(
  () =>
    import("../components/pages/configurables/other-permit-fees/SetPermitFees")
);
const OtherPermitFeesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/other-permit-fees/PermitFeesDetails"
    )
);

const ReasonRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/reason-for-objection/ReasonRegister"
    )
);
const SetReason = React.lazy(
  () =>
    import("../components/pages/configurables/reason-for-objection/SetReason")
);
const ReasonDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/reason-for-objection/ReasonDetails"
    )
);

const ConditionRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/building-condition/ConditionRegister"
    )
);
const SetCondition = React.lazy(
  () =>
    import("../components/pages/configurables/building-condition/SetCondition")
);
const ConditionDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/building-condition/ConditionDetails"
    )
);

const SetApplicationFees = React.lazy(
  () =>
    import(
      "../components/pages/configurables/application-fees/SetApplicationFees"
    )
);
const ApplicationFeesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/application-fees/ApplicationFeesDetails"
    )
);
const ApplicationFeesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/application-fees/ApplicationFeesRegister"
    )
);

const SetWasteFees = React.lazy(
  () => import("../components/pages/configurables/waste-fees/SetWasteFees")
);
const WasteFeesDetails = React.lazy(
  () => import("../components/pages/configurables/waste-fees/WasteFeesDetails")
);
const WasteFeesRegister = React.lazy(
  () => import("../components/pages/configurables/waste-fees/WasteFeesRegister")
);

const SetSignFees = React.lazy(
  () => import("../components/pages/configurables/sign-fees/SetSignFees")
);
const SignFeesDetails = React.lazy(
  () => import("../components/pages/configurables/sign-fees/SignFeesDetails")
);
const SignFeesRegister = React.lazy(
  () => import("../components/pages/configurables/sign-fees/SignFeesRegister")
);

const StreetsRegister = React.lazy(
  () => import("../components/pages/configurables/streets/StreetsRegister")
);
const SetStreet = React.lazy(
  () => import("../components/pages/configurables/streets/SetSreet")
);
const StreetDetails = React.lazy(
  () => import("../components/pages/configurables/streets/StreetDetails")
);

const StagesRegister = React.lazy(
  () => import("../components/pages/stages/StagesRegister")
);
const SetStage = React.lazy(
  () => import("../components/pages/stages/SetStage")
);
const StageDetails = React.lazy(
  () => import("../components/pages/stages/StageDetails")
);

const RentandRatesRegister = React.lazy(
  () => import("../components/pages/rent-and-rates/RentandRatesRegister")
);
const SetRentandRate = React.lazy(
  () => import("../components/pages/rent-and-rates/SetRentandRate")
);
const RentandRateDetails = React.lazy(
  () => import("../components/pages/rent-and-rates/RentandRateDetails")
);
const RentandRateBusinessDeactivation = React.lazy(
  () => import("../components/pages/rent-and-rates/BusinessDeactivation")
);

const SetMarketRent = React.lazy(
  () => import("../components/pages/market-rent/SetMarketRent")
);
const MarketRentsDetails = React.lazy(
  () => import("../components/pages/market-rent/MarketRentsDetails")
);
const MarketRentsRegister = React.lazy(
  () => import("../components/pages/market-rent/MarketRentsRegister")
);
const MarketRentsBusinessDeactivation = React.lazy(
  () => import("../components/pages/market-rent/BusinessDeactivation")
);
const PermitsRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/PermitsRegister"
    )
);
const ChainLinkPermitForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ChainLinkPermitForm"
    )
);
const ChainLinkPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ChainLinkPermitRegister"
    )
);
const ChainLinkRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/ChainLinkPermitRegister"
    )
);
const ChainLinkPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ChainLinkPermitDetails"
    )
);
const SetChainLinkScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/SetScheduleInspection"
    )
);
const ChainLinkPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ChainlinkPermitInspectionDetails"
    )
);
const SetChainLinkPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/SetInpectionQuestionnaire"
    )
);
const ChainlinkPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ViewReport"
    )
);
const ChainlinkPermitSetReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/SetReview"
    )
);
const ChainlinkPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/Reviewers"
    )
);
const ChainlinkPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ReviewedReport"
    )
);
const ChainlinkPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ChainlinkPermitQuestionsReport"
    )
);
const ChainlinkPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/SetRecommend"
    )
);
const ChainlinkPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/PPCReport"
    )
);
const ChainlinkPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/SetSchedulePPCMeeting"
    )
);
const ChainlinkPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/SetMultipleScheduling"
    )
);
const ChainlinkPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/DecisionReport"
    )
);
const ChainlinkPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ChainlinkPermitDecisionScheduleDetails"
    )
);
const ChainlinkPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/chain-link-permit/Decide")
);

const ChainlinkPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/chain-link-permit/ChainLinkPermitForm"
    )
);

const ExcavationRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/ExcavationPermitRegister"
    )
);
const ExcavationPermitForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ExcavationPermitForm"
    )
);
const ExcavationPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ExcavationPermitRegister"
    )
);
const ExcavationPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ExcavationPermitDetails"
    )
);
const SetExcavationScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/SetScheduleInspection"
    )
);
const ExcavationPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ExcavationPermitInspectionDetails"
    )
);
const SetExcavationPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/SetInpectionQuestionnaire"
    )
);
const ExcavationPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ViewReport"
    )
);
const ExcavationPermitSetReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/SetReview"
    )
);
const ExcavationPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/Reviewers"
    )
);
const ExcavationPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ReviewedReport"
    )
);
const ExcavationPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/SetRecommend"
    )
);
const ExcavationPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ExcavationPermitQuestionsReport"
    )
);
const ExcavationPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/PPCReport"
    )
);
const ExcavationPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/SetSchedulePPCMeeting"
    )
);
const ExcavationPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ExcavationPermitDecisionScheduleDetails"
    )
);
const ExcavationPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/DecisionReport"
    )
);
const ExcavationPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/excavation-permit/Decide")
);
const ExcavationPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/SetMultipleScheduling"
    )
);
const ExcavationPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/excavation-permit/ExcavationPermitForm"
    )
);

const PropertyValuationView = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/PropertyValuationView"
    )
);
const PropertyValuationDetails = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/PropertyValuationDetails"
    )
);

const PropertyValuationRegister = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/PropertyValuationRegister"
    )
);

const PropertyValuationObjectionRegister = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/PropertyValuationObjectionRegister"
    )
);

const PropertyValuationObjectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/PropertyValuationObjectionDetails"
    )
);

const SetSchedulePropertyValuationObjection = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/SetSchedulePropertyValuationObjection"
    )
);

const SetObjectorRepresentative = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/SetObjectorRepresentative"
    )
);
const SetHearingDateChange = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/SetHearingDateChange"
    )
);
const SetCourtProceedings = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/SetCourtProceedings"
    )
);

const SetProperty = React.lazy(
  () => import("../components/pages/property-rates/property-tax/SetProperty")
);

const SetLocalGovernmentContacts = React.lazy(
  () =>
    import(
      "../components/pages/configurables/lg-contacts/SetLocalGovernmentContacts"
    )
);

const SetWalls = React.lazy(
  () => import("../components/pages/configurables/walls/SetWalls")
);

const WallsDetails = React.lazy(
  () => import("../components/pages/configurables/walls/WallsDetails")
);
const WallsRegister = React.lazy(
  () => import("../components/pages/configurables/walls/WallsRegister")
);

const SetAnnualRates = React.lazy(
  () =>
    import("../components/pages/configurables/property-rates/SetAnnualRates")
);

const AnnualRatesDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/property-rates/AnnualRatesDetails"
    )
);
const AnnualRatesRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/property-rates/AnnualRatesRegister"
    )
);

const SetValidity = React.lazy(
  () => import("../components/pages/configurables/tender-validity/SetValidity")
);

const ValidityDetails = React.lazy(
  () =>
    import("../components/pages/configurables/tender-validity/ValidityDetails")
);
const ValidityRegister = React.lazy(
  () =>
    import("../components/pages/configurables/tender-validity/ValidityRegister")
);

const SetPaymentInterval = React.lazy(
  () =>
    import(
      "../components/pages/configurables/payment-intervals/SetPaymentInterval"
    )
);

const PaymentIntervalDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/payment-intervals/PaymentIntervalDetails"
    )
);
const PaymentIntervalRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/payment-intervals/PaymentIntervalRegister"
    )
);

const SetObjection = React.lazy(
  () => import("../components/pages/configurables/objections/SetObjection")
);

const ObjectionDetails = React.lazy(
  () => import("../components/pages/configurables/objections/ObjectionDetails")
);
const ObjectionRegister = React.lazy(
  () => import("../components/pages/configurables/objections/ObjectionRegister")
);

const SetLandTitle = React.lazy(
  () =>
    import(
      "../components/pages/configurables/land-title-description/SetLandTitle"
    )
);

const LandTitleDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/land-title-description/LandTitleDetails"
    )
);
const LandTitleRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/land-title-description/LandTitleRegister"
    )
);

const SetLeaseStatus = React.lazy(
  () => import("../components/pages/configurables/lease-status/SetLeaseStatus")
);

const LeaseStatusDetails = React.lazy(
  () =>
    import("../components/pages/configurables/lease-status/LeaseStatusDetails")
);
const LeaseStatusRegister = React.lazy(
  () =>
    import("../components/pages/configurables/lease-status/LeaseStatusRegister")
);

const SetConstructionCompletion = React.lazy(
  () =>
    import(
      "../components/pages/configurables/construction-completion/SetConstructionCompletion"
    )
);

const ConstructionCompletionDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/construction-completion/ConstructionCompletionDetails"
    )
);
const ConstructionCompletionRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/construction-completion/ConstructionCompletionRegister"
    )
);

const SetBuildingType = React.lazy(
  () =>
    import("../components/pages/configurables/building-type/SetBuildingType")
);

const BuildingTypeDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/building-type/BuildingTypeDetails"
    )
);
const BuildingTypeRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/building-type/BuildingTypeRegister"
    )
);

const SetToolType = React.lazy(
  () => import("../components/pages/configurables/tool-types/SetToolType")
);

const ToolTypeDetails = React.lazy(
  () => import("../components/pages/configurables/tool-types/ToolTypeDetails")
);
const ToolTypeRegister = React.lazy(
  () => import("../components/pages/configurables/tool-types/ToolTypeRegister")
);

const SetToolSize = React.lazy(
  () => import("../components/pages/configurables/tool-sizes/SetToolSize")
);

const ToolSizeDetails = React.lazy(
  () => import("../components/pages/configurables/tool-sizes/ToolSizeDetails")
);
const ToolSizeRegister = React.lazy(
  () => import("../components/pages/configurables/tool-sizes/ToolSizeRegister")
);

const SetCategoryAmount = React.lazy(
  () =>
    import(
      "../components/pages/configurables/category-amount/SetCategoryAmount"
    )
);

const CategoryAmountDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/category-amount/CategoryAmountDetails"
    )
);
const CategoryAmountRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/category-amount/CategoryAmountRegister"
    )
);

const SetMaterialUsed = React.lazy(
  () =>
    import("../components/pages/configurables/material-used/SetMaterialUsed")
);

const MaterialUsedDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/material-used/MaterialUsedDetails"
    )
);
const MaterialUsedRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/material-used/MaterialUsedRegister"
    )
);

const FinishWallsRegister = React.lazy(
  () =>
    import("../components/pages/configurables/finish-walls/FinishWallsRegister")
);
const SetFinishWalls = React.lazy(
  () => import("../components/pages/configurables/finish-walls/SetFinishWalls")
);
const FinishWallsDetails = React.lazy(
  () =>
    import("../components/pages/configurables/finish-walls/FinishWallsDetails")
);
const FloorFinishRegister = React.lazy(
  () =>
    import("../components/pages/configurables/floor-finish/FloorFinishRegister")
);
const SetFloorFinish = React.lazy(
  () => import("../components/pages/configurables/floor-finish/SetFloorFinish")
);
const FloorFinishDetails = React.lazy(
  () =>
    import("../components/pages/configurables/floor-finish/FloorFinishDetails")
);
const RoofCoveringRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/roof-covering/RoofCoveringRegister"
    )
);
const SetRoofCovering = React.lazy(
  () =>
    import("../components/pages/configurables/roof-covering/SetRoofCovering")
);
const RoofCoveringDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/roof-covering/RoofCoveringDetails"
    )
);
const CeilingRegister = React.lazy(
  () => import("../components/pages/configurables/ceiling/CeilingRegister")
);
const SetCeiling = React.lazy(
  () => import("../components/pages/configurables/ceiling/SetCeiling")
);
const CeilingDetails = React.lazy(
  () => import("../components/pages/configurables/ceiling/CeilingDetails")
);

const CurrencyRegister = React.lazy(
  () => import("../components/pages/configurables/currencies/CurrencyRegister")
);
const SetCurrency = React.lazy(
  () => import("../components/pages/configurables/currencies/SetCurrency")
);
const CurrencyDetails = React.lazy(
  () => import("../components/pages/configurables/currencies/CurrencyDetails")
);
const BankCodesRegister = React.lazy(
  () => import("../components/pages/configurables/bank-codes/BankCodesRegister")
);
const SetBankCodes = React.lazy(
  () => import("../components/pages/configurables/bank-codes/SetBankCodes")
);
const BankCodesDetails = React.lazy(
  () => import("../components/pages/configurables/bank-codes/BankCodesDetails")
);

const SecurityRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/security-measures/SecurityMeasureRegister"
    )
);
const SetSecurityMeasure = React.lazy(
  () =>
    import(
      "../components/pages/configurables/security-measures/SetSecurityMeasure"
    )
);
const SecurityMeasureDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/security-measures/SecurityMeasureDetails"
    )
);

const BusinessCatRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/business-category/BusinessCategoriesRegister"
    )
);
const SetBusinessCat = React.lazy(
  () =>
    import(
      "../components/pages/configurables/business-category/SetBusinessCategory"
    )
);
const BusinessCatDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/business-category/BusinessCategoryDetails"
    )
);

const RentPaymentIntervalRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/rent-payment-interval/RentPaymentIntervalRegister"
    )
);
const SetRentPaymentInterval = React.lazy(
  () =>
    import(
      "../components/pages/configurables/rent-payment-interval/SetRentPaymentInterval"
    )
);
const RentPaymentIntervalDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/rent-payment-interval/RentPaymentIntervalDetails"
    )
);

const HoardingPermitForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/HoardingPermitForm"
    )
);
const HoardingRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/HoardingPermitRegister"
    )
);

const HoardingPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/HoardingPermitRegister"
    )
);
const HoardingPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/HoardingPermitDetails"
    )
);
const SetHoardingScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/SetScheduleInspection"
    )
);
const HoardingPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/HoardingPermitInspectionDetails"
    )
);
const SetHoardingPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/SetInpectionQuestionnaire"
    )
);
const HoardingPermitReviewers = React.lazy(
  () =>
    import("../components/pages/developement-control/hoarding-permit/Reviewers")
);
const HoardingPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/ViewReport"
    )
);
const HoardingPermitSetReview = React.lazy(
  () =>
    import("../components/pages/developement-control/hoarding-permit/SetReview")
);
const HoardingPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/ReviewedReport"
    )
);
const HoardingPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/HoardingPermitQuestionsReport"
    )
);
const HoardingPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/SetRecommend"
    )
);
const HoardingPermitPPCReport = React.lazy(
  () =>
    import("../components/pages/developement-control/hoarding-permit/PPCReport")
);
const HoardingPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/SetSchedulePPCMeeting"
    )
);
const HoardingPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/HoardingPermitDecisionScheduleDetails"
    )
);
const HoardingPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/DecisionReport"
    )
);
const HoardingPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/hoarding-permit/Decide")
);
const HoardingPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/SetMultipleScheduling"
    )
);

const HoardingPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/hoarding-permit/HoardingPermitForm"
    )
);

const BoundaryFenceRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/boundary-fence/BoundaryFenceRegister"
    )
);
const SetBoundaryFence = React.lazy(
  () =>
    import("../components/pages/configurables/boundary-fence/SetBoundaryFence")
);
const BoundaryFenceDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/boundary-fence/BoundaryFenceDetails"
    )
);
const AccommodationCategoryRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/accommodation-category/AccommondationCategoriesRegister"
    )
);
const SetAccommodationCategory = React.lazy(
  () =>
    import(
      "../components/pages/configurables/accommodation-category/SetAccommondationCategory"
    )
);
const AccommodationCategoryDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/accommodation-category/AccommondationCategoryDetails"
    )
);
const OutdoorAdvertisementPermitForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvertisementPermitForm"
    )
);
const OutdoorAdvertisementPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvertisementPermitRegister"
    )
);
const OutdoorAdvertisementRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/OutdoorAdvertisementPermitRegister"
    )
);
const OutdoorAdvertisementPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvetisementPermitDetails"
    )
);
const SetOutdoorAdvertisementScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/SetScheduleInspection"
    )
);
const OutdoorAdvertisementPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvertisementPermitInspectionDetails"
    )
);
const SetOutdoorAdvertisementPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/SetInpectionQuestionnaire"
    )
);
const OutdoorAdvertisementPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/ViewReport"
    )
);
const OutdoorAdvertisementPermitSetReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control//outdoor-advertisement-permit/SetReview"
    )
);
const OutdoorAdvertisementPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/Reviewers"
    )
);
const OutdoorAdvertisementPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/ReviewedReport"
    )
);
const OutdoorAdvertisementPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvertisementPermitQuestionsReport"
    )
);
const OutdoorAdvertisementPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/SetRecommend"
    )
);
const OutdoorAdvertisementPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/PPCReport"
    )
);
const OutdoorAdvertisementPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/SetSchedulePPCMeeting"
    )
);
const OutdoorAdvertisementPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvertisementPermitDecisionScheduleDetails"
    )
);
const OutdoorAdvertisementPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/DecisionReport"
    )
);
const OutdoorAdvertisementPermitDecide = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/Decide"
    )
);
const OutdoorAdvertisementSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/SetMultipleScheduling"
    )
);
const OutdoorAdvertisementPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvertisementPermitForm"
    )
);

const OccupationalPermitForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/OccupationalPermitForm"
    )
);
const OccupationalPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/OccupationalPermitRegister"
    )
);
const OccupationalRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/OccupationalPermitRegister"
    )
);
const OccupationalPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/OccupationalPermitDetails"
    )
);
const SetOccupationScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/SetScheduleInspection"
    )
);
const OccupationalPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/OccupationalPermitInspectionDetails"
    )
);
const SetOccupationalPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/SetInpectionQuestionnaire"
    )
);
const OccupationalPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/ViewReport"
    )
);
const OccupationalPermitSetReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/SetReview"
    )
);
const OccupationalPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/Reviewers"
    )
);
const OccupationalPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/ReviewedReport"
    )
);
const OccupationalPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/OccupationalPermitQuestionsReport"
    )
);
const OccupationalPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/SetRecommend"
    )
);
const OccupationalPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/PPCReport"
    )
);
const OccupationalPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/SetSchedulePPCMeeting"
    )
);
const OccupationalPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/OccupationalPermitDecisionScheduleDetails"
    )
);
const OccupationalPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/DecisionReport"
    )
);
const OccupationalPermitDecide = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/Decide"
    )
);
const OccupationalPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/SetMultipleScheduling"
    )
);
const OccupationalPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/occupational-permit/OccupationalPermitForm"
    )
);

const DemolitionPermitForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DemolitionPermitForm"
    )
);
const DemolitionRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/DemolitionPermitRegister"
    )
);

const DemolitionPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DemolitionPermitRegister"
    )
);
const DemolitionPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DemolitionPermitDetails"
    )
);
const SetDemolitionScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/SetScheduleInspection"
    )
);
const DemolitionPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DemolitionPermitInspectionDetails"
    )
);
const SetDemolitionPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/SetInpectionQuestionnaire"
    )
);
const DemolitionPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/ViewReport"
    )
);
const DemolitionPermitSetReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/SetReview"
    )
);
const DemolitionPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/Reviewers"
    )
);
const DemolitionPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/ReviewedReport"
    )
);
const DemolitionPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/SetRecommend"
    )
);
const DemolitionPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DemolitionPermitQuestionsReport"
    )
);
const DemolitionPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/PPCReport"
    )
);
const DemolitionPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/SetSchedulePPCMeeting"
    )
);
const DemolitionPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DecisionReport"
    )
);
const DemolitionPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DemolitionPermitDecisionScheduleDetails"
    )
);
const DemolitionPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/demolition-permit/Decide")
);
const DemolitionPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/SetMultipleScheduling"
    )
);
const DemolitonPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/demolition-permit/DemolitionPermitForm"
    )
);

const PropertyValuationForm = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/PropertyValuationForm"
    )
);

const SetPropertyValuation = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-valuation/SetPropertyValuation"
    )
);
const SetMarketDues = React.lazy(
  () => import("../components/pages/market-dues/SetMarketDues")
);
const MarketDuesDetails = React.lazy(
  () => import("../components/pages/market-dues/MarketDuesDetails")
);
const MarketDuesRegister = React.lazy(
  () => import("../components/pages/market-dues/MarketDuesRegister")
);

const SetGroundRent = React.lazy(
  () => import("../components/pages/ground-rent/SetGroundRent")
);
const GroundRentDetails = React.lazy(
  () => import("../components/pages/ground-rent/GroundRentDetails")
);
const GroundRentRegister = React.lazy(
  () => import("../components/pages/ground-rent/GroundRentRegister")
);
const GroundRentBusinessDeactivation = React.lazy(
  () => import("../components/pages/ground-rent/BusinessDeactivation")
);

const SetOutdoorAdvertising = React.lazy(
  () => import("../components/pages/outdoor-advertising/SetOutdoorAdvertising")
);
const OutdoorAdvertisingDetails = React.lazy(
  () =>
    import("../components/pages/outdoor-advertising/OutdoorAdvertisingDetails")
);
const OutdoorAdvertisingRegister = React.lazy(
  () =>
    import("../components/pages/outdoor-advertising/OutdoorAdvertisingRegister")
);
const OutdoorAdvertisingBusinessDeactivation = React.lazy(
  () => import("../components/pages/outdoor-advertising/BusinessDeactivation")
);

const AuditTrailRegister = React.lazy(
  () => import("../components/pages/users/AuditTrailRegister")
);

const UserProfile = React.lazy(
  () => import("../components/pages/users/UserProfile")
);

const SetVillage = React.lazy(
  () => import("../components/pages/villages/SetVillage")
);

const VillageDetails = React.lazy(
  () => import("../components/pages/villages/VillageDetails")
);

const SetDevelopmentPermit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/SetDevelopmentPermit"
    )
);
const SetScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/SetScheduleInspection"
    )
);
const DevelopmentPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/DevelopmentPermitInspectionDetails"
    )
);
const SetInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/SetInpectionQuestionnaire"
    )
);
const ViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/ViewReport"
    )
);
const SetReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/SetReview"
    )
);
const DevelopmentPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/ReviewedReport"
    )
);
const DevelopmentPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/DevelopmentPermitQuestionsReport"
    )
);
const DevelopmentPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/SetRecommend"
    )
);
const DevelopmentPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/PPCReport"
    )
);
const DevelopmentPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/DecisionReport"
    )
);
const DevelopmentPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/SetSchedulePPCMeeting"
    )
);
const DevelopmentPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/DevelopmentPermitDecisionScheduleDetails"
    )
);
const DevelopmentPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/development-permit/Decide")
);
const DevelopmentPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/Reviewers"
    )
);
const DevelopmentPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/DevelopmentPermitDetails"
    )
);
const DevelopmentRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/DevelopmentPermitRegister"
    )
);
const DevelopmentPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/DevelopmentPermitRegister"
    )
);
const DevelopmentPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/SetMultipleScheduling"
    )
);
const DevelopmentPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/development-permit/DevelopmentPermitForm"
    )
);

const RoutineInspectionForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/routine-inspection/RoutineInspectionForm"
    )
);
const RoutineInspectionRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/routine-inspection/RoutineInspectionRegister"
    )
);
const RoutineInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/routine-inspection/RoutineInspectionDetails"
    )
);

const CommencementForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/notification-of-commencement/CommencementForm"
    )
);

const CommencementRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/notification-of-commencement/CommencementRegister"
    )
);

const RequestDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/notification-of-request-for-inspection/RequestDetails"
    )
);

const RequestForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/notification-of-request-for-inspection/RequestForm"
    )
);

const RequestRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/notification-of-request-for-inspection/RequestRegister"
    )
);

const CommencementDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/notification-of-commencement/CommencementDetails"
    )
);

const EnforcementForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/enforcement/SetEnforcement"
    )
);
const EnforcementRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/enforcement/EnforcementRegister"
    )
);
const EnforcementDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/enforcement/EnforcementDetails"
    )
);
const SetEnforcementsSchedulePPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/enforcement/SetSchedulePPCMeeting"
    )
);
const EnforcementDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/enforcement/EnforcementDecisionScheduleDetails"
    )
);
const EnforcementDecide = React.lazy(
  () => import("../components/pages/developement-control/enforcement/Decide")
);

const SetSubdivisionConsolidationPermit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SetSubdivisionConsolidationPermit"
    )
);
const SubdivisionConsolidationPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SubdivisionConsolidationPermitDetails"
    )
);
const SubdivisionConsolidationRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/SubdivisionConsolidationPermitRegister"
    )
);
const SubdivisionConsolidationPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SubdivisionConsolidationPermitRegister"
    )
);
const SetSubdivisionConsolidationPermitScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SetScheduleInspection"
    )
);
const SubdivisionConsolidationPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SubdivisionConsolidationPermitInspectionDetails"
    )
);
const SetSubdivisionConsolidationPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SetInpectionQuestionnaire"
    )
);
const SubdivisionConsolidationViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/ViewReport"
    )
);
const SubdivisionConsolidationSetReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SetReview"
    )
);
const SubdivisionConsolidationReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/Reviewers"
    )
);

const SubdivisionConsolidationPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/ReviewedReport"
    )
);
const SubdivisionConsolidationPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SetRecommend"
    )
);
const SubdivisionConsolidationPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SubdivisionConsolidationPermitQuestionsReport"
    )
);
const SubdivisionConsolidationPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/PPCReport"
    )
);
const SubdivisionConsolidationPermitSetSchedulePPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SetSchedulePPCMeeting"
    )
);
const SubdivisionConsolidationPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/DecisionReport"
    )
);
const SubdivisionConsolidationPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SubdivisionConsolidationPermitDecisionScheduleDetails"
    )
);
const SubdivisionConsolidationPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/subdivision-permit/Decide")
);
const SubdivisionConsolidationPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SetMultipleScheduling"
    )
);
const SubdivisionConsolidationPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/subdivision-permit/SubdivisionConsolidationPermitForm"
    )
);

const SetBuildingPermit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/SetBuildingPermit"
    )
);
const BuildingPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/SetMultipleScheduling"
    )
);

const BuildingPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitDetails"
    )
);

const BuildingPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitRegister"
    )
);
const BuildingRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/BuildingPermitRegister"
    )
);
const SetBuildingPermitScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/SetBuildingPermitScheduleInspection"
    )
);

const BuildingPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitInspectionDetails"
    )
);
const SetBuildingPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/SetBuildingPermitInpectionQuestionnaire"
    )
);

const ViewBuildingPermitReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/ViewBuildingPermitReport"
    )
);

const SetBuildingPermitReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/SetBuildingPermitReview"
    )
);
const BuildingPermitReviewers = React.lazy(
  () =>
    import("../components/pages/developement-control/building-permit/Reviewers")
);

const BuildingPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitReviewedReport"
    )
);

const BuildingPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitQuestionsReport"
    )
);

const BuildingPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitSetRecommend"
    )
);

const BuildingPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitPPCReport"
    )
);

const BuildingPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitSetPPCMeeting"
    )
);
const BuildingPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitDecisionScheduleDetails"
    )
);
const BuildingPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitDecisionReport"
    )
);
const BuildingPermitDecide = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitDecide"
    )
);
const BuildingPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/building-permit/BuildingPermitForm"
    )
);

//lease application
const SetLeaseApplication = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/SetLeaseApplication"
    )
);

const LeaseApplicationDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/LeaseApplicationDetails"
    )
);
const LeaseApplicationRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/LeaseApplicationRegister"
    )
);
const LeaseApplicationsRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/LeaseApplicationRegister"
    )
);
const SetLeaseApplicationInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/SetScheduleInspection"
    )
);
const LeaseApplicationInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/LeaseApplicationInspectionDetails"
    )
);
const SetLeaseApplicationInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/SetInpectionQuestionnaire"
    )
);
const LeaseApplicationViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/ViewReport"
    )
);
const SetLeaseApplicationReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/SetReview"
    )
);
const LeaseApplicationReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/ReviewedReport"
    )
);
const LeaseApplicationSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/SetRecommend"
    )
);
const LeaseApplicationQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/LeaseApplicationQuestionsReport"
    )
);
const LeaseApplicationPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/PPCReport"
    )
);
const LeaseApplicationReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/Reviewers"
    )
);
const LeaseApplicationSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/SetSchedulePPCMeeting"
    )
);
const LeaseApplicationDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/DecisionReport"
    )
);
const LeaseApplicationDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/LeaseApplicationDecisionScheduleDetails"
    )
);
const LeaseApplicationPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/lease-application/Decide")
);
const LeaseApplicationSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/SetMultipleScheduling"
    )
);
const LeaseApplicationEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/lease-application/LeaseApplicationForm"
    )
);

const AssessmentsRegister = React.lazy(
  () => import("../components/pages/billing/AssessmentsRegister")
);

const PaymentsRegister = React.lazy(
  () => import("../components/pages/payments/PaymentsRegister")
);
const PrnsRegister = React.lazy(
  () => import("../components/pages/payments/PrnsRegister")
);
const PaymentsSearch = React.lazy(
  () => import("../components/pages/payments/PaymentsSearch")
);

const LedgerRegister = React.lazy(
  () => import("../components/pages/payments/LedgerRegister")
);
const GeneralLedgerRegister = React.lazy(
  () => import("../components/pages/payments/GeneralLedgerRegister")
);
const ArrearsLedgerRegister = React.lazy(
  () => import("../components/pages/payments/ArrearsLedgerRegister")
);
const RevenueLedgerRegister = React.lazy(
  () => import("../components/pages/payments/RevenueLedgerRegister")
);
const Paf = React.lazy(() => import("../components/pages/billing/Paf"));

const InvoiceList = React.lazy(
  () => import("../components/pages/billing/InvoiceList")
);

const SetDepartments = React.lazy(
  () => import("../components/pages/configurables/departments/SetDepartments")
);

const DepartmentsRegister = React.lazy(
  () =>
    import("../components/pages/configurables/departments/DepartmentsRegister")
);

const DepartmentDetails = React.lazy(
  () =>
    import("../components/pages/configurables/departments/DepartmentDetails")
);

const MarketDueCategoryRegister = React.lazy(
  () =>
    import(
      "../components/pages/markets/market-due-categories/MarketDueCategoryRegister"
    )
);

const SetMarketDueCategory = React.lazy(
  () =>
    import(
      "../components/pages/markets/market-due-categories/SetMarketDueCategory"
    )
);

const MarketDueCategoryDetails = React.lazy(
  () =>
    import(
      "../components/pages/markets/market-due-categories/MarketDueCategoryDetails"
    )
);

const RoutesRegister = React.lazy(
  () => import("../components/pages/routes/RoutesRegister")
);

const RouteDetails = React.lazy(
  () => import("../components/pages/routes/RouteDetails")
);

const SetRoute = React.lazy(
  () => import("../components/pages/routes/SetRoute")
);

const BatchUploadForm = React.lazy(
  () => import("../components/pages/routes/BatchUploadForm")
);

const PropertyDetails = React.lazy(
  () =>
    import("../components/pages/property-rates/property-tax/PropertyDetails")
);

const PropertyRegister = React.lazy(
  () =>
    import("../components/pages/property-rates/property-tax/PropertyRegister")
);
const PropertyBusinessDeactivation = React.lazy(
  () =>
    import(
      "../components/pages/property-rates/property-tax/BusinessDeactivation"
    )
);

const ParkUseFeesForm = React.lazy(
  () => import("../components/pages/park-user-fees/ParkUserFeesForm")
);

const ParkFeesDetails = React.lazy(
  () => import("../components/pages/park-user-fees/ParkFeesDetails")
);
const ParkFeesRegister = React.lazy(
  () => import("../components/pages/park-user-fees/ParkFeesRegister")
);
const ParkPrnsRegister = React.lazy(
  () => import("../components/pages/park-user-fees/PrnsRegister")
);
const ParkPaymentsRegister = React.lazy(
  () => import("../components/pages/park-user-fees/PaymentsRegister")
);
const ParkUserPaymentsRegister = React.lazy(
  () => import("../components/pages/park-user-fees/PaymentsShareRegister")
);
const ParkUserPaymentsDetails = React.lazy(
  () => import("../components/pages/park-user-fees/PaymentsShareDetails")
);
const CondominiumPermitForm = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/CondominiumPermitForm"
    )
);

const CondominiumDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/CondominiumDetails"
    )
);
const CondominiumPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/CondominiumPermitRegister"
    )
);
const CondominiumRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/CondominiumPermitRegister"
    )
);
const SetCondominiumPermitScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/SetScheduleInspection"
    )
);
const CondominiumPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/CondominiumPermitInspectionDetails"
    )
);
const SetCondominiumPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/SetInpectionQuestionnaire"
    )
);
const CondominiumPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/ViewReport"
    )
);
const SetCondominiumPermitReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/SetReview"
    )
);
const CondominiumPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/Reviewers"
    )
);
const CondominiumPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/ReviewedReport"
    )
);
const CondominiumPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/SetRecommend"
    )
);
const CondominiumPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/CondominiumPermitQuestionsReport"
    )
);
const CondominiumPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/PPCReport"
    )
);
const CondominiumPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/SetSchedulePPCMeeting"
    )
);
const CondominiumPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/DecisionReport"
    )
);
const CondominiumPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/CondominiumPermitDecisionScheduleDetails"
    )
);
const CondominiumPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/condominium-permit/Decide")
);
const CondominiumPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/SetMultipleScheduling"
    )
);
const CondominiumPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/condominium-permit/CondominiumPermitForm"
    )
);

const SetRenovationPermit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/SetRenovationPermit"
    )
);
const RenovationPermitDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/RenovationPermitDetails"
    )
);
const RenovationRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/professionals/RenovationPermitRegister"
    )
);
const RenovationPermitRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/RenovationPermitRegister"
    )
);
const SetRenovationPermitScheduleInspection = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/SetScheduleInspection"
    )
);
const RenovationPermitInspectionDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/RenovationPermitInspectionDetails"
    )
);
const SetRenovationPermitInspectionQuestionnaire = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/SetInpectionQuestionnaire"
    )
);
const RenovationPermitViewReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/ViewReport"
    )
);
const SetRenovationPermitReview = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/SetReview"
    )
);

const RenovationPermitReviewers = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/Reviewers"
    )
);
const RenovationPermitReviewedReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/ReviewedReport"
    )
);
const RenovationPermitQuestionsReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/RenovationPermitQuestionsReport"
    )
);
const RenovationPermitSetRecommend = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/SetRecommend"
    )
);
const RenovationPermitPPCReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/PPCReport"
    )
);
const RenovationPermitSetPPCMeeting = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/SetSchedulePPCMeeting"
    )
);
const RenovationPermitDecisionReport = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/DecisionReport"
    )
);
const RenovationPermitDecisionScheduleDetails = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/RenovationPermitDecisionScheduleDetails"
    )
);

const RenovationPermitDecide = React.lazy(
  () =>
    import("../components/pages/developement-control/renovation-permit/Decide")
);
const RenovationPermitSetMultipleScheduling = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/SetMultipleScheduling"
    )
);
const RenovationPermitEdit = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/renovation-permit/RenovationPermitForm"
    )
);

const ApplicationsRegister = React.lazy(
  () =>
    import(
      "../components/pages/developement-control/application-search/ApplicationsRegister"
    )
);

const TradeCategoryRegister = React.lazy(
  () => import("../components/pages/trade-categories/TradeCategoryRegister")
);
const TradeCategoryDetails = React.lazy(
  () => import("../components/pages/trade-categories/TradeCategoryDetails")
);
const SetTradeCategory = React.lazy(
  () => import("../components/pages/trade-categories/SetTradeCategory")
);

const TradeSubCategoryRegister = React.lazy(
  () =>
    import("../components/pages/trade-sub-categories/TradeSubCategoryRegister")
);
const TradeSubCategoryDetails = React.lazy(
  () =>
    import("../components/pages/trade-sub-categories/TradeSubCategoryDetails")
);
const SetTradeSubCategory = React.lazy(
  () => import("../components/pages/trade-sub-categories/SetTradeSubCategory")
);

const TitleDescriptionRegister = React.lazy(
  () =>
    import(
      "../components/pages/configurables/title-descriptions/TitleDescriptionRegister"
    )
);
const TitleDescriptionDetails = React.lazy(
  () =>
    import(
      "../components/pages/configurables/title-descriptions/TitleDescriptionRegister"
    )
);
const SetTitleDescription = React.lazy(
  () =>
    import(
      "../components/pages/configurables/title-descriptions/SetTitleDescription"
    )
);

const SetMarketFacilityNumbers = React.lazy(
  () =>
    import(
      "../components/pages/market-facility-numbers/SetMarketFacilityNumbers"
    )
);
const MarketFacilityNumbersDetails = React.lazy(
  () =>
    import(
      "../components/pages/market-facility-numbers/MarketFacilityNumbersDetails"
    )
);

const MarketFacilityNumbersRegister = React.lazy(
  () =>
    import(
      "../components/pages/market-facility-numbers/MarketFacilityNumbersRegister"
    )
);

const AmendmentRequest = React.lazy(
  () => import("../components/pages/amendments/AmendmentApplication")
);

const RegistrationWiseAmendment = React.lazy(
  () => import("../components/pages/amendments/RegistrationWiseAmendment")
);

const BusinessRegistrationObjection = React.lazy(
  () => import("../components/pages/objections/BusinessRegistrationObjection")
);

const RegistrationWiseReassessment = React.lazy(
  () => import("../components/pages/reassessments/RegistrationWiseReassessment")
);

const AmendmentRegister = React.lazy(
  () => import("../components/pages/amendments/AmendmentRegister")
);

const ReassessmentApplication = React.lazy(
  () => import("../components/pages/reassessments/ReassessmentApplication")
);

const BusinessTransferApplication = React.lazy(
  () =>
    import("../components/pages/business-transfers/BusinessTransferApplication")
);

const BusinessTransfer = React.lazy(
  () => import("../components/pages/business-transfers/BusinessTransfer")
);

const BusinessTransferRegister = React.lazy(
  () =>
    import("../components/pages/business-transfers/BusinessTransferRegister")
);
const BusinessTransferDetails = React.lazy(
  () => import("../components/pages/business-transfers/BusinessTransferDetails")
);

const ObjectionRequest = React.lazy(
  () => import("../components/pages/objections/ObjectionApplication")
);

const ObjectionRequestDetails = React.lazy(
  () => import("../components/pages/objections/ObjectionRequestDetails")
);

const ObjectionRequestRegister = React.lazy(
  () => import("../components/pages/objections/ObjectionRequestRegister")
);

const BusinessDeactivationRequest = React.lazy(
  () =>
    import(
      "../components/pages/business-deactivation/BusinessDeactivationApplication"
    )
);

const BusinessDeactivationDetails = React.lazy(
  () =>
    import(
      "../components/pages/business-deactivation/BusinessDeactivationDetails"
    )
);

const BusinessDeactivationRegister = React.lazy(
  () =>
    import(
      "../components/pages/business-deactivation/BusinessDeactivationRegister"
    )
);

const ValuationObjectionRequestDetails = React.lazy(
  () =>
    import(
      "../components/pages/valuation-objections/ValuationObjectionRequestDetails"
    )
);

const SetAdvanceIncome = React.lazy(() =>
  import("../components/pages/advance-income/SetAdvanceIncome")
);

const AdvanceIncomeRegister = React.lazy(() =>
  import("../components/pages/advance-income/AdvanceIncomeRegister")
);

const AdvanceIncomeDetails = React.lazy(() =>
  import("../components/pages/advance-income/AdvanceIncomeDetails")
);

export const standardRoutes = () => [
  {
    path: "/",
    component: <Dashboard />,
    exact: true,
  },

  {
    path: "/register",
    component: <HorizontalLinearStepper />,
    exact: true,
  },

  {
    path: "/audit/activity",
    component: <AuditTrailRegister />,
    exact: true,
  },

  // Account Management
  {
    // path: !GetPermissions([CAN_ADMIN]) ? "/users" : "",
    path: "/users",
    component: <UsersRegister />,
    exact: true,
  },
  {
    path: "/users/create",
    component: <SetUser />,
    exact: true,
  },
  {
    path: "/users/update/:id",
    component: <SetUser />,
    exact: true,
  },
  {
    path: "/users/view/:id",
    component: <UserDetails />,
    exact: true,
  },
  {
    path: "/change-password",
    component: <ChangePasswordForm />,
    exact: true,
  },
  {
    path: "/users/profile",
    component: <UserProfileView />,
    exact: true,
  },

  //MARKETS MANAGEMENT
  {
    path: "/local-governments",
    component: <LocalGovernmentsRegister />,
    exact: true,
  },
  {
    path: "/local-governments/create",
    component: <SetLocalGovernment />,
    exact: true,
  },
  {
    path: "/local-governments/update/:id",
    component: <SetLocalGovernment />,
    exact: true,
  },
  {
    path: "/local-governments/view/:id",
    component: <LocalGovernmentsDetails />,
    exact: true,
  },

  //Nature of Business
  {
    path: "/nature-of-business",
    component: <NatureOfBusinessRegister />,
    exact: true,
  },
  {
    path: "/nature-of-business/create",
    component: <SetNatureOfBusiness />,
    exact: true,
  },
  {
    path: "/nature-of-business/update/:id",
    component: <SetNatureOfBusiness />,
    exact: true,
  },
  {
    path: "/nature-of-business/view/:id",
    component: <NatureOfBusinessDetails />,
    exact: true,
  },

  //Operational PermitCategories
  {
    path: "/operational-permit-categories",
    component: <OperationalPermitCategoriesRegister />,
    exact: true,
  },
  {
    path: "/operational-permit-categories/create",
    component: <SetOperationalPermitCategories />,
    exact: true,
  },
  {
    path: "/operational-permit-categories/update/:id",
    component: <SetOperationalPermitCategories />,
    exact: true,
  },
  {
    path: "/operational-permit-categories/view/:id",
    component: <OperationalPermitCategoriesDetails />,
    exact: true,
  },

  //Operational Sub Categories
  {
    path: "/operational-permit-sub-categories",
    component: <OperationalPermitSubCategoriesRegister />,
    exact: true,
  },
  {
    path: "/operational-permit-sub-categories/create",
    component: <SetOperationalPermitSubCategories />,
    exact: true,
  },
  {
    path: "/operational-permit-sub-categories/update/:id",
    component: <SetOperationalPermitSubCategories />,
    exact: true,
  },
  {
    path: "/operational-permit-sub-categories/view/:id",
    component: <OperationalPermitSubCategoriesDetails />,
    exact: true,
  },

  //LST CATEGORIES
  {
    path: "/lst-categories",
    component: <LstCategoryRegister />,
    exact: true,
  },
  {
    path: "/lst-categories/create",
    component: <SetLstCategory />,
    exact: true,
  },
  {
    path: "/lst-categories/update/:id",
    component: <SetLstCategory />,
    exact: true,
  },
  {
    path: "/lst-categories/view/:id",
    component: <LstCategoriesDetails />,
    exact: true,
  },

  //PROPERTY RATES
  {
    path: "/property-annual-rates",
    component: <AnnualRatesRegister />,
    exact: true,
  },
  {
    path: "/property-annual-rates/create",
    component: <SetAnnualRates />,
    exact: true,
  },
  {
    path: "/property-annual-rates/update/:id",
    component: <SetAnnualRates />,
    exact: true,
  },
  {
    path: "/property-annual-rates/view/:id",
    component: <AnnualRatesDetails />,
    exact: true,
  },

  //LST NATURE OF BUSINESS
  {
    path: "/lst-nature-of-business",
    component: <LstNatureOfBusinessRegister />,
    exact: true,
  },
  {
    path: "/lst-nature-of-business/create",
    component: <SetLstNatureOfBusiness />,
    exact: true,
  },
  {
    path: "/lst-nature-of-business/update/:id",
    component: <SetLstNatureOfBusiness />,
    exact: true,
  },
  {
    path: "/lst-nature-of-business/view/:id",
    component: <LstNatureOfBusinessDetails />,
    exact: true,
  },
  //Nature of Business
  {
    path: "/nature-of-market",
    component: <NatureofMarketRegister />,
    exact: true,
  },
  {
    path: "/nature-of-market/create",
    component: <SetNatureOfMarket />,
    exact: true,
  },
  {
    path: "/nature-of-market/update/:id",
    component: <SetNatureOfMarket />,
    exact: true,
  },
  {
    path: "/nature-of-market/view/:id",
    component: <NatureOfMarketDetails />,
    exact: true,
  },

  //Revenue sources
  {
    path: "/revenue-sources",
    component: <RevenueSourcesRegister />,
    exact: true,
  },
  {
    path: "/revenue-sources/create",
    component: <SetRevenueSource />,
    exact: true,
  },

  //Demand Dates
  {
    path: "/demand-dates",
    component: <DemandDateRegister />,
    exact: true,
  },
  {
    path: "/demand-dates/create",
    component: <SetDemandDate />,
    exact: true,
  },
  {
    path: "/demand-dates/view/:id",
    component: <DemandDateDetails />,
    exact: true,
  },
  {
    path: "/demand-dates/update/:id",
    component: <SetDemandDate />,
    exact: true,
  },

  //Business Categories
  {
    path: "/business-categories",
    component: <BusinessCategoriesRegister />,
    exact: true,
  },
  {
    path: "/business-categories/create",
    component: <SetBusinessCategory />,
    exact: true,
  },
  {
    path: "/business-categories/view/:id",
    component: <BusinessCategoryDetails />,
    exact: true,
  },
  {
    path: "/business-categories/update/:id",
    component: <SetBusinessCategory />,
    exact: true,
  },

  //Trade Categories
  {
    path: "/trade-category",
    component: <TradeCategoryRegister />,
    exact: true,
  },
  {
    path: "/trade-category/create",
    component: <SetTradeCategory />,
    exact: true,
  },
  {
    path: "/trade-category/view/:id",
    component: <TradeCategoryDetails />,
    exact: true,
  },
  {
    path: "/trade-category/update/:id",
    component: <SetTradeCategory />,
    exact: true,
  },

  //Trade Sub Categories
  {
    path: "/trade-sub-category",
    component: <TradeSubCategoryRegister />,
    exact: true,
  },
  {
    path: "/trade-sub-category/create",
    component: <SetTradeSubCategory />,
    exact: true,
  },
  {
    path: "/trade-sub-category/view/:id",
    component: <TradeSubCategoryDetails />,
    exact: true,
  },
  {
    path: "/trade-sub-category/update/:id",
    component: <SetTradeSubCategory />,
    exact: true,
  },

  //TITLE_DESCRIPTION
  {
    path: "/title-description",
    component: <TitleDescriptionRegister />,
    exact: true,
  },
  {
    path: "/title-description/create",
    component: <SetTitleDescription />,
    exact: true,
  },
  {
    path: "/title-description/view/:id",
    component: <TitleDescriptionDetails />,
    exact: true,
  },
  {
    path: "/title-description/update/:id",
    component: <SetTitleDescription />,
    exact: true,
  },

  //Hotel facility rating
  {
    path: "/hotel-facility-rating",
    component: <HotelFacilityRatingRegister />,
    exact: true,
  },
  {
    path: "/hotel-facility-rating/create",
    component: <SetHotelFacilityRating />,
    exact: true,
  },
  {
    path: "/hotel-facility-rating/view/:id",
    component: <HotelFacilityRatingDetails />,
    exact: true,
  },

  {
    path: "/hotel-facility-rating/update/:id",
    component: <SetHotelFacilityRating />,
    exact: true,
  },

  //Scrutiny Workflows
  {
    path: "/scrutiny-workflows",
    component: <ScrutinyWorkflowRegister />,
    exact: true,
  },
  {
    path: "/scrutiny-workflows/create",
    component: <SetScrutinyWorkflow />,
    exact: true,
  },
  {
    path: "/scrutiny-workflows/view/:id",
    component: <ScrutinyWorkflowDetails />,
    exact: true,
  },

  {
    path: "/scrutiny-workflows/update/:id",
    component: <SetScrutinyWorkflow />,
    exact: true,
  },

  //Scrutiny Workflow Configs
  {
    path: "/scrutiny-workflow-config",
    component: <ScrutinyWorkflowConfigRegister />,
    exact: true,
  },
  {
    path: "/scrutiny-workflow-config/create",
    component: <SetScrutinyWorkflowConfig />,
    exact: true,
  },
  {
    path: "/scrutiny-workflow-config/view/:id",
    component: <ScrutinyWorkflowConfigDetails />,
    exact: true,
  },

  {
    path: "/scrutiny-workflow-config/update/:id",
    component: <SetScrutinyWorkflowConfig />,
    exact: true,
  },
  {
    path: "/scrutiny-service-actors/create/:id",
    component: <AddServiceActors />,
    exact: true,
  },

  //Development Control Roles
  {
    path: "/development-control-roles",
    component: <DevelopmentControlRolesRegister />,
    exact: true,
  },
  {
    path: "/development-control-roles/create",
    component: <SetDevelopmentControlRoles />,
    exact: true,
  },
  {
    path: "/development-control-roles/view/:id",
    component: <DevelopmentControlRolesDetails />,
    exact: true,
  },

  {
    path: "/development-control-roles/update/:id",
    component: <SetDevelopmentControlRoles />,
    exact: true,
  },
  {
    path: "/development-control-roles/create/:id",
    component: <AddActors />,
    exact: true,
  },
  {
    path: "/development-control-roles/add-multiple-workflows/",
    component: <MultipleWorkflowsForm />,
    exact: true,
  },

  //LHT Rates
  {
    path: "/lht-rates",
    component: <LHTRatesRegister />,
    exact: true,
  },
  {
    path: "/lht-rates/create",
    component: <SetLHTRates />,
    exact: true,
  },
  {
    path: "/lht-rates/view/:id",
    component: <LHTRatesDetails />,
    exact: true,
  },
  {
    path: "/lht-rates/update/:id",
    component: <SetLHTRates />,
    exact: true,
  },

  //LST Rates
  {
    path: "/lst-rates",
    component: <LSTRatesRegister />,
    exact: true,
  },
  {
    path: "/lst-rates/create",
    component: <SetLSTRates />,
    exact: true,
  },
  {
    path: "/lst-rates/view/:id",
    component: <LSTRatesDetails />,
    exact: true,
  },
  {
    path: "/lst-rates/update/:id",
    component: <SetLSTRates />,
    exact: true,
  },

  //Land Use
  {
    path: "/land-use",
    component: <LandUseRegister />,
    exact: true,
  },
  {
    path: "/land-use/create",
    component: <SetLandUse />,
    exact: true,
  },
  {
    path: "/land-use/view/:id",
    component: <LandUseDetails />,
    exact: true,
  },
  {
    path: "/land-use/update/:id",
    component: <SetLandUse />,
    exact: true,
  },

  //All Configurables
  {
    path: "/all-configurables",
    component: <AllConfigurablesRegister />,
    exact: true,
  },
  {
    path: "/all-configurables/create",
    component: <SetAllConfigurables />,
    exact: true,
  },
  {
    path: "/all-configurables/view/:id",
    component: <AllConfigurablesDetails />,
    exact: true,
  },
  {
    path: "/all-configurables/update/:id",
    component: <SetAllConfigurables />,
    exact: true,
  },

  //permits professionals
  {
    path: "/professionals",
    component: <ProfessionalsRegister />,
    exact: true,
  },
  {
    path: "/professionals/create",
    component: <SetProfessional />,
    exact: true,
  },
  {
    path: "/professionals/view/:id",
    component: <ProfessionalDetails />,
    exact: true,
  },
  {
    path: "/professionals/update/:id",
    component: <SetProfessional />,
    exact: true,
  },

  //Self Employment Rates
  {
    path: "/self-employment-rates",
    component: <RatesRegister />,
    exact: true,
  },
  {
    path: "/self-employment-rates/create",
    component: <SetRate />,
    exact: true,
  },
  {
    path: "/self-employment-rates/view/:id",
    component: <RateDetails />,
    exact: true,
  },
  {
    path: "/self-employment-rates/update/:id",
    component: <SetRate />,
    exact: true,
  },

  //Annual Rates
  {
    path: "/annual-rates",
    component: <YearlyRatesRegister />,
    exact: true,
  },
  {
    path: "/annual-rates/create",
    component: <SetYearlyRates />,
    exact: true,
  },
  {
    path: "/annual-rates/view/:id",
    component: <YearlyRateDetails />,
    exact: true,
  },
  {
    path: "/annual-rates/update/:id",
    component: <SetYearlyRates />,
    exact: true,
  },

  //Annual Rates
  {
    path: "/planning-rates",
    component: <PlanningRatesRegister />,
    exact: true,
  },
  {
    path: "/planning-rates/create",
    component: <SetPlanningRates />,
    exact: true,
  },
  {
    path: "/planning-rates/view/:id",
    component: <PlanningRateDetails />,
    exact: true,
  },
  {
    path: "/planning-rates/update/:id",
    component: <SetPlanningRates />,
    exact: true,
  },

  //Escalation Periods
  {
    path: "/escalation-periods",
    component: <EscalationPerodsRegister />,
    exact: true,
  },
  {
    path: "/escalation-periods/create",
    component: <SetEscalationPerods />,
    exact: true,
  },
  {
    path: "/escalation-periods/view/:id",
    component: <EscalationPerodDetails />,
    exact: true,
  },
  {
    path: "/escalation-periods/update/:id",
    component: <SetEscalationPerods />,
    exact: true,
  },

  // Rateable Value Percentages
  {
    path: "/rateable-value-percentages",
    component: <RateablePercentagesRegister />,
    exact: true,
  },
  {
    path: "/rateable-value-percentages/create",
    component: <SetRateablePercentages />,
    exact: true,
  },
  {
    path: "/rateable-value-percentages/view/:id",
    component: <RateablePercentagesDetails />,
    exact: true,
  },
  {
    path: "/rateable-value-percentages/update/:id",
    component: <SetRateablePercentages />,
    exact: true,
  },

  // Rateable Value Percentages
  {
    path: "/gross-value-percentages",
    component: <GrossValuePercentagesRegister />,
    exact: true,
  },
  {
    path: "/gross-value-percentages/create",
    component: <SetGrossValuePercentages />,
    exact: true,
  },
  {
    path: "/gross-value-percentages/view/:id",
    component: <GrossValuePercentageDetails />,
    exact: true,
  },
  {
    path: "/gross-value-percentages/update/:id",
    component: <SetGrossValuePercentages />,
    exact: true,
  },

  //Valuation Periods
  {
    path: "/valuation-periods",
    component: <ValuationPeriodRegister />,
    exact: true,
  },
  {
    path: "/valuation-periods/create",
    component: <SetValuationPeriod />,
    exact: true,
  },
  {
    path: "/valuation-periods/view/:id",
    component: <ValuationPeriodDetails />,
    exact: true,
  },
  {
    path: "/valuation-periods/update/:id",
    component: <SetValuationPeriod />,
    exact: true,
  },

  //Lettable Area Percentages
  {
    path: "/lettable-area-percentages",
    component: <AreaPercentageRegister />,
    exact: true,
  },
  {
    path: "/lettable-area-percentages/create",
    component: <SetAreaPercentage />,
    exact: true,
  },
  {
    path: "/lettable-area-percentages/view/:id",
    component: <AreaPercentageDetails />,
    exact: true,
  },
  {
    path: "/lettable-area-percentages/update/:id",
    component: <SetAreaPercentage />,
    exact: true,
  },

  //Ahdoc Reports
  {
    path: `/${CONST_REPORTS}/adhoc`,
    component: <AdhocReports />,
    exact: true,
  },
  //Stages
  {
    path: "/stages",
    component: <StagesRegister />,
    exact: true,
  },
  {
    path: "/stages/create",
    component: <SetStage />,
    exact: true,
  },
  {
    path: "/stages/view/:id",
    component: <StageDetails />,
    exact: true,
  },
  {
    path: "/stages/update/:id",
    component: <SetStage />,
    exact: true,
  },

  //Hotel Classifications
  {
    path: "/hotel-classifications",
    component: <HotelClassificationRegister />,
    exact: true,
  },
  {
    path: "/hotel-classifications/create",
    component: <SetHotelClassification />,
    exact: true,
  },
  {
    path: "/hotel-classifications/view/:id",
    component: <HotelClassificationDetails />,
    exact: true,
  },
  {
    path: "/hotel-classifications/update/:id",
    component: <SetHotelClassification />,
    exact: true,
  },

  //Economic Growth
  {
    path: "/economic-growth",
    component: <EconomicGrowthRegister />,
    exact: true,
  },
  {
    path: "/economic-growth/create",
    component: <SetEconomicGrowth />,
    exact: true,
  },
  {
    path: "/economic-growth/view/:id",
    component: <EconomicGrowthDetails />,
    exact: true,
  },
  {
    path: "/economic-growth/update/:id",
    component: <SetEconomicGrowth />,
    exact: true,
  },

  //Economic Growth
  {
    path: "/forecast-config",
    component: <ForecastConfigRegister />,
    exact: true,
  },
  {
    path: "/forecast-config/create",
    component: <SetForecastConfig />,
    exact: true,
  },
  {
    path: "/forecast-config/view/:id",
    component: <ForecastConfigDetails />,
    exact: true,
  },
  {
    path: "/forecast-config/update/:id",
    component: <SetForecastConfig />,
    exact: true,
  },

  // ARREARS MANAGEMENT
  {
    path: "/:source_name/arrears/:source",
    component: <ArrearsManagementList />,
    exact: true,
  },
  {
    path: "/:source_name/arrears/:source/view/:id",
    component: <ArrearManagementDetails />,
    exact: true,
  },
  //Revenue Source
  {
    path: "/revenue-source",
    component: <RevenueSourcRegister />,
    exact: true,
  },
  {
    path: "/revenue-source/create",
    component: <SetRevenueSourc />,
    exact: true,
  },
  {
    path: "/revenue-source/view/:id",
    component: <RevenueSourcDetails />,
    exact: true,
  },
  {
    path: "/revenue-source/update/:id",
    component: <SetRevenueSourc />,
    exact: true,
  },

  //Revenue Share Ratio
  {
    path: "/revenue-share-ratio",
    component: <RevenueShareRatioRegister />,
    exact: true,
  },
  {
    path: "/revenue-share-ratio/create",
    component: <SetRevenueShareRatio />,
    exact: true,
  },
  {
    path: "/revenue-share-ratio/view/:id",
    component: <RevenueShareRatioDetails />,
    exact: true,
  },
  {
    path: "/revenue-share-ratio/update/:id",
    component: <SetRevenueShareRatio />,
    exact: true,
  },
  //Revenue Category
  {
    path: "/revenue-categories",
    component: <RevenueCategoryRegister />,
    exact: true,
  },
  {
    path: "/revenue-categories/create",
    component: <SetRevenueCategory />,
    exact: true,
  },
  {
    path: "/revenue-categories/view/:id",
    component: <RevenueCategoryDetails />,
    exact: true,
  },
  {
    path: "/revenue-categories/update/:id",
    component: <SetRevenueCategory />,
    exact: true,
  },

  //ENITIES TYPES
  {
    path: "/entity-types",
    component: <EntityTypeRegister />,
    exact: true,
  },
  {
    path: "/entity-types/create",
    component: <SetEntityType />,
    exact: true,
  },
  {
    path: "/entity-types/view/:id",
    component: <EntityTypeDetails />,
    exact: true,
  },
  {
    path: "/entity-types/update/:id",
    component: <SetEntityType />,
    exact: true,
  },

  //ENITIES SUB TYPES
  {
    path: "/entity-sub-types",
    component: <EntitySubTypeRegister />,
    exact: true,
  },
  {
    path: "/entity-sub-types/create",
    component: <SetEntitySubType />,
    exact: true,
  },
  {
    path: "/entity-sub-types/view/:id",
    component: <EntitySubTypeDetails />,
    exact: true,
  },
  {
    path: "/entity-sub-types/update/:id",
    component: <SetEntitySubType />,
    exact: true,
  },

  //Municipalities
  {
    path: "/municipalities",
    component: <MunicipalitiesRegister />,
    exact: true,
  },
  {
    path: "/municipalities/create",
    component: <SetMunicipality />,
    exact: true,
  },
  {
    path: "/municipalities/view/:id",
    component: <MunicipalityDetails />,
    exact: true,
  },

  //Revenue Source Items
  {
    path: "/revenue-source-items",
    component: <RevenueSourceItemsRegister />,
    exact: true,
  },
  {
    path: "/revenue-source-items/create",
    component: <SetRevenueSourceItems />,
    exact: true,
  },
  {
    path: "/revenue-source-items/view/:id",
    component: <RevenueSourceItemsDetails />,
    exact: true,
  },
  {
    path: "/revenue-source-items/update/:id",
    component: <SetRevenueSourceItems />,
    exact: true,
  },

  // Revenue Budget
  {
    path: "/revenue-budget",
    component: <RevenueBudgetRegister />,
    exact: true,
  },
  {
    path: "/revenue-budget/create",
    component: <SetRevenueBudget />,
    exact: true,
  },
  {
    path: "/revenue-budget/view/:id",
    component: <RevenueBudgetDetails />,
    exact: true,
  },
  {
    path: "/revenue-budget/update/:id",
    component: <SetRevenueBudget />,
    exact: true,
  },

  // VOTE CODES

  {
    path: "/vote-codes",
    component: <VoteCodesRegister />,
    exact: true,
  },

  {
    path: "/vote-codes/create",
    component: <SetVoteCodes />,
    exact: true,
  },

  {
    path: "/vote-codes/update/:id",
    component: <SetVoteCodes />,
    exact: true,
  },

  //Parishes
  {
    path: "/parishes",
    component: <ParishRegister />,
    exact: true,
  },
  {
    path: "/parishes/create",
    component: <SetParish />,
    exact: true,
  },
  {
    path: "/parishes/view/:id",
    component: <ParishDetails />,
    exact: true,
  },
  {
    path: "/parishes/update/:id",
    component: <SetParish />,
    exact: true,
  },

  //Villages
  {
    path: "/villages",
    component: <VillageRegister />,
    exact: true,
  },
  {
    path: "/villages/create",
    component: <SetVillage />,
    exact: true,
  },
  {
    path: "/villages/view/:id",
    component: <VillageDetails />,
    exact: true,
  },
  {
    path: "/villages/update/:id",
    component: <SetVillage />,
    exact: true,
  },

  //Divisions
  {
    path: "/divisions",
    component: <DivisionRegister />,
    exact: true,
  },
  {
    path: "/divisions/create",
    component: <SetDivision />,
    exact: true,
  },
  {
    path: "/divisions/view/:id",
    component: <DivisionDetails />,
    exact: true,
  },
  {
    path: "/divisions/update/:id",
    component: <SetDivision />,
    exact: true,
  },

  //STREETS
  {
    path: "/streets/create",
    component: <SetStreet />,
    exact: true,
  },
  {
    path: "/streets/view/:id",
    component: <StreetDetails />,
    exact: true,
  },
  {
    path: "/streets/update/:id",
    component: <SetStreet />,
    exact: true,
  },
  {
    path: "/streets",
    component: <StreetsRegister />,
    exact: true,
  },

  //USAGE
  {
    path: "/usage/create",
    component: <SetUsage />,
    exact: true,
  },
  {
    path: "/usage/view/:id",
    component: <UsageDetails />,
    exact: true,
  },
  {
    path: "/usage/update/:id",
    component: <SetUsage />,
    exact: true,
  },
  {
    path: "/usage",
    component: <UsageRegister />,
    exact: true,
  },

  //REASON FOR OBJECTION
  {
    path: "/reason-for-objection/create",
    component: <SetReason />,
    exact: true,
  },
  {
    path: "/reason-for-objection/view/:id",
    component: <ReasonDetails />,
    exact: true,
  },
  {
    path: "/reason-for-objection/update/:id",
    component: <SetReason />,
    exact: true,
  },
  {
    path: "/reason-for-objection/",
    component: <ReasonRegister />,
    exact: true,
  },

  //BUILDING CONDITION
  {
    path: "/building-condition/create",
    component: <SetCondition />,
    exact: true,
  },
  {
    path: "/building-condition/view/:id",
    component: <ConditionDetails />,
    exact: true,
  },
  {
    path: "/building-condition/update/:id",
    component: <SetCondition />,
    exact: true,
  },
  {
    path: "/building-condition/",
    component: <ConditionRegister />,
    exact: true,
  },

  //APPLICATION FEES
  {
    path: "/application-fees/create",
    component: <SetApplicationFees />,
    exact: true,
  },
  {
    path: "/application-fees/view/:id",
    component: <ApplicationFeesDetails />,
    exact: true,
  },
  {
    path: "/application-fees/update/:id",
    component: <SetApplicationFees />,
    exact: true,
  },
  {
    path: "/application-fees/",
    component: <ApplicationFeesRegister />,
    exact: true,
  },

  //WASTE FEES
  {
    path: "/waste-fees/create",
    component: <SetWasteFees />,
    exact: true,
  },
  {
    path: "/waste-fees/view/:id",
    component: <WasteFeesDetails />,
    exact: true,
  },
  {
    path: "/waste-fees/update/:id",
    component: <SetWasteFees />,
    exact: true,
  },
  {
    path: "/waste-fees/",
    component: <WasteFeesRegister />,
    exact: true,
  },

  //SIGN FEES
  {
    path: "/sign-fees/create",
    component: <SetSignFees />,
    exact: true,
  },
  {
    path: "/sign-fees/view/:id",
    component: <SignFeesDetails />,
    exact: true,
  },
  {
    path: "/sign-fees/update/:id",
    component: <SetSignFees />,
    exact: true,
  },
  {
    path: "/sign-fees/",
    component: <SignFeesRegister />,
    exact: true,
  },

  //SUB USAGE
  {
    path: "/sub-usage/create",
    component: <SetSubUsage />,
    exact: true,
  },
  {
    path: "/sub-usage/view/:id",
    component: <SubUsageDetails />,
    exact: true,
  },
  {
    path: "/sub-usage/update/:id",
    component: <SetSubUsage />,
    exact: true,
  },
  {
    path: "/sub-usage",
    component: <SubUsageRegister />,
    exact: true,
  },

  //PERMIT FEES
  {
    path: "/permit-fees/create",
    component: <SetPermitFees />,
    exact: true,
  },
  {
    path: "/permit-fees/view/:id",
    component: <PermitFeesDetails />,
    exact: true,
  },
  {
    path: "/permit-fees/update/:id",
    component: <SetPermitFees />,
    exact: true,
  },
  {
    path: "/permit-fees",
    component: <PermitFeesRegister />,
    exact: true,
  },

  //OTHER PERMIT FEES
  {
    path: "/other-permit-fees/create",
    component: <SetOtherPermitFees />,
    exact: true,
  },
  {
    path: "/other-permit-fees/view/:id",
    component: <OtherPermitFeesDetails />,
    exact: true,
  },
  {
    path: "/other-permit-fees/update/:id",
    component: <SetOtherPermitFees />,
    exact: true,
  },
  {
    path: "/other-permit-fees",
    component: <OtherPermitFeesRegister />,
    exact: true,
  },

  //Routes
  {
    path: "/routes",
    component: <RoutesRegister />,
    exact: true,
  },
  {
    path: "/routes/create",
    component: <SetRoute />,
    exact: true,
  },
  {
    path: "/routes/batch-upload",
    component: <BatchUploadForm />,
    exact: true,
  },
  {
    path: "/routes/view/:id",
    component: <RouteDetails />,
    exact: true,
  },
  {
    path: "/routes/update/:id",
    component: <SetRoute />,
    exact: true,
  },

  //Rent and Rates
  {
    path: "/rent-and-rates/:status",
    component: <RentandRatesRegister />,
    exact: true,
  },
  {
    path: "/rent-and-rates/create",
    component: <SetRentandRate />,
    exact: true,
  },
  {
    path: "/rent-and-rates/view/:id",
    component: <RentandRateDetails />,
    exact: true,
  },
  {
    path: "/rent-and-rates/update/:id",
    component: <SetRentandRate />,
    exact: true,
  },
  {
    path: "/rent-and-rates/business_deactivation/:id",
    component: <RentandRateBusinessDeactivation />,
    exact: true,
  },

  //Business categories in rs dropdowns
  {
    path: "/business-category",
    component: <BusinessCatRegister />,
    exact: true,
  },
  {
    path: "/business-category/create",
    component: <SetBusinessCat />,
    exact: true,
  },
  {
    path: "/business-category/view/:id",
    component: <BusinessCatDetails />,
    exact: true,
  },
  {
    path: "/business-category/update/:id",
    component: <SetBusinessCat />,
    exact: true,
  },

  //Rent payment interval in rs dropdowns
  {
    path: "/rent-payment-interval",
    component: <RentPaymentIntervalRegister />,
    exact: true,
  },
  {
    path: "/rent-payment-interval/create",
    component: <SetRentPaymentInterval />,
    exact: true,
  },
  {
    path: "/rent-payment-interval/view/:id",
    component: <RentPaymentIntervalDetails />,
    exact: true,
  },
  {
    path: "/rent-payment-interval/update/:id",
    component: <SetRentPaymentInterval />,
    exact: true,
  },

  //Development Control
  // //Professionals
  // {
  //   path: "/professionals/",
  //   component: <PermitsRegister />,
  //   exact: true,
  // },

  //Chainlink permit
  {
    path: "/chainlink-permit/",
    component: <ChainLinkRegister />,
    exact: true,
  },
  {
    path: "/chainlink-permit/:status",
    component: <ChainLinkPermitRegister />,
    exact: true,
  },
  {
    path: "/chainlink-permit/view/:id/:status",
    component: <ChainLinkPermitDetails />,
    exact: true,
  },
  {
    path: "/chainlink-permit/create",
    component: <ChainLinkPermitForm />,
    exact: true,
  },
  {
    path: "/chainlink-permit/schedule/:id",
    component: <SetChainLinkScheduleInspection />,
    exact: true,
  },
  {
    path: "/chainlink-permit/view-schedule/:id",
    component: <ChainLinkPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/chainlink-permit/inspect/:id",
    component: <SetChainLinkPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/chainlink-permit/view-report/:id",
    component: <ChainlinkPermitViewReport />,
    exact: true,
  },
  {
    path: "/chainlink-permit/review/:empty/:id",
    component: <ChainlinkPermitSetReview />,
    exact: true,
  },
  {
    path: "/chainlink-permit/reviewers/:id",
    component: <ChainlinkPermitReviewers />,
    exact: true,
  },
  {
    path: "/chainlink-permit/reviewed-report/:id",
    component: <ChainlinkPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/chainlink-permit/questions-report/",
    component: <ChainlinkPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/chainlink-permit/recommend-permit/:id",
    component: <ChainlinkPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/chainlink-permit/ppc-report/:id",
    component: <ChainlinkPermitPPCReport />,
    exact: true,
  },
  {
    path: "/chainlink-permit/ppc-schedule/:id",
    component: <ChainlinkPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/chainlink-permit/decision-report/:id",
    component: <ChainlinkPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/chainlink-permit/decision-schedule/:id",
    component: <ChainlinkPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/chainlink-permit/update/:id/:status",
    component: <ChainlinkPermitEdit />,
    exact: true,
  },
  {
    path: "/chainlink-permit/decide/:id",
    component: <ChainlinkPermitDecide />,
    exact: true,
  },
  {
    path: "/chainlink-permit/multiple-scheduling",
    component: <ChainlinkPermitSetMultipleScheduling />,
    exact: true,
  },

  //Excavation Permit
  {
    path: "/excavation-permit/",
    component: <ExcavationRegister />,
    exact: true,
  },
  {
    path: "/excavation-permit/create",
    component: <ExcavationPermitForm />,
    exact: true,
  },
  {
    path: "/excavation-permit/:status",
    component: <ExcavationPermitRegister />,
    exact: true,
  },
  {
    path: "/excavation-permit/view/:id/:status",
    component: <ExcavationPermitDetails />,
    exact: true,
  },
  {
    path: "/excavation-permit/view-schedule/:id",
    component: <ExcavationPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/excavation-permit/schedule/:id",
    component: <SetExcavationScheduleInspection />,
    exact: true,
  },
  {
    path: "/excavation-permit/inspect/:id",
    component: <SetExcavationPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/excavation-permit/view-report/:id",
    component: <ExcavationPermitViewReport />,
    exact: true,
  },
  {
    path: "/excavation-permit/review/:empty/:id",
    component: <ExcavationPermitSetReview />,
    exact: true,
  },
  {
    path: "/excavation-permit/reviewers/:id",
    component: <ExcavationPermitReviewers />,
    exact: true,
  },
  {
    path: "/excavation-permit/reviewed-report/:id",
    component: <ExcavationPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/excavation-permit/questions-report/",
    component: <ExcavationPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/excavation-permit/recommend-permit/:id",
    component: <ExcavationPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/excavation-permit/ppc-report/:id",
    component: <ExcavationPermitPPCReport />,
    exact: true,
  },
  {
    path: "/excavation-permit/ppc-schedule/:id",
    component: <ExcavationPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/excavation-permit/decision-report/:id",
    component: <ExcavationPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/excavation-permit/decision-schedule/:id",
    component: <ExcavationPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/excavation-permit/decide/:id",
    component: <ExcavationPermitDecide />,
    exact: true,
  },
  {
    path: "/excavation-permit/multiple-scheduling",
    component: <ExcavationPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/excavation-permit/update/:id/:status",
    component: <ExcavationPermitEdit />,
    exact: true,
  },

  //Outdoor advertisement permit
  {
    path: "/outdoor-advertisement-permit/",
    component: <OutdoorAdvertisementRegister />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/",
    component: <OutdoorAdvertisementRegister />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/create",
    component: <OutdoorAdvertisementPermitForm />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/:status",
    component: <OutdoorAdvertisementPermitRegister />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/view/:id/:status",
    component: <OutdoorAdvertisementPermitDetails />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/schedule/:id",
    component: <SetOutdoorAdvertisementScheduleInspection />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/view-schedule/:id",
    component: <OutdoorAdvertisementPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/inspect/:id",
    component: <SetOutdoorAdvertisementPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/view-report/:id",
    component: <OutdoorAdvertisementPermitViewReport />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/review/:empty/:id",
    component: <OutdoorAdvertisementPermitSetReview />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/reviewers/:id",
    component: <OutdoorAdvertisementPermitReviewers />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/reviewed-report/:id",
    component: <OutdoorAdvertisementPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/questions-report/",
    component: <OutdoorAdvertisementPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/recommend-permit/:id",
    component: <OutdoorAdvertisementPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/ppc-report/:id",
    component: <OutdoorAdvertisementPermitPPCReport />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/ppc-schedule/:id",
    component: <OutdoorAdvertisementPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/decision-report/:id",
    component: <OutdoorAdvertisementPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/decision-schedule/:id",
    component: <OutdoorAdvertisementPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/decide/:id",
    component: <OutdoorAdvertisementPermitDecide />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/multiple-scheduling",
    component: <OutdoorAdvertisementSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/outdoor-advertisement-permit/update/:id/:status",
    component: <OutdoorAdvertisementPermitEdit />,
    exact: true,
  },

  //Hoarding permit
  {
    path: "/hoarding-permit/",
    component: <HoardingRegister />,
    exact: true,
  },
  {
    path: "/hoarding-permit/create",
    component: <HoardingPermitForm />,
    exact: true,
  },
  {
    path: "/hoarding-permit/:status",
    component: <HoardingPermitRegister />,
    exact: true,
  },
  {
    path: "/hoarding-permit/view/:id/:status",
    component: <HoardingPermitDetails />,
    exact: true,
  },
  {
    path: "/hoarding-permit/schedule/:id",
    component: <SetHoardingScheduleInspection />,
    exact: true,
  },
  {
    path: "/hoarding-permit/view-schedule/:id",
    component: <HoardingPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/hoarding-permit/inspect/:id",
    component: <SetHoardingPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/hoarding-permit/reviewers/:id",
    component: <HoardingPermitReviewers />,
    exact: true,
  },
  {
    path: "/hoarding-permit/view-report/:id",
    component: <HoardingPermitViewReport />,
    exact: true,
  },
  {
    path: "/hoarding-permit/review/:empty/:id",
    component: <HoardingPermitSetReview />,
    exact: true,
  },
  {
    path: "/hoarding-permit/reviewed-report/:id",
    component: <HoardingPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/hoarding-permit/questions-report/",
    component: <HoardingPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/hoarding-permit/recommend-permit/:id",
    component: <HoardingPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/hoarding-permit/ppc-report/:id",
    component: <HoardingPermitPPCReport />,
    exact: true,
  },
  {
    path: "/hoarding-permit/ppc-schedule/:id",
    component: <HoardingPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/hoarding-permit/decision-report/:id",
    component: <HoardingPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/hoarding-permit/decision-schedule/:id",
    component: <HoardingPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/hoarding-permit/decide/:id",
    component: <HoardingPermitDecide />,
    exact: true,
  },
  {
    path: "/hoarding-permit/multiple-scheduling",
    component: <HoardingPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/hoarding-permit/update/:id/:status",
    component: <HoardingPermitEdit />,
    exact: true,
  },

  //Occupational Permit
  {
    path: "/occupation-permit/",
    component: <OccupationalRegister />,
    exact: true,
  },
  {
    path: "/occupational-permit/create",
    component: <OccupationalPermitForm />,
    exact: true,
  },
  {
    path: "/occupational-permit/:status",
    component: <OccupationalPermitRegister />,
    exact: true,
  },
  {
    path: "/occupational-permit/view/:id/:status",
    component: <OccupationalPermitDetails />,
    exact: true,
  },
  {
    path: "/occupational-permit/schedule/:id",
    component: <SetOccupationScheduleInspection />,
    exact: true,
  },
  {
    path: "/occupational-permit/view-schedule/:id",
    component: <OccupationalPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/occupational-permit/inspect/:id",
    component: <SetOccupationalPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/occupational-permit/reviewers/:id",
    component: <OccupationalPermitReviewers />,
    exact: true,
  },
  {
    path: "/occupational-permit/view-report/:id",
    component: <OccupationalPermitViewReport />,
    exact: true,
  },
  {
    path: "/occupational-permit/review/:empty/:id",
    component: <OccupationalPermitSetReview />,
    exact: true,
  },
  {
    path: "/occupational-permit/reviewed-report/:id",
    component: <OccupationalPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/occupational-permit/questions-report/",
    component: <OccupationalPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/occupational-permit/recommend-permit/:id",
    component: <OccupationalPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/occupational-permit/ppc-report/:id",
    component: <OccupationalPermitPPCReport />,
    exact: true,
  },
  {
    path: "/occupational-permit/ppc-schedule/:id",
    component: <OccupationalPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/occupational-permit/decision-report/:id",
    component: <OccupationalPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/occupational-permit/decision-schedule/:id",
    component: <OccupationalPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/occupational-permit/decide/:id",
    component: <OccupationalPermitDecide />,
    exact: true,
  },
  {
    path: "/occupational-permit/multiple-scheduling",
    component: <OccupationalPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/occupational-permit/update/:id/:status",
    component: <OccupationalPermitEdit />,
    exact: true,
  },

  //Demolition Permit
  {
    path: "/demolition-permit/",
    component: <DemolitionRegister />,
    exact: true,
  },
  {
    path: "/demolition-permit/create",
    component: <DemolitionPermitForm />,
    exact: true,
  },
  {
    path: "/demolition-permit/:status",
    component: <DemolitionPermitRegister />,
    exact: true,
  },
  {
    path: "/demolition-permit/view/:id/:status",
    component: <DemolitionPermitDetails />,
    exact: true,
  },
  {
    path: "/demolition-permit/schedule/:id",
    component: <SetDemolitionScheduleInspection />,
    exact: true,
  },
  {
    path: "/demolition-permit/view-schedule/:id",
    component: <DemolitionPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/demolition-permit/inspect/:id",
    component: <SetDemolitionPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/demolition-permit/view-report/:id",
    component: <DemolitionPermitViewReport />,
    exact: true,
  },
  {
    path: "/demolition-permit/review/:empty/:id",
    component: <DemolitionPermitSetReview />,
    exact: true,
  },
  {
    path: "/demolition-permit/reviewers/:id",
    component: <DemolitionPermitReviewers />,
    exact: true,
  },
  {
    path: "/demolition-permit/reviewed-report/:id",
    component: <DemolitionPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/demolition-permit/questions-report/",
    component: <DemolitionPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/demolition-permit/recommend-permit/:id",
    component: <DemolitionPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/demolition-permit/ppc-report/:id",
    component: <DemolitionPermitPPCReport />,
    exact: true,
  },
  {
    path: "/demolition-permit/ppc-schedule/:id",
    component: <DemolitionPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/demolition-permit/decision-report/:id",
    component: <DemolitionPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/demolition-permit/decision-schedule/:id",
    component: <DemolitionPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/demolition-permit/decide/:id",
    component: <DemolitionPermitDecide />,
    exact: true,
  },
  {
    path: "/demolition-permit/multiple-scheduling",
    component: <DemolitionPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/demolition-permit/update/:id/:status",
    component: <DemolitonPermitEdit />,
    exact: true,
  },

  // Subject Matter Categories
  {
    path: "/markets/create",
    component: <SetMarkets />,
    exact: true,
  },
  {
    path: "/markets/view/:id",
    component: <MarketsDetails />,
    exact: true,
  },
  {
    path: "/markets/update/:id",
    component: <SetMarkets />,
    exact: true,
  },
  {
    path: "/markets",
    component: <MarketsRegister />,
    exact: true,
  },

  //TENDERER REVENUE SOURCE ITEM
  {
    path: "/tenderer-revenue-source-items/create",
    component: <SetTendererRevenueSourceItem />,
    exact: true,
  },
  {
    path: "/tenderer-revenue-source-items/view/:id",
    component: <TendererRevenueSourceItemDetails />,
    exact: true,
  },
  {
    path: "/tenderer-revenue-source-items/update/:id",
    component: <SetTendererRevenueSourceItem />,
    exact: true,
  },
  {
    path: "/tenderer-revenue-source-items",
    component: <TendererRevenueSourceItemRegister />,
    exact: true,
  },

  //MARKET STOCK TYPES
  {
    path: "/market-stock-types/create",
    component: <SetMarketStockTypes />,
    exact: true,
  },
  {
    path: "/market-stock-types/view/:id",
    component: <MarketStockTypesDetails />,
    exact: true,
  },
  {
    path: "/market-stock-types/update/:id",
    component: <SetMarketStockTypes />,
    exact: true,
  },
  {
    path: "/market-stock-types",
    component: <MarketStockTypesRegister />,
    exact: true,
  },

  //MARKET STOCK NUMBERS
  {
    path: "/market-facility-numbers/create",
    component: <SetMarketFacilityNumbers />,
    exact: true,
  },
  {
    path: "/market-facility-numbers/view/:id",
    component: <MarketFacilityNumbersDetails />,
    exact: true,
  },
  {
    path: "/market-facility-numbers/update/:id",
    component: <SetMarketFacilityNumbers />,
    exact: true,
  },
  {
    path: "/market-facility-numbers",
    component: <MarketFacilityNumbersRegister />,
    exact: true,
  },

  //MARKET DUES RATES
  {
    path: "/market-dues-rates/create",
    component: <SetMarketDuesRates />,
    exact: true,
  },
  {
    path: "/market-dues-rates/view/:id",
    component: <MarketDuesRatesDetails />,
    exact: true,
  },
  {
    path: "/market-dues-rates/update/:id",
    component: <SetMarketDuesRates />,
    exact: true,
  },
  {
    path: "/market-dues-rates",
    component: <MarketDuesRateRegister />,
    exact: true,
  },

  // Market Dues Items
  {
    path: "/market-dues-items/create",
    component: <SetMarketDuesItem />,
    exact: true,
  },
  {
    path: "/market-dues-items/view/:id",
    component: <MarketDuesItemsDetails />,
    exact: true,
  },
  {
    path: "/market-dues-items/update/:id",
    component: <SetMarketDuesItem />,
    exact: true,
  },
  {
    path: "/market-dues-items",
    component: <MarketDuesItemsRegister />,
    exact: true,
  },

  //MARKET FACILITY TYPES
  {
    path: "/market-facility-types/create",
    component: <SetMarketFacilityTypes />,
    exact: true,
  },
  {
    path: "/market-facility-types/view/:id",
    component: <MarketFacilitiesTypesDetails />,
    exact: true,
  },
  {
    path: "/market-facility-types/update/:id",
    component: <SetMarketFacilityTypes />,
    exact: true,
  },
  {
    path: "/market-facility-types",
    component: <MarketFacilityTypesRegister />,
    exact: true,
  },

  //MARKET FACILITIES
  {
    path: "/market-facilities/create",
    component: <SetMarketFacilities />,
    exact: true,
  },
  {
    path: "/market-facilities/view/:id",
    component: <MarketFacilitiesDetails />,
    exact: true,
  },
  {
    path: "/market-facilities/update/:id",
    component: <SetMarketFacilities />,
    exact: true,
  },
  {
    path: "/market-facilities",
    component: <MarketFacilitiesRegister />,
    exact: true,
  },

  //VEHICLE OPERTAION CATEGORIES
  {
    path: "/vehicle-operation-categories/create",
    component: <SetVehicleOpertationCategory />,
    exact: true,
  },
  {
    path: "/vehicle-operation-categories/view/:id",
    component: <VehicleOperationCategoryDetails />,
    exact: true,
  },
  {
    path: "/vehicle-operation-categories/update/:id",
    component: <SetVehicleOpertationCategory />,
    exact: true,
  },
  {
    path: "/vehicle-operation-categories",
    component: <VehicleOperationCategoryRegister />,
    exact: true,
  },

  //VEHICLE CLASSIFICATIONS
  {
    path: "/vehicle-classifications/create",
    component: <SetVehicleClassification />,
    exact: true,
  },
  {
    path: "/vehicle-classifications/view/:id",
    component: <VehicleClassificationDetails />,
    exact: true,
  },
  {
    path: "/vehicle-classifications/update/:id",
    component: <SetVehicleClassification />,
    exact: true,
  },
  {
    path: "/vehicle-classifications",
    component: <VehicleClassificationRegister />,
    exact: true,
  },

  //VEHICLE ASSESSMENTS
  {
    path: "/vehicle-assessments/create",
    component: <SetVehicleAssessment />,
    exact: true,
  },
  {
    path: "/vehicle-assessments/view/:id",
    component: <VehicleAssessmentDetails />,
    exact: true,
  },
  {
    path: "/vehicle-assessments/update/:id",
    component: <SetVehicleAssessment />,
    exact: true,
  },
  {
    path: "/vehicle-assessments",
    component: <VehicleAssessmentRegister />,
    exact: true,
  },

  //VEHICLE CATEGORY
  {
    path: "/vehicle-category/create",
    component: <SetVehicleCategory />,
    exact: true,
  },
  {
    path: "/vehicle-category/view/:id",
    component: <VehicleCategoryDetails />,
    exact: true,
  },
  {
    path: "/vehicle-category/update/:id",
    component: <SetVehicleCategory />,
    exact: true,
  },
  {
    path: "/vehicle-category",
    component: <VehicleCategoryRegister />,
    exact: true,
  },

  //VEHICLE PURPOSE
  {
    path: "/vehicle-purpose/create",
    component: <SetVehiclePurpose />,
    exact: true,
  },
  {
    path: "/vehicle-purpose/view/:id",
    component: <VehiclePurposeDetails />,
    exact: true,
  },
  {
    path: "/vehicle-purpose/update/:id",
    component: <SetVehiclePurpose />,
    exact: true,
  },
  {
    path: "/vehicle-purpose",
    component: <VehiclePurposeRegister />,
    exact: true,
  },

  //PARKS REGISTRATION
  {
    path: "/parks/create",
    component: <SetParks />,
    exact: true,
  },
  {
    path: "/parks/view/:id",
    component: <ParksDetails />,
    exact: true,
  },
  {
    path: "/parks/update/:id",
    component: <SetParks />,
    exact: true,
  },
  {
    path: "/parks",
    component: <ParksRegister />,
    exact: true,
  },

  //PARKS REGISTRATION
  {
    path: "/ontrs/create",
    component: <SetOntr />,
    exact: true,
  },
  {
    path: "/ontrs/view/:id",
    component: <OntrDetails />,
    exact: true,
  },
  {
    path: "/ontrs/update/:id",
    component: <SetOntr />,
    exact: true,
  },
  {
    path: "/ontrs/:status",
    component: <OntrRegister />,
    exact: true,
  },

  /** Amendments */
  {
    path: "/amendment/:revenue_source/:id",
    component: <RegistrationWiseAmendment />,
    exact: true,
  },
  {
    path: "/amendments/request",
    component: <AmendmentRequest />,
    exact: true,
  },
  {
    path: "/amendments/register/:status",
    component: <AmendmentRegister />,
    exact: true,
  },
  {
    path: "/amendments/view/:id",
    component: <AmendmentDetails />,
    exact: true,
  },

  /** Reassessments */
  {
    path: "/reassessment/:revenue_source/:id/:invoice_id/:date",
    component: <RegistrationWiseReassessment />,
    exact: true,
  },
  {
    path: "/reassessment/:revenue_source/:id/:invoice_id",
    component: <RegistrationWiseReassessment />,
    exact: true,
  },
  {
    path: "/reassessment/:revenue_source/:id/",
    component: <RegistrationWiseReassessment />,
    exact: true,
  },
  {
    path: "/reassessments/request",
    component: <ReassessmentApplication />,
    exact: true,
  },
  {
    path: "/reassessments/register/:status",
    component: <ReassessmentRegister />,
    exact: true,
  },
  {
    path: "/reassessments/view/:id",
    component: <ReassessmentDetails />,
    exact: true,
  },
  {
    path: "/lht-reassessments/:status",
    component: <LhtReassessmentRegister />,
    exact: true,
  },
  {
    path: "/lht-reassessments/view/:id",
    component: <LhtReassessmentDetails />,
    exact: true,
  },
  {
    path: "/lst-reassessments/:status",
    component: <LstReassessmentRegister />,
    exact: true,
  },
  {
    path: "/lst-reassessments/view/:id",
    component: <LstReassessmentDetails />,
    exact: true,
  },

  /** Business transfers */
  {
    path: "/business-transfers/request",
    component: <BusinessTransfer />,
    exact: true,
  },
  {
    path: "/business-transfers/view/:id",
    component: <BusinessTransferDetails />,
    exact: true,
  },
  {
    path: "/business-transfers/request/:revenuesource/:branchcode",
    component: <BusinessTransferApplication />,
    exact: true,
  },

  {
    path: "/business-transfers/register/:status",
    component: <BusinessTransferRegister />,
    exact: true,
  },

  /** Objections */
  {
    path: "/objection/:revenue_source/:id",
    component: <BusinessRegistrationObjection />,
    exact: true,
  },
  {
    path: "/objections/request",
    component: <ObjectionRequest />,
    exact: true,
  },
  {
    path: "/objections/request",
    component: <ObjectionRequest />,
    exact: true,
  },
  {
    path: "/objections/register/:status",
    component: <ObjectionRequestRegister />,
    exact: true,
  },
  {
    path: "/objections/view/:id",
    component: <ObjectionRequestDetails />,
    exact: true,
  },

  /** Business Deactivation */
  {
    path: "/business-deactivation/request",
    component: <BusinessDeactivationRequest />,
    exact: true,
  },
  {
    path: "/business-deactivation/register/:status",
    component: <BusinessDeactivationRegister />,
    exact: true,
  },
  {
    path: "/business-deactivation/view/:id",
    component: <BusinessDeactivationDetails />,
    exact: true,
  },

  {
    path: "/valuation-objection/:id",
    component: <ValuationObjectionHandler />,
    exact: true,
  },

  //TRADING LICENSE REGISTRATION
  {
    path: "/trading-license/create",
    component: <SetTradingLicense />,
    exact: true,
  },
  {
    path: "/trading-license/view/:id",
    component: <TradingLicenseDetails />,
    exact: true,
  },
  {
    path: "/trading-license/update/:id",
    component: <SetTradingLicense />,
    exact: true,
  },
  {
    path: "/trading-license/:status",
    component: <TradingLicenseRegister />,
    exact: true,
  },
  {
    path: "/trading-license/business_deactivation/:id",
    component: <TradingLicenseBusinessDeactivation />,
    exact: true,
  },

  //LOCAL HOTEL TAX REGISTRATION
  {
    path: "/local-hotel-tax/create",
    component: <SetLocalHotelTax />,
    exact: true,
  },
  {
    path: "/local-hotel-tax/view/:id",
    component: <LocalHotelTaxDetails />,
    exact: true,
  },
  {
    path: "/local-hotel-tax/update/:id",
    component: <SetLocalHotelTax />,
    exact: true,
  },
  {
    path: "/local-hotel-tax/:status",
    component: <LocalHotelTaxRegister />,
    exact: true,
  },
  {
    path: "/local-hotel-tax/business_deactivation/:id",
    component: <LocalHotelBusinessDeactivation />,
    exact: true,
  },

  //LOCAL SERVICE TAX REGISTRATION
  {
    path: "/local-service-tax/create",
    component: <SetLocalServiceTax />,
    exact: true,
  },
  {
    path: "/local-service-tax/view/:id",
    component: <LocalServiceTaxDetails />,
    exact: true,
  },
  {
    path: "/local-service-tax/update/:id",
    component: <SetLocalServiceTax />,
    exact: true,
  },
  {
    path: "/local-service-tax/:status",
    component: <LocalServiceTaxRegister />,
    exact: true,
  },
  {
    path: "/local-service-tax/business_deactivation/:id",
    component: <LocalServiceTaxBusinessDeactivation />,
    exact: true,
  },

  //GROUND RENT REGISTRATION
  {
    path: "/ground-rent/create",
    component: <SetGroundRent />,
    exact: true,
  },
  {
    path: "/ground-rent/view/:id",
    component: <GroundRentDetails />,
    exact: true,
  },
  {
    path: "/ground-rent/update/:id",
    component: <SetGroundRent />,
    exact: true,
  },
  {
    path: "/ground-rent/:status",
    component: <GroundRentRegister />,
    exact: true,
  },
  {
    path: "/ground-rent/business_deactivation/:id",
    component: <GroundRentBusinessDeactivation />,
    exact: true,
  },

  //TENDERER REGISTRATION
  {
    path: "/tenderer/create",
    component: <SetTenderer />,
    exact: true,
  },
  {
    path: "/tenderer/view/:id",
    component: <TendererDetails />,
    exact: true,
  },
  {
    path: "/tenderer/update/:id",
    component: <SetTenderer />,
    exact: true,
  },
  {
    path: "/tenderer/:status",
    component: <TendererRegister />,
    exact: true,
  },

  //OUTDOOR ADVERTISING REGISTRATION
  {
    path: "/outdoor-advertising/create",
    component: <SetOutdoorAdvertising />,
    exact: true,
  },
  {
    path: "/outdoor-advertising/view/:id",
    component: <OutdoorAdvertisingDetails />,
    exact: true,
  },
  {
    path: "/outdoor-advertising/update/:id",
    component: <SetOutdoorAdvertising />,
    exact: true,
  },
  {
    path: "/outdoor-advertising/:status",
    component: <OutdoorAdvertisingRegister />,
    exact: true,
  },
  {
    path: "/outdoor-advertising/business_deactivation/:id",
    component: <OutdoorAdvertisingBusinessDeactivation />,
    exact: true,
  },

  //OPEERATIONAL PERMIT REGISTRATION
  {
    path: "/operational-permit/create",
    component: <SetOperationalPermit />,
    exact: true,
  },
  {
    path: "/operational-permit/view/:id",
    component: <OperationalPermitDetails />,
    exact: true,
  },
  {
    path: "/operational-permit/update/:id",
    component: <SetOperationalPermit />,
    exact: true,
  },
  {
    path: "/operational-permit/:status",
    component: <OperationalPermitRegister />,
    exact: true,
  },
  {
    path: "/operational-permit/business_deactivation/:id",
    component: <OperationalPermitBusinessDeactivation />,
    exact: true,
  },

  //MARKET RENT REGISTRATION
  {
    path: "/market-rents/create",
    component: <SetMarketRent />,
    exact: true,
  },
  {
    path: "/market-rents/view/:id",
    component: <MarketRentsDetails />,
    exact: true,
  },
  {
    path: "/market-rents/update/:id",
    component: <SetMarketRent />,
    exact: true,
  },
  {
    path: "/market-rents/:status",
    component: <MarketRentsRegister />,
    exact: true,
  },
  {
    path: "/market-rents/business_deactivation/:id",
    component: <MarketRentsBusinessDeactivation />,
    exact: true,
  },

  //MARKET DUES REGISTRATION
  {
    path: "/market-dues/create",
    component: <SetMarketDues />,
    exact: true,
  },
  {
    path: "/market-dues/view/:id",
    component: <MarketDuesDetails />,
    exact: true,
  },
  {
    path: "/market-dues/update/:id",
    component: <SetMarketDues />,
    exact: true,
  },
  {
    path: "/market-dues/",
    component: <MarketDuesRegister />,
    exact: true,
  },

  //PROPERTY VALUATION REGISTRATION

  {
    path: "/property-valuation/create",
    component: <SetPropertyValuation />,
    exact: true,
  },
  {
    path: "/property-valuation/update/:id",
    component: <SetPropertyValuation />,
    exact: true,
  },
  {
    path: "/property-valuation/view/:id",
    component: <PropertyValuationDetails />,
    exact: true,
  },
  {
    path: "/property-valuation/view/edit/:id",
    component: <PropertyValuationView />,
    exact: true,
  },
  {
    path: "/property-valuation/:status",
    component: <PropertyValuationRegister />,
    exact: true,
  },

  // PROPERTY OBJECTIONS
  {
    path: "/property-valuation-objections/:status",
    component: <PropertyValuationObjectionRegister />,
    exact: true,
  },
  {
    path: "/property-valuation-objections/view/:id",
    component: <PropertyValuationObjectionDetails />,
    exact: true,
  },

  {
    path: "/property-valuation-objections/schedule/:id",
    component: <SetSchedulePropertyValuationObjection />,
    exact: true,
  },

  {
    path: "/property-valuation-objections/objector-representative/:id",
    component: <SetObjectorRepresentative />,
    exact: true,
  },

  {
    path: "/property-valuation-objections/hearing-date-change/:id",
    component: <SetHearingDateChange />,
    exact: true,
  },

  {
    path: "/property-valuation-objections/add-court-proceedings/:id",
    component: <SetCourtProceedings />,
    exact: true,
  },

  //PARK USER FEES REGISTRATION

  {
    path: "/park-user-fees/create",
    component: <ParkUseFeesForm type={TYPE_ADD} />,
    exact: true,
  },
  {
    path: "/park-user-fees/update/:id",
    component: <ParkUseFeesForm type={TYPE_EDIT} />,
    exact: true,
  },
  {
    path: "/park-user-fees/view/:id",
    component: <ParkFeesDetails />,
    exact: true,
  },
  {
    path: "/park-user-fees/:status",
    component: <ParkFeesRegister />,
    exact: true,
  },
  {
    path: "/park-user-fees/business_deactivation/:id",
    component: <ParkUserFeesBusinessDeactivation />,
    exact: true,
  },
  {
    path: "/reports/park-prn-sharing/",
    component: <ParkPrnsRegister />,
    exact: true,
  },
  {
    path: "/reports/park-payment-sharing/",
    component: <ParkPaymentsRegister />,
    exact: true,
  },
  {
    path: "/reports/park-user-payment/",
    component: <ParkUserPaymentsRegister />,
    exact: true,
  },
  {
    path: "/reports/park-user-pending-payment/",
    component: <ParkUserPaymentsRegister />,
    exact: true,
  },
  {
    path: "/park-user-payment/view/:id",
    component: <ParkUserPaymentsDetails />,
    exact: true,
  },
  {
    path: "/park-user-pending-payment/view/:id",
    component: <ParkUserPaymentsDetails />,
    exact: true,
  },
  //CONDOMINIUM PERMIT REGISTRATION
  {
    path: "/condominium-permit/",
    component: <CondominiumRegister />,
    exact: true,
  },
  {
    path: "/condominium-permit/create",
    component: <CondominiumPermitForm type={TYPE_ADD} />,
    exact: true,
  },
  {
    path: "/condominium-permit/update/:id",
    component: <CondominiumPermitForm type={TYPE_EDIT} />,
    exact: true,
  },
  {
    path: "/condominium-permit/view/:id/:status",
    component: <CondominiumDetails />,
    exact: true,
  },
  {
    path: "/condominium-permit/:status",
    component: <CondominiumPermitRegister />,
    exact: true,
  },
  {
    path: "/condominium-permit/schedule/:id",
    component: <SetCondominiumPermitScheduleInspection />,
    exact: true,
  },
  {
    path: "/condominium-permit/view-schedule/:id",
    component: <CondominiumPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/condominium-permit/inspect/:id",
    component: <SetCondominiumPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/condominium-permit/view-report/:id",
    component: <CondominiumPermitViewReport />,
    exact: true,
  },
  {
    path: "/condominium-permit/review/:empty/:id",
    component: <SetCondominiumPermitReview />,
    exact: true,
  },
  {
    path: "/condominium-permit/reviewers/:id",
    component: <CondominiumPermitReviewers />,
    exact: true,
  },
  {
    path: "/condominium-permit/reviewed-report/:id",
    component: <CondominiumPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/condominium-permit/questions-report/",
    component: <CondominiumPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/condominium-permit/recommend-permit/:id",
    component: <CondominiumPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/condominium-permit/ppc-report/:id",
    component: <CondominiumPermitPPCReport />,
    exact: true,
  },
  {
    path: "/condominium-permit/ppc-schedule/:id",
    component: <CondominiumPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/condominium-permit/decision-report/:id",
    component: <CondominiumPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/condominium-permit/decision-schedule/:id",
    component: <CondominiumPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/condominium-permit/decide/:id",
    component: <CondominiumPermitDecide />,
    exact: true,
  },
  {
    path: "/condominium-permit/multiple-scheduling",
    component: <CondominiumPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/condominium-permit/update/:id/:status",
    component: <CondominiumPermitEdit />,
    exact: true,
  },

  //PROPERTY TAX REGISTRATION
  {
    path: "/property-tax/create",
    component: <SetProperty />,
    exact: true,
  },
  {
    path: "/property-tax/update/:id",
    component: <SetProperty />,
    exact: true,
  },
  {
    path: "/property-tax/view/:id",
    component: <PropertyDetails />,
    exact: true,
  },
  {
    path: "/property-tax/:status",
    component: <PropertyRegister />,
    exact: true,
  },
  {
    path: "/property-tax/business_deactivation/:id",
    component: <PropertyBusinessDeactivation />,
    exact: true,
  },

  // Permission
  {
    path: "/permissions/create",
    component: <SetPermission />,
    exact: true,
  },
  {
    path: "/permissions/view/:id",
    component: <PermissionDetails />,
    exact: true,
  },
  {
    path: "/permissions/update/:id",
    component: <SetPermission />,
    exact: true,
  },
  {
    path: "/permissions",
    component: <PermissionRegister />,
    exact: true,
  },
  //MDAs
  {
    path: "/roles",
    component: <RoleRegister />,
    exact: true,
  },
  {
    path: "/roles/create",
    component: <SetRole />,
    exact: true,
  },
  {
    path: "/roles/view/:id",
    component: <RoleDetails />,
    exact: true,
  },
  {
    path: "/roles/update/:id",
    component: <SetRole />,
    exact: true,
  },

  //WALLS
  {
    path: "/walls",
    component: <WallsRegister />,
    exact: true,
  },
  {
    path: "/walls/create",
    component: <SetWalls />,
    exact: true,
  },
  {
    path: "/walls/view/:id",
    component: <WallsDetails />,
    exact: true,
  },
  {
    path: "/walls/update/:id",
    component: <SetWalls />,
    exact: true,
  },

  //DEMAND NOTICE
  {
    path: "/mobile-contacts-configure/create",
    component: <SetLocalGovernmentContacts />,
    exact: true,
  },

  //TENDER VALIDITY
  {
    path: "/tender-validity",
    component: <ValidityRegister />,
    exact: true,
  },
  {
    path: "/tender-validity/create",
    component: <SetValidity />,
    exact: true,
  },
  {
    path: "/tender-validity/view/:id",
    component: <ValidityDetails />,
    exact: true,
  },
  {
    path: "/tender-validity/update/:id",
    component: <SetValidity />,
    exact: true,
  },

  {
    path: "/tender-validity",
    component: <ValidityRegister />,
    exact: true,
  },
  {
    path: "/tender-validity/create",
    component: <SetValidity />,
    exact: true,
  },
  {
    path: "/tender-validity/view/:id",
    component: <ValidityDetails />,
    exact: true,
  },
  {
    path: "/tender-validity/update/:id",
    component: <SetValidity />,
    exact: true,
  },

  // PAYMENT INTERVALS
  {
    path: "/payment-intervals",
    component: <PaymentIntervalRegister />,
    exact: true,
  },
  {
    path: "/payment-intervals/create",
    component: <SetPaymentInterval />,
    exact: true,
  },
  {
    path: "/payment-intervals/view/:id",
    component: <PaymentIntervalDetails />,
    exact: true,
  },
  {
    path: "/payment-intervals/update/:id",
    component: <SetPaymentInterval />,
    exact: true,
  },

  //OBJECTION REASONS
  {
    path: "/objections",
    component: <ObjectionRegister />,
    exact: true,
  },
  {
    path: "/objections/create",
    component: <SetObjection />,
    exact: true,
  },
  {
    path: "/objections/view/:id",
    component: <ObjectionDetails />,
    exact: true,
  },
  {
    path: "/objections/update/:id",
    component: <SetObjection />,
    exact: true,
  },

  //LEASE STATUSES
  {
    path: "/lease-status",
    component: <LeaseStatusRegister />,
    exact: true,
  },
  {
    path: "/lease-status/create",
    component: <SetLeaseStatus />,
    exact: true,
  },
  {
    path: "/lease-status/view/:id",
    component: <LeaseStatusDetails />,
    exact: true,
  },
  {
    path: "/lease-status/update/:id",
    component: <SetLeaseStatus />,
    exact: true,
  },

  //LAND TITLE
  {
    path: "/land-title",
    component: <LandTitleRegister />,
    exact: true,
  },
  {
    path: "/land-title/create",
    component: <SetLandTitle />,
    exact: true,
  },
  {
    path: "/land-title/view/:id",
    component: <LandTitleDetails />,
    exact: true,
  },
  {
    path: "/land-title/update/:id",
    component: <SetLandTitle />,
    exact: true,
  },

  //CONSTRUCTION COMPLETION
  {
    path: "/construction-completion",
    component: <ConstructionCompletionRegister />,
    exact: true,
  },
  {
    path: "/construction-completion/create",
    component: <SetConstructionCompletion />,
    exact: true,
  },
  {
    path: "/construction-completion/view/:id",
    component: <ConstructionCompletionDetails />,
    exact: true,
  },
  {
    path: "/construction-completion/update/:id",
    component: <SetConstructionCompletion />,
    exact: true,
  },

  //BUILDING TYPES
  {
    path: "/building-types",
    component: <BuildingTypeRegister />,
    exact: true,
  },
  {
    path: "/building-types/create",
    component: <SetBuildingType />,
    exact: true,
  },
  {
    path: "/building-types/view/:id",
    component: <BuildingTypeDetails />,
    exact: true,
  },
  {
    path: "/building-types/update/:id",
    component: <SetBuildingType />,
    exact: true,
  },

  //TOOL TYPES
  {
    path: "/tool-types",
    component: <ToolTypeRegister />,
    exact: true,
  },
  {
    path: "/tool-types/create",
    component: <SetToolType />,
    exact: true,
  },
  {
    path: "/tool-types/view/:id",
    component: <ToolTypeDetails />,
    exact: true,
  },
  {
    path: "/tool-types/update/:id",
    component: <SetToolType />,
    exact: true,
  },

  //TOOL TYPES
  {
    path: "/tool-sizes",
    component: <ToolSizeRegister />,
    exact: true,
  },
  {
    path: "/tool-sizes/create",
    component: <SetToolSize />,
    exact: true,
  },
  {
    path: "/tool-sizes/view/:id",
    component: <ToolSizeDetails />,
    exact: true,
  },
  {
    path: "/tool-sizes/update/:id",
    component: <SetToolSize />,
    exact: true,
  },

  //TOOL TYPES
  {
    path: "/material-used",
    component: <MaterialUsedRegister />,
    exact: true,
  },
  {
    path: "/material-used/create",
    component: <SetMaterialUsed />,
    exact: true,
  },
  {
    path: "/material-used/view/:id",
    component: <MaterialUsedDetails />,
    exact: true,
  },
  {
    path: "/material-used/update/:id",
    component: <SetMaterialUsed />,
    exact: true,
  },

  //Business Category Amount
  {
    path: "/category-amount",
    component: <CategoryAmountRegister />,
    exact: true,
  },
  {
    path: "/category-amount/create",
    component: <SetCategoryAmount />,
    exact: true,
  },
  {
    path: "/category-amount/view/:id",
    component: <CategoryAmountDetails />,
    exact: true,
  },
  {
    path: "/category-amount/update/:id",
    component: <SetCategoryAmount />,
    exact: true,
  },
  //DEPARTMENTS
  {
    path: "/departments",
    component: <DepartmentsRegister />,
    exact: true,
  },
  {
    path: "/departments/create",
    component: <SetDepartments />,
    exact: true,
  },
  {
    path: "/departments/view/:id",
    component: <DepartmentDetails />,
    exact: true,
  },
  {
    path: "/departments/update/:id",
    component: <SetDepartments />,
    exact: true,
  },

  //FINISH WALLS
  {
    path: "/finish-walls",
    component: <FinishWallsRegister />,
    exact: true,
  },
  {
    path: "/finish-walls/create",
    component: <SetFinishWalls />,
    exact: true,
  },
  {
    path: "/finish-walls/view/:id",
    component: <FinishWallsDetails />,
    exact: true,
  },
  {
    path: "/finish-walls/update/:id",
    component: <SetFinishWalls />,
    exact: true,
  },

  //FLOOR FINISH
  {
    path: "/floor-finish",
    component: <FloorFinishRegister />,
    exact: true,
  },
  {
    path: "/floor-finish/create",
    component: <SetFloorFinish />,
    exact: true,
  },
  {
    path: "/floor-finish/view/:id",
    component: <FloorFinishDetails />,
    exact: true,
  },
  {
    path: "/floor-finish/update/:id",
    component: <SetFloorFinish />,
    exact: true,
  },

  //ROOF COVERING
  {
    path: "/roof-covering",
    component: <RoofCoveringRegister />,
    exact: true,
  },
  {
    path: "/roof-covering/create",
    component: <SetRoofCovering />,
    exact: true,
  },
  {
    path: "/roof-covering/view/:id",
    component: <RoofCoveringDetails />,
    exact: true,
  },
  {
    path: "/roof-covering/update/:id",
    component: <SetRoofCovering />,
    exact: true,
  },

  //CEILING
  {
    path: "/ceiling",
    component: <CeilingRegister />,
    exact: true,
  },
  {
    path: "/ceiling/create",
    component: <SetCeiling />,
    exact: true,
  },
  {
    path: "/ceiling/view/:id",
    component: <CeilingDetails />,
    exact: true,
  },
  {
    path: "/ceiling/update/:id",
    component: <SetCeiling />,
    exact: true,
  },

  //Currencies
  {
    path: "/currencies",
    component: <CurrencyRegister />,
    exact: true,
  },
  {
    path: "/currencies/create",
    component: <SetCurrency />,
    exact: true,
  },
  {
    path: "/currencies/view/:id",
    component: <CurrencyDetails />,
    exact: true,
  },
  {
    path: "/currencies/update/:id",
    component: <SetCurrency />,
  },
  //BANK CODES
  {
    path: "/bank-codes",
    component: <BankCodesRegister />,
    exact: true,
  },
  {
    path: "/bank-codes/create",
    component: <SetBankCodes />,
    exact: true,
  },
  {
    path: "/bank-codes/view/:id",
    component: <BankCodesDetails />,
    exact: true,
  },
  {
    path: "/bank-codes/update/:id",
    component: <SetBankCodes />,
    exact: true,
  },

  //SECURITY MEASURE
  {
    path: "/security-measures",
    component: <SecurityRegister />,
    exact: true,
  },
  {
    path: "/security-measures/create",
    component: <SetSecurityMeasure />,
    exact: true,
  },
  {
    path: "/security-measures/view/:id",
    component: <SecurityMeasureDetails />,
    exact: true,
  },
  {
    path: "/security-measures/update/:id",
    component: <SetSecurityMeasure />,
    exact: true,
  },

  //BOUNDARY FENCE
  {
    path: "/boundary-fence",
    component: <BoundaryFenceRegister />,
    exact: true,
  },
  {
    path: "/boundary-fence/create",
    component: <SetBoundaryFence />,
    exact: true,
  },
  {
    path: "/boundary-fence/view/:id",
    component: <BoundaryFenceDetails />,
    exact: true,
  },
  {
    path: "/boundary-fence/update/:id",
    component: <SetBoundaryFence />,
    exact: true,
  },

  //GRIEVANCES
  {
    path: "/grievances/create",
    component: <SetEnquiries />,
    exact: true,
  },
  {
    path: "/grievances/all-enquiries",
    component: <AssignedEnquiries />,
    exact: true,
  },
  {
    path: "/grievances/user-grievances",
    component: <UserGrievancesRegister />,
    exact: true,
  },
  {
    path: "/grievances/view/:id",
    component: <EnquiriesDetails />,
    exact: true,
  },
  {
    path: "/grievances/update/:id",
    component: <SetEnquiries />,
    exact: true,
  },
  {
    path: "/grievances/:status",
    component: <EnquiriesRegister />,
    exact: true,
  },

  {
    path: "/grievances/query-assignments/:overdue",
    component: <OverdueRegister />,
    exact: true,
  },

  {
    path: "/grievances/assign-query/:id",
    component: <SetAssignQuery />,
    exact: true,
  },

  {
    path: "/grievances/report-query/:id",
    component: <SetReportQuery />,
    exact: true,
  },

  //ACCOMMODATION CATEGORY
  {
    path: "/accommondation-categories",
    component: <AccommodationCategoryRegister />,
    exact: true,
  },
  {
    path: "/accommondation-categories/create",
    component: <SetAccommodationCategory />,
    exact: true,
  },
  {
    path: "/accommondation-categories/view/:id",
    component: <AccommodationCategoryDetails />,
    exact: true,
  },
  {
    path: "/accommondation-categories/update/:id",
    component: <SetAccommodationCategory />,
    exact: true,
  },
  //ADVERTISEMENT CLASSIFICATION
  {
    path: "/advertisement-classification/",
    component: <AdvertisementClassificationRegister />,
    exact: true,
  },
  {
    path: "/advertisement-classification/create",
    component: <SetAdvertisementClassification />,
    exact: true,
  },
  {
    path: "/advertisement-classification/view/:id",
    component: <AdvertisementClassificationDetails />,
    exact: true,
  },
  {
    path: "/advertisement-classification/update/:id",
    component: <SetAdvertisementClassification />,
    exact: true,
  },

  //OUTDOOR RATES
  {
    path: "/outdoor-rates/",
    component: <OutdoorRateRegister />,
    exact: true,
  },
  {
    path: "/outdoor-rates/create",
    component: <SetOutdoorRates />,
    exact: true,
  },
  {
    path: "/outdoor-rates/view/:id",
    component: <OutdoorRatesDetails />,
    exact: true,
  },
  {
    path: "/outdoor-rates/update/:id",
    component: <SetOutdoorRates />,
    exact: true,
  },
  //DEVELOPMENT CONTROL

  // ALL APPLICATIONS REGISTER
  {
    path: "/all-applications",
    component: <ApplicationsRegister />,
    exact: true,
  },
  // ROUTINE INSPECTION
  {
    path: "/routine-inspection/create",
    component: <RoutineInspectionForm />,
    exact: true,
  },
  {
    path: "/routine-inspection/create/:type/:id",
    component: <RoutineInspectionForm />,
    exact: true,
  },
  {
    path: "/routine-inspection",
    component: <RoutineInspectionRegister />,
    exact: true,
  },
  {
    path: "/routine-inspection/view/:id",
    component: <RoutineInspectionDetails />,
    exact: true,
  },

  // NOTIFICATION OF COMMENCEMENT
  {
    path: "/notification-of-commencement/create",
    component: <CommencementForm />,
    exact: true,
  },
  {
    path: "/notification-of-commencement",
    component: <CommencementRegister />,
    exact: true,
  },
  {
    path: "/notification-of-commencement/view/:id",
    component: <CommencementDetails />,
    exact: true,
  },

  // REQUEST FOR INSPECTION
  {
    path: "/requirement-for-inspection/create",
    component: <RequestForm />,
    exact: true,
  },
  {
    path: "/requirement-for-inspection",
    component: <RequestRegister />,
    exact: true,
  },
  {
    path: "/requirement-for-inspection/view/:id",
    component: <RequestDetails />,
    exact: true,
  },

  //ENFORCEMENTS
  {
    path: "/enforcements/issue-notice/:id",
    component: <EnforcementForm />,
    exact: true,
  },
  {
    path: "/enforcements/:status",
    component: <EnforcementRegister />,
    exact: true,
  },
  {
    path: "/enforcements/view/:id",
    component: <EnforcementDetails />,
    exact: true,
  },
  {
    path: "/enforcements/schedule/:id",
    component: <SetEnforcementsSchedulePPCMeeting />,
    exact: true,
  },
  {
    path: "/enforcements/decision-schedule/:id",
    component: <EnforcementDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/enforcements/decide/:id",
    component: <EnforcementDecide />,
    exact: true,
  },

  //DEVELOPMENT_PERMIT
  {
    path: "/development-permit/",
    component: <DevelopmentRegister />,
    exact: true,
  },
  {
    path: "/development-permit/create",
    component: <SetDevelopmentPermit />,
    exact: true,
  },
  {
    path: "/development-permit/view/:id/:status",
    component: <DevelopmentPermitDetails />,
    exact: true,
  },
  {
    path: "/development-permit/update/:id",
    component: <SetDevelopmentPermit />,
    exact: true,
  },
  {
    path: "/development-permit/:status",
    component: <DevelopmentPermitRegister />,
    exact: true,
  },
  {
    path: "/development-permit/schedule/:id",
    component: <SetScheduleInspection />,
    exact: true,
  },
  {
    path: "/development-permit/view-schedule/:id",
    component: <DevelopmentPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/development-permit/inspect/:id",
    component: <SetInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/development-permit/view-report/:id",
    component: <ViewReport />,
    exact: true,
  },
  {
    path: "/development-permit/review/:empty/:id",
    component: <SetReview />,
    exact: true,
  },
  {
    path: "/development-permit/reviewers/:id",
    component: <DevelopmentPermitReviewers />,
    exact: true,
  },
  {
    path: "/development-permit/reviewed-report/:id",
    component: <DevelopmentPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/development-permit/questions-report/",
    component: <DevelopmentPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/development-permit/recommend-permit/:id",
    component: <DevelopmentPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/development-permit/ppc-report/:id",
    component: <DevelopmentPermitPPCReport />,
    exact: true,
  },
  {
    path: "/development-permit/ppc-schedule/:id",
    component: <DevelopmentPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/development-permit/decision-report/:id",
    component: <DevelopmentPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/development-permit/decision-schedule/:id",
    component: <DevelopmentPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/development-permit/decide/:id",
    component: <DevelopmentPermitDecide />,
    exact: true,
  },
  {
    path: "/development-permit/multiple-scheduling",
    component: <DevelopmentPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/development-permit/update/:id/:status",
    component: <DevelopmentPermitEdit />,
    exact: true,
  },

  //SUBDIVISION_CONSOLIDATION_PERMIT
  {
    path: "/subdivision-consolidation-permit/",
    component: <SubdivisionConsolidationRegister />,
  },
  {
    path: "/subdivision-consolidation-permits/create",
    component: <SetSubdivisionConsolidationPermit />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/view/:id/:status",
    component: <SubdivisionConsolidationPermitDetails />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/update/:id",
    component: <SetSubdivisionConsolidationPermit />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/:status",
    component: <SubdivisionConsolidationPermitRegister />,
  },
  {
    path: "/subdivision-consolidation-permits/schedule/:id",
    component: <SetSubdivisionConsolidationPermitScheduleInspection />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/view-schedule/:id",
    component: <SubdivisionConsolidationPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/inspect/:id",
    component: <SetSubdivisionConsolidationPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/view-report/:id",
    component: <SubdivisionConsolidationViewReport />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/review/:empty/:id",
    component: <SubdivisionConsolidationSetReview />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/reviewed-report/:id",
    component: <SubdivisionConsolidationPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/questions-report/",
    component: <SubdivisionConsolidationPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/recommend-permit/:id",
    component: <SubdivisionConsolidationPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/ppc-report/:id",
    component: <SubdivisionConsolidationPermitPPCReport />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/ppc-schedule/:id",
    component: <SubdivisionConsolidationPermitSetSchedulePPCMeeting />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/decision-report/:id",
    component: <SubdivisionConsolidationPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/decision-schedule/:id",
    component: <SubdivisionConsolidationPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/decide/:id",
    component: <SubdivisionConsolidationPermitDecide />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/reviewers/:id",
    component: <SubdivisionConsolidationReviewers />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/multiple-scheduling",
    component: <SubdivisionConsolidationPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/subdivision-consolidation-permits/update/:id/:status",
    component: <SubdivisionConsolidationPermitEdit />,
    exact: true,
  },

  //BUILDING_PERMIT
  {
    path: "/building-permit/",
    component: <BuildingRegister />,
    exact: true,
  },
  {
    path: "/building-permit/create",
    component: <SetBuildingPermit />,
    exact: true,
  },
  {
    path: "/building-permit/view/:id/:status",
    component: <BuildingPermitDetails />,
    exact: true,
  },
  {
    path: "/building-permit/update/:id",
    component: <SetBuildingPermit />,
    exact: true,
  },
  {
    path: "/building-permit/:status",
    component: <BuildingPermitRegister />,

    exact: true,
  },
  {
    path: "/building-permit/schedule/:id",
    component: <SetBuildingPermitScheduleInspection />,
    exact: true,
  },
  {
    path: "/building-permit/view-schedule/:id",
    component: <BuildingPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/building-permit/inspect/:id",
    component: <SetBuildingPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/building-permit/view-report/:id",
    component: <ViewBuildingPermitReport />,
    exact: true,
  },
  {
    path: "/building-permit/review/:empty/:id",
    component: <SetBuildingPermitReview />,
    exact: true,
  },
  {
    path: "/building-permit/reviewed-report/:id",
    component: <BuildingPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/building-permit/questions-report/",
    component: <BuildingPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/building-permit/recommend-permit/:id",
    component: <BuildingPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/building-permit/ppc-report/:id",
    component: <BuildingPermitPPCReport />,
    exact: true,
  },
  {
    path: "/building-permit/ppc-schedule/:id",
    component: <BuildingPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/building-permit/decision-report/:id",
    component: <BuildingPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/building-permit/decision-schedule/:id",
    component: <BuildingPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/building-permit/decide/:id",
    component: <BuildingPermitDecide />,
    exact: true,
  },
  {
    path: "/building-permit/reviewers/:id",
    component: <BuildingPermitReviewers />,
    exact: true,
  },
  {
    path: "/building-permit/multiple-scheduling",
    component: <BuildingPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/building-permit/update/:id/:status",
    component: <BuildingPermitEdit />,
    exact: true,
  },

  //RENOVATION_PERMIT
  {
    path: "/renovation-permit/",
    component: <RenovationRegister />,
    exact: true,
  },
  {
    path: "/renovation-permit/create",
    component: <SetRenovationPermit />,
    exact: true,
  },
  {
    path: "/renovation-permit/view/:id/:status",
    component: <RenovationPermitDetails />,
    exact: true,
  },
  {
    path: "/renovation-permit/update/:id",
    component: <SetRenovationPermit />,
    exact: true,
  },
  {
    path: "/renovation-permit/:status",
    component: <RenovationPermitRegister />,
    exact: true,
  },
  {
    path: "/renovation-permit/schedule/:id",
    component: <SetRenovationPermitScheduleInspection />,
    exact: true,
  },
  {
    path: "/renovation-permit/view-schedule/:id",
    component: <RenovationPermitInspectionDetails />,
    exact: true,
  },
  {
    path: "/renovation-permit/inspect/:id",
    component: <SetRenovationPermitInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/renovation-permit/view-report/:id",
    component: <RenovationPermitViewReport />,
    exact: true,
  },
  {
    path: "/renovation-permit/review/:empty/:id",
    component: <SetRenovationPermitReview />,
    exact: true,
  },
  {
    path: "/renovation-permit/reviewed-report/:id",
    component: <RenovationPermitReviewedReport />,
    exact: true,
  },
  {
    path: "/renovation-permit/questions-report/",
    component: <RenovationPermitQuestionsReport />,
    exact: true,
  },
  {
    path: "/renovation-permit/recommend-permit/:id",
    component: <RenovationPermitSetRecommend />,
    exact: true,
  },
  {
    path: "/renovation-permit/ppc-report/:id",
    component: <RenovationPermitPPCReport />,
    exact: true,
  },
  {
    path: "/renovation-permit/ppc-schedule/:id",
    component: <RenovationPermitSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/renovation-permit/decision-report/:id",
    component: <RenovationPermitDecisionReport />,
    exact: true,
  },
  {
    path: "/renovation-permit/decision-schedule/:id",
    component: <RenovationPermitDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/renovation-permit/decide/:id",
    component: <RenovationPermitDecide />,
    exact: true,
  },
  {
    path: "/renovation-permit/reviewers/:id",
    component: <RenovationPermitReviewers />,
    exact: true,
  },
  {
    path: "/renovation-permit/multiple-scheduling",
    component: <RenovationPermitSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/renovation-permit/update/:id/:status",
    component: <RenovationPermitEdit />,
    exact: true,
  },

  //LEASE_APPLICATION
  {
    path: "/lease-application/",
    component: <LeaseApplicationsRegister />,
    exact: true,
  },
  {
    path: "/lease-application/create",
    component: <SetLeaseApplication />,
    exact: true,
  },
  {
    path: "/lease-application/view/:id/:status",
    component: <LeaseApplicationDetails />,
    exact: true,
  },
  {
    path: "/lease-application/update/:id",
    component: <SetLeaseApplication />,
    exact: true,
  },
  {
    path: "/lease-application/:status",
    component: <LeaseApplicationRegister />,
    exact: true,
  },
  {
    path: "/lease-application/schedule/:id",
    component: <SetLeaseApplicationInspection />,
    exact: true,
  },
  {
    path: "/lease-application/view-schedule/:id",
    component: <LeaseApplicationInspectionDetails />,
    exact: true,
  },
  {
    path: "/lease-application/inspect/:id",
    component: <SetLeaseApplicationInspectionQuestionnaire />,
    exact: true,
  },
  {
    path: "/lease-application/view-report/:id",
    component: <LeaseApplicationViewReport />,
    exact: true,
  },
  {
    path: "/lease-application/review/:empty/:id",
    component: <SetLeaseApplicationReview />,
    exact: true,
  },
  {
    path: "/lease-application/reviewed-report/:id",
    component: <LeaseApplicationReviewedReport />,
    exact: true,
  },
  {
    path: "/lease-application/questions-report/",
    component: <LeaseApplicationQuestionsReport />,
    exact: true,
  },
  {
    path: "/lease-application/recommend-permit/:id",
    component: <LeaseApplicationSetRecommend />,
    exact: true,
  },
  {
    path: "/lease-application/ppc-report/:id",
    component: <LeaseApplicationPPCReport />,
    exact: true,
  },
  {
    path: "/lease-application/ppc-schedule/:id",
    component: <LeaseApplicationSetPPCMeeting />,
    exact: true,
  },
  {
    path: "/lease-application/decision-report/:id",
    component: <LeaseApplicationDecisionReport />,
    exact: true,
  },
  {
    path: "/lease-application/decision-schedule/:id",
    component: <LeaseApplicationDecisionScheduleDetails />,
    exact: true,
  },
  {
    path: "/lease-application/decide/:id",
    component: <LeaseApplicationPermitDecide />,
    exact: true,
  },
  {
    path: "/lease-application/reviewers/:id",
    component: <LeaseApplicationReviewers />,
    exact: true,
  },
  {
    path: "/lease-application/multiple-scheduling",
    component: <LeaseApplicationSetMultipleScheduling />,
    exact: true,
  },
  {
    path: "/lease-application/update/:id/:status",
    component: <LeaseApplicationEdit />,
    exact: true,
  },

  // Permit questions
  {
    path: `/${URL_PERMIT_QUESTION}/create`,
    component: <SetPermitQuestion />,
    exact: true,
  },
  {
    path: `/${URL_PERMIT_QUESTION}/view/:id`,
    component: <PermitQuestionDetails />,
    exact: true,
  },
  {
    path: `/${URL_PERMIT_QUESTION}/update/:id`,
    component: <SetPermitQuestion />,
    exact: true,
  },
  {
    path: URL_PERMIT_QUESTION,
    component: <PermitQuestionsRegister />,
    exact: true,
  },

  // Site Inspection questions
  {
    path: `/${URL_SITE_INSPECTION_QUESTION}/create`,
    component: <SetSiteInspectionQuestion />,
    exact: true,
  },
  {
    path: `/${URL_SITE_INSPECTION_QUESTION}/view/:id`,
    component: <SiteInspectionQuestionDetails />,
    exact: true,
  },
  {
    path: `/${URL_SITE_INSPECTION_QUESTION}/update/:id`,
    component: <SetSiteInspectionQuestion />,
    exact: true,
  },
  {
    path: URL_SITE_INSPECTION_QUESTION,
    component: <SiteInspectionQuestionsRegister />,
    exact: true,
  },

  // Site Inspection Answers
  {
    path: `/${URL_SITE_INSPECTION_ANSWER}/create`,
    component: <SetSiteInspectionAnswer />,
    exact: true,
  },
  {
    path: `/${URL_SITE_INSPECTION_ANSWER}/view/:id`,
    component: <SiteInspectionAnswerDetails />,
    exact: true,
  },
  {
    path: `/${URL_SITE_INSPECTION_ANSWER}/update/:id`,
    component: <SetSiteInspectionAnswer />,
    exact: true,
  },
  {
    path: URL_SITE_INSPECTION_ANSWER,
    component: <SiteInspectionAnswersRegister />,
    exact: true,
  },
  // Payments
  {
    path: "/payments",
    component: <PaymentsRegister />,
    exact: true,
  },
  {
    path: "/prns",
    component: <PrnsRegister />,
    exact: true,
  },

  {
    path: "/register-payment",
    component: <PaymentsSearch />,
    exact: true,
  },
  {
    path: "/ledgers",
    component: <LedgerRegister />,
    exact: true,
  },
  {
    path: "/general-ledger",
    component: <GeneralLedgerRegister />,
    exact: true,
  },

  {
    path: "/arrears-ledger",
    component: <ArrearsLedgerRegister />,
    exact: true,
  },

  {
    path: "/income-ledger",
    component: <RevenueLedgerRegister />,
    exact: true,
  },

  {
    path: "/ledgers/view/:id",
    component: <LedgerDetails />,
    exact: true,
  },

  {
    path: "/general-ledger/view/:id",
    component: <GeneralLedgerDetails />,
    exact: true,
  },
  {
    path: "/arrears-ledger/view/:id",
    component: <ArrearsLedgerDetails />,
    exact: true,
  },
  {
    path: "/arrears-ledger/view/:id",
    component: <ArrearsLedgerDetails />,
    exact: true,
  },

  {
    path: "/general-ledger/",
    component: <LedgerGeneralView />,
    exact: true,
  },

  {
    path: "/taxpayer-register",
    component: <TaxPayerRegister />,
    exact: true,
  },

  /** Markets related */
  // Market due categories
  {
    path: "/market-due-categories",
    component: <MarketDueCategoryRegister />,
    exact: true,
  },
  {
    path: "/market-due-categories/create",
    component: <SetMarketDueCategory />,
    exact: true,
  },
  {
    path: "/market-due-categories/view/:id",
    component: <MarketDueCategoryDetails />,
    exact: true,
  },
  {
    path: "/market-due-categories/update/:id",
    component: <SetMarketDueCategory />,
    exact: true,
  },

  // Assessments and billing
  {
    path: "/invoices",
    component: <InvoiceList />,
    exact: true,
  },
  {
    path: "/assessments",
    component: <AssessmentsRegister />,
    exact: true,
  },

  // Assessed return periods
  {
    path: "/assessed-return-period",
    component: <AssessedReturnPeriods />,
    exact: true,
  },
  {
    path: "/assessed-return-period/:revenue_source/:id/:tax",
    component: <ReturnPeriodsRegister />,
    exact: true,
  },

  {
    path: "/paf/create/multiple",
    component: <PafMultiple />,
    exact: true,
  },

  {
    path: "/trading-license/view/:period_id/:id",
    component: <TradeLicenseAssessment />,
    exact: true,
  },

  {
    path: "/paf/create/invoice/:invoice_id/:tendered/:amount",
    component: <Paf />,
    exact: true,
  },
  {
    path: "/paf/create/invoice/vehicle/:advanced/:invoice_id",
    component: <Paf />,
    exact: true,
  },
  {
    path: "/paf/create/invoice/:tax/:invoice_id",
    component: <Paf />,
    exact: true,
  },

  ////Vehicle Assessment
  {
    path: "/advance-income",
    component: <AdvanceIncomeRegister />,
    exact: true,
  },
  {
    path: "/advance-income/create",
    component: <SetAdvanceIncome />,
    exact: true,
  },
  {
    path: "/advance-income/view/:id",
    component: <AdvanceIncomeDetails />,
    exact: true,
  },
  {
    path: "/advanced-tax-income/update/:id",
    component: <SetAdvanceIncome />,
    exact: true,
  },

  ////Vehicle Tax Rates
  {
    path: "/vehicle-tax-rates",
    component: <VehicleTaxRatesRegister />,
    exact: true,
  },
  {
    path: "/vehicle-tax-rates/create",
    component: <SetVehicleTaxRates />,
    exact: true,
  },
  {
    path: "/vehicle-tax-rates/view/:id",
    component: <VehicleTaxRatesDetails />,
    exact: true,
  },
  {
    path: "/vehicle-tax-rates/update/:id",
    component: <SetVehicleTaxRates />,
    exact: true,
  },

  // Logout
  {
    path: "/logout",
    component: <LogoutRoute />,
    exact: true,
  },

  // Reports
  {
    path: "/reports/summary",
    component: <ReportsSummary status={STATUS_SUMMARY} />,
    exact: true,
  },
  {
    path: "/reports/summary/fys",
    component: <ReportsSummaryFys />,
    exact: true,
  },
  {
    path: "reports/forecast",
    component: <RevenueForecast />,
    exact: true,
  },

  {
    path: "reports/budget-performance",
    component: <BudgetPerformance />,
    exact: true,
  },
  {
    path: "reports/development-control",
    component: <DashboardContent status={STATUS_DEVELOPMENT_CONTROL} />,
    exact: true,
  },
  {
    path: "reports/ifms",
    component: <DashboardContent status={STATUS_IFMS} />,
    exact: true,
  },
  {
    path: "reports/ifms-lgs",
    component: <DashboardContent status={STATUS_IFMS_LG} />,
    exact: true,
  },

  // TIN REGISTRATION
  {
    path: "/instant-tin/create",
    component: <SetInstantTinForm />,
    exact: true,
  },

  {
    path: "/instant-tin/register",
    component: <InstantTinRegister />,
    exact: true,
  },

  // LOCAL GOVERNMENT PROFILE
  {
    path: "/local-government-profile/create",
    component: <SetLocalGovernmentProfile />,
    exact: true,
  },

  {
    path: "/local-government-profile/update/:id",
    component: <SetLocalGovernmentProfile />,
    exact: true,
  },

  {
    path: "/local-government-profile",
    component: <LocalGovernmentProfileRegister />,
    exact: true,
  },

  {
    path: "/local-government-profile/view/:id",
    component: <LocalGovernmentProfilePosts />,
    exact: true,
  },

  // PROFILE TYPES
  {
    path: "/profile-types/update/:id",
    component: <SetProfileTypes />,
    exact: true,
  },
  {
    path: "/profile-types/create",
    component: <SetProfileTypes />,
    exact: true,
  },
  {
    path: "/profile-types",
    component: <ProfileTypesRegister />,
    exact: true,
  },

  // Logos and signatures
  {
    path: "/logo-signature/upload",
    component: <LogoSignatureForm />,
    exact: true,
  },
  {
    path: "/logo-signature/register",
    component: <LogoSignatureRegister />,
    exact: true,
  },
  {
    path: "/logo-signature/view",
    component: <LogoSignatureDetails />,
    exact: true,
  },

  // Tax Heads
  {
    path: "/tax-head/create",
    component: <SetTaxHead />,
    exact: true,
  },
  {
    path: "/tax-head/view/:id",
    component: <TaxHeadDetails />,
    exact: true,
  },
  {
    path: "/tax-head/update/:tax_head/:id",
    component: <SetTaxHead />,
    exact: true,
  },
  {
    path: "/tax-head",
    component: <TaxHeadsRegister />,
    exact: true,
  },
  // NEWS
  {
    path: "/news/create",
    component: <SetNews />,
    exact: true,
  },
  {
    path: "/news/update/:id",
    component: <SetNews />,
    exact: true,
  },
  {
    path: "/news/view/:id",
    component: <NewsDetails />,
    exact: true,
  },
  {
    path: "/news/register",
    component: <NewsRegister />,
    exact: true,
  },
  ...PeriodRoutes,
  // CERTIFICATE DETAILS
  {
    path: "output/:source/:id",
    component: <OutputDetails />,
    exact: true,
  },
];

export const guestRoutes = [
  {
    path: "/",
    component: <Landing />,
    exact: true,
  },
  {
    path: loginPath,
    component: <Login />,
    exact: true,
  },
  {
    path: registerPath,
    component: <Login />,
    exact: true,
  },
  {
    path: "/password-reset/:token",
    component: <Login />,
    exact: true,
  },
  {
    path: "/local-government-profile/view/:id",
    component: <LocalGovernmentProfilePosts />,
    exact: true,
  },
  {
    path: "/local-government-profile/view/:id/:post",
    component: <LGPostView />,
    exact: true,
  },
  {
    path: "/ontrs/create",
    component: <OntrFormExternal />,
    exact: true,
  },
  //GRIEVANCES
  {
    path: "/grievances/create",
    component: <EnquiresFormExternal />,
    exact: true,
  },
  {
    path: otpPath,
    component: <Login />,
    exact: true,
  },
  {
    path: setPasswordPath,
    component: <Login />,
    exact: true,
  },
  // NEWS
  {
    path: "/news",
    component: <News />,
    exact: true,
  },
  //INSTANT TIN
  {
    path: "/instant-tin/create",
    component: <SetInstantTinForm />,
    exact: true,
  },
  // ADVANCEDINCOMETAX
  {
    path: "/guest/advance-income/create",
    component: <SetAdvanceIncome showHeader/>,
  },
  // CERTIFICATE DETAILS
  {
    path: "output/:source/:id",
    component: <OutputDetails />,
    exact: true,
  },
];
