import styled from "styled-components";
import { configColor } from "../../styled/Theme";
import LogoComponent from "../../common/logo/LogoComponent";
import {
  AccountCircle,
  ArticleOutlined,
  KeyboardArrowDown,
  MoreVert,
  Notifications,
  Settings,
} from "@mui/icons-material";
import { Badge, Box, IconButton } from "@mui/material";
import { DropdownPopup } from "../../common/dropdowns/DropdownPopup";
import { account, reports, reportsOptions } from "../../../constants/settings";
import { DrawerComponent } from "../../common/drawer/DrawerComponent";
import { OptionsMenu } from "../../pages/dashboard/OptionsMenu";
import { NotificationsList } from "../../pages/dashboard/NotificationsList";
import { SettingsList } from "../../pages/dashboard/SettingsList";
import useUsers from "../../../hooks/users/useUsers";
import LeftDrawer from "../../pages/dashboard/LeftDrawer";
import { useAuth } from "../../../context/authContext";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { CircularLoader } from "../../loaders/CircularLoader";
import useGenericGet from "../../../hooks/common/useGenericGet";
import {
  DEVELOPMENT_CONTROL_ACTORS_PERMISSIONS_API,
  NOTIFICATIONS_API,
} from "../../../constants/api";
import { FlagIndicator } from "./FlagIndicator";
import {
  GetPermissions,
  developmentControlPermissions,
  level1Permissions,
  mainPermissions,
} from "../../../utils/permissionsUtils";
import {
  GetPermissions as GetPermissionsRoles,
  HasUserType,
  IsAuditAuthority,
} from "../../../utils/authUtils";
import { sPropertyValuation } from "../../layouts/LeftMenu";
import { AUDIT_AUTHORITY, MIS_AUTHORITY } from "../../../constants/webapp";

export function NotificationOption({ notifications, count, setCount }) {
  return (
    <DrawerComponent
      title="Notifications"
      button={
        <IconButton>
          <Badge badgeContent={count} color="error">
            <Notifications />
          </Badge>
        </IconButton>
      }
    >
      <NotificationsList
        notifications={notifications}
        count={count}
        setCount={setCount}
      />
    </DrawerComponent>
  );
}

export function SettingsOption() {
  return (
    <DrawerComponent
      title="Settings"
      button={
        <IconButton>
          <Settings />
        </IconButton>
      }
    >
      <SettingsList />
    </DrawerComponent>
  );
}

export default function DashboardNavbar() {
  const { profileData } = useUsers();
  const { user, userData } = useAuth();
  const { updateContext } = useAppContext();
  const { loadData: getActorDetails, data: actor_details } = useGenericGet();
  const { data: notifications, loadData: getNotifications } = useGenericGet();
  const [count, setCount] = useState(0);

  useEffect(() => {
    getNotifications({ api: NOTIFICATIONS_API });
  }, []);

  // update notification count
  useEffect(() => {
    if (notifications?.results) {
      var count = 0;
      notifications?.results.map((notification) => {
        if (!notification?.is_read) {
          count = count + 1;
        }
      });
      setCount(count);
    }
  }, [notifications]);

  const data = {
    email: profileData().email,
    name: profileData().first_name || profileData().mobile_number,
    role: userData?.role_details?.name || <CircularLoader size={15} />,
    municipality: userData?.profile?.municipality_name || "Region / Location",
    division_name: userData?.profile?.division_name,
  };

  useEffect(() => {
    if (user?.id && userData?.role_details?.can_public === 0)
      getActorDetails({
        api: DEVELOPMENT_CONTROL_ACTORS_PERMISSIONS_API,
        params: { actor: user?.id },
      });
  }, [user, userData]);

  useEffect(() => {
    if (userData?.role_details?.can_admin === 1) {
      updateContext("can_admin", 1);
    }
    if (userData?.role_details?.app_can_admin === 1) {
      updateContext("app_can_admin", 1);
    }
    // save role name
    updateContext("role_name", userData?.role_details?.name);
    // save role details
    updateContext("role_details", userData?.role_details);

    // save municipality details
    let municipality = user?.profile?.municipality;
    if (municipality) updateContext("municipality", municipality);

    // save actor details
    if (actor_details) updateContext("actor_details", actor_details?.permitted);
  }, [userData, user, actor_details]);

  function getDivisionDetails() {
    let division = data?.division_name;
    if (division) return "| " + division;
    return "";
  }

  return (
    <Wrapper className="flexCenter animate whiteBg" style={{ height: "70px" }}>
      <NavInner className="flexSpaceCenter">
        <div className="flexCenter gap10">
          <MenuWrapper>
            <LeftDrawer />
          </MenuWrapper>
          <LogoComponent height={60} />
          <Box width={10} />

          <UlWrapper className="flexCenter gap10">
            {/* Role Card */}
            <RoundedContainer className="animate pointer">
              <div className="font13 semiBold">{data.role}</div>
              <div className="font11">{`${
                data.municipality
              } ${getDivisionDetails()}`}</div>
            </RoundedContainer>

            {GetPermissions([
              ...mainPermissions,
              ...developmentControlPermissions,
            ]) ||
            GetPermissionsRoles(sPropertyValuation) ||
            HasUserType([AUDIT_AUTHORITY, MIS_AUTHORITY]) ? (
              <DropdownPopup menu={reportsOptions()}>
                {/* Reports Card */}
                <RoundedContainer className="animate pointer flexCenter">
                  <ArticleOutlined sx={{ ml: -1, mr: 1, fontSize: 20 }} />
                  <div className="font13 semiBold">Reports</div>
                  <KeyboardArrowDown
                    fontSize="small"
                    style={{
                      marginLeft: 5,
                      marginRight: -10,
                      marginTop: -2,
                    }}
                  />
                </RoundedContainer>
              </DropdownPopup>
            ) : null}
          </UlWrapper>
        </div>

        <UlWrapper className="flexCenter gap10">
          {/* Flag Indicator */}
          <FlagIndicator />

          {/* Notification Option */}
          <NotificationOption
            notifications={notifications}
            count={count}
            setCount={setCount}
          />

          {/* Settings Option */}
          {GetPermissions(level1Permissions) && <SettingsOption />}

          <DropdownPopup menu={account}>
            {/* Account Card */}
            <RoundedContainer className="flex gap10 animate pointer">
              <AccountCircle sx={{ ml: -1 }} />
              <div>
                <div className="font13 semiBold">{data.name}</div>
                <div
                  className="font11"
                  style={{
                    maxWidth: 100,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {data.email}
                </div>
              </div>
              <KeyboardArrowDown
                fontSize="small"
                style={{
                  marginRight: -10,
                  marginTop: -2,
                }}
              />
            </RoundedContainer>
          </DropdownPopup>
        </UlWrapper>

        {/* Mobile Options */}
        <MobileWrapper className="flexCenter">
          <NotificationOption />
          <DropdownPopup menu={account}>
            <IconButton>
              <AccountCircle />
            </IconButton>
          </DropdownPopup>

          {GetPermissions([
            ...mainPermissions,
            ...developmentControlPermissions,
          ]) || GetPermissionsRoles(sPropertyValuation) ? (
            <DrawerComponent
              title="More Settings"
              button={
                <IconButton>
                  <MoreVert />
                </IconButton>
              }
            >
              <OptionsMenu />
            </DrawerComponent>
          ) : null}
        </MobileWrapper>
      </NavInner>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  border-bottom: 1px solid #ddd;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  width: 100%;
  padding: 0 30px;
  position: relative;
  height: 100%;
  @media (max-width: 990px) {
    padding: 0 10px;
  }
`;

const RoundedContainer = styled.div`
  padding: 7px 20px;
  line-height: 1.4;
  min-height: 45px;
  background: #f2f2f2f2;
  align-items: center;
  border-radius: 50px;
  :hover {
    background: ${configColor};
    color: white};
  }
`;
const UlWrapper = styled.ul`
  @media (max-width: 990px) {
    display: none;
  }
`;

const MobileWrapper = styled.ul`
  display: none;
  @media (max-width: 990px) {
    display: flex;
  }
`;

const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
  }
`;
