import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useForm from "../../../hooks/common/useForm";
import {
  CONFIGURABLES_API,
  PRESUMPTIVE_TAX_ASSESSMENT_API,
  TRADING_LICENSE_AMOUNT_API,
  TURNOVER_THRESHOLD_API,
} from "../../../constants/api";
import {
  APPLICATION_FEES,
  SIGN_FEES,
  WASTE_FEES,
} from "../../../constants/webapp";
import useAssessments from "../../../hooks/billing/useAssessments";
import { TRADING_LICENSE_API } from "../../../constants/api";
import Loader from "../../common/Loader";
import useGenericSet from "../../../hooks/common/useGenericSet";
import PaymentModeInput from "../../common/PaymentModeInput";
import toast from "react-hot-toast";

const TradeLicenseAssessment = ({ tax_id, formId, proceed }) => {
  const { form, updateFormValue } = useForm();
  const { getUsers } = useAssessments();
  const { loadData: getTurnoverThreshold } = useGenericGet();
  const { loadData: getApplicationFees } = useGenericGet();
  const { loadData: getWasteFees } = useGenericGet();
  const { loadData: getSignFees } = useGenericGet();
  const { loadData: getLicenseAmount } = useGenericGet();
  const {
    uploadData: generatePresumptiveAssessment,
    error: presError,
    data: presumptiveAmount,
    submitting: loadingPresumptive,
  } = useGenericSet();
  const { loadData, data: tradinglicense, loading } = useGenericGet();
  const [totalPayableAmount, setTotalPayableAmount] = useState(
    Number(tradinglicense?.assessed_value || 0) +
      Number(presumptiveAmount?.amount || 0)
  );

  useEffect(() => {
    if (tax_id) {
      loadData({ api: TRADING_LICENSE_API, id: tax_id });
    }
  }, [tax_id]);

  useEffect(() => {
    getTurnoverThreshold({ api: TURNOVER_THRESHOLD_API });
    getApplicationFees({
      api: CONFIGURABLES_API,
      params: { field: APPLICATION_FEES },
    });
    getWasteFees({ api: CONFIGURABLES_API, params: { field: WASTE_FEES } });
    getSignFees({ api: CONFIGURABLES_API, params: { field: SIGN_FEES } });
    getUsers();
    getLicenseAmount({
      api: TRADING_LICENSE_AMOUNT_API,
      params: {
        grade: tradinglicense?.street_details?.grade,
        category: tradinglicense?.trade_sub_category_details?.id,
      },
    });
  }, []);

  useEffect(() => {
    if(presError) {
      console.log(presError);
      toast.error(presError?.detail? String(presError?.detail): 'Failed to assess presumptive tax')
      
    }
  }, [presError])

  const getPresumptiveAmount = (amount = form?.estimated_gross_sales || 0) => {
    if (amount && (amount >= 10000000 ) && (amount <= 150000000)) {
      generatePresumptiveAssessment({
        api: PRESUMPTIVE_TAX_ASSESSMENT_API,
        params: {
          turnOver: amount,
          // TODO: Handle when there is no tin on the trading license
          tin: tradinglicense?.tin,
        },
      });
    } else {
      updateFormValue("amount", 0)
    }
  };

  useEffect(() => {
    getPresumptiveAmount();
  }, [tradinglicense]);

  useEffect(() => {
    updateFormValue("r_turn_amount", form?.turnover_threshold);
  }, [form?.turnover_threshold]);

  useEffect(() => {
    setTotalPayableAmount(
      Number(tradinglicense?.assessed_value || 0) +
        Number(presumptiveAmount?.amount || 0)
    );
  }, [presumptiveAmount?.amount, tradinglicense?.assessed_value]);

  const handleTurnOverAmountChange = (e) => {
    // turn value to number
    const value = Number(e.target.value);
    updateFormValue("estimated_gross_sales", value);
    if (value === 0) {
      updateFormValue("estimated_gross_sales", "");
    }
    getPresumptiveAmount(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    // if any value in the form is empty, or 0, remove it from the form
    if (form?.estimated_gross_sales >= 10000000 && form?.estimated_gross_sales <= 150000000) {
      if (!presumptiveAmount?.amount) {
        return;
      }
      form["presumptive_tax_amount"] = Number(presumptiveAmount?.amount);
    }
    const validatedForm = Object.keys(form).reduce((acc, key) => {
      if (form[key] !== "" && form[key] !== 0 && form[key] !== null) {
        acc[key] = form[key];
      }
      return acc;
    }, {});
    proceed({
      ...validatedForm,
      amount: tradinglicense?.assessed_value,
      revenue_source: "trade_license",
    });
  };

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Trade License</div>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-lg-6">
              <FormInput
                label="Grade"
                value={tradinglicense?.grade || ""}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Trading Fee"
                value={Number(
                  tradinglicense?.r_trd_amount || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Business Turnover"
                value={tradinglicense?.r_lst_threshold_details?.name || ""}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="LST Amount"
                value={Number(
                  tradinglicense?.r_turn_amount || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <FormInput
                label="Application Fees"
                value={Number(tradinglicense?.r_app_fees || 0).toLocaleString()}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Waste Fees"
                value={Number(
                  tradinglicense?.r_waste_fees || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Sign Fees"
                value={Number(
                  tradinglicense?.r_sky_signs || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>

            <div className="col-lg-6">
              <FormInput
                label="Trade License Amount"
                value={Number(
                  tradinglicense?.assessed_value || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Gross Turnover Amount"
                key="estimated_gross_sales"
                value={form?.estimated_gross_sales}
                onChange={(e) => handleTurnOverAmountChange(e)}
                required
              />
            </div>
            {form?.estimated_gross_sales >= 10000000 && form?.estimated_gross_sales <= 150000000 && (
              <div className="col-lg-6">
                {loadingPresumptive ? (
                  <div>Loading...</div>
                ) : (
                  <FormInput
                    label="Presumptive Tax Amount"
                    value={Number(
                      presumptiveAmount?.amount || 0
                    ).toLocaleString()}
                    disabled
                  />
                )}
              </div>
            )}
            <div className="col-lg-6">
              <FormInput
                label="Total Amount Payable"
                key="total_amount_payable"
                value={Number(totalPayableAmount || 0).toLocaleString()}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <PaymentModeInput
        form={form}
        updateFormValue={updateFormValue}
        required
      />
    </form>
  );
};

TradeLicenseAssessment.propTypes = {
  tradinglicense: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

export default TradeLicenseAssessment;
