import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import SelectInput from "./SelectInput";

const { RangePicker } = DatePicker;

const TableFilter = (props) => {
  const {
    name,
    onChange,
    updateFormValues,
    filters,
    type,
    options: propOptions,
    ...rest
  } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (propOptions) {
      setOptions(
        (propOptions || []).map((stub) => ({
          value: stub.id,
          label: stub.name,
        }))
      );
    }
  }, [propOptions]);

  if (type === "select")
    return (
      <SelectInput
        showSearch
        options={options}
        name={name}
        value={filters[name] || ""}
        onChange={(value) => onChange(name, value)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    );

  if (type === "date") {
    return (
      <RangePicker
        onChange={(value) =>
          updateFormValues({
            min_date: value?.[0].format("YYYY-MM-DD"),
            max_date: value?.[1].format("YYYY-MM-DD"),
          })
        }
        style={{ height: 40 }}
      />
    );
  }

  if (type === "expiryDate") {
    return (
      <RangePicker
        onChange={(value) =>
          updateFormValues({
            expiry_date_after: value?.[0].format("YYYY-MM-DD"),
            expiry_date_before: value?.[1].format("YYYY-MM-DD"),
          })
        }
        style={{ height: 40 }}
      />
    );
  }

  if (type === "licenseValidity") {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "5px" }}>
            <input
              type="text"
              className="form-control"
              placeholder="min"
              name="license_validity_min"
              onBlur={(event) => onChange(name, event.target.value)}
              onKeyDown={(event) =>
                event.key === "Enter" || event.key === "Next"
                  ? onChange("license_validity_min", event.target.value)
                  : ""
              }
            />
          </div>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="max"
              name="license_validity_max"
              onBlur={(event) => onChange(name, event.target.value)}
              onKeyDown={(event) =>
                event.key === "Enter" || event.key === "Next"
                  ? onChange("license_validity_max", event.target.value)
                  : ""
              }
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <input
      type="text"
      className="form-control"
      name={name}
      onBlur={(event) => onChange(name, event.target.value)}
      onKeyDown={(event) =>
        event.key === "Enter" || event.key === "Next"
          ? onChange(name, event.target.value)
          : ""
      }
    />
  );
};

TableFilter.defaultProps = {
  filters: {},
};

TableFilter.propTypes = {
  filters: PropTypes.shape({}),
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  updateFormValues: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TableFilter;
