import { useEffect } from "react";
import {
  API_ADVANCE_INCOME,
  API_ALL_BANK_CODES,
  CONFIGURABLES_API,
  PERIOD_API,
} from "../../../constants/api";
import useForm from "../../../hooks/common/useForm";
import useGenericGet from "../../../hooks/common/useGenericGet";
import { Visibility } from "../../common/Visibility";
import SelectInput from "../../common/SelectInput";
import {
  countries,
  modes,
  registrationTypes,
} from "../../../constants/advance-income";
import Loader from "../../common/Loader";
import FormInput from "../../common/FormInput";
import { VEHICLE_CATEGORY } from "../../../constants/webapp";

const AdvanceIncomeAssessment = ({
  tax_id,
  formId,
  proceed,
  error,
  period: period_id,
}) => {
  const { form, updateFormValue, onChange } = useForm();
  const { loadData, data: advIncome, loading } = useGenericGet();
  const { data: categories, loadData: getCategories } = useGenericGet();
  const { data: period, loadData: getPeriod } = useGenericGet();
  const { data: purposes, loadData: getPurposes } = useGenericGet();
  const { data: bankCodes, loadData: getBankCodes } = useGenericGet();

  useEffect(() => {
    if (tax_id) {
      loadData({ api: API_ADVANCE_INCOME, id: tax_id });
    }
  }, [tax_id]);

  useEffect(() => {
    getCategories({
      api: CONFIGURABLES_API,
      params: { field: VEHICLE_CATEGORY },
    });
    getPeriod({ api: PERIOD_API, id: period_id });
    getPurposes({
      api: CONFIGURABLES_API,
      params: { field: "Vehicle Purpose" },
    });
    getBankCodes({ api: API_ALL_BANK_CODES });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ amount: advIncome?.amount });
  };

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">
            Generate Bill for Advance Income Tax
          </div>
        </div>

        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">
              SECTION B: MOTORVEHICLE VEHICLE DETAILS
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <FormInput
                label="Number plate"
                name="number_plate"
                value={advIncome?.number_plate}
                required={true}
                disabled={true}
              />
            </div>
            <div className="col-sm-4">
              <SelectInput
                label="Country"
                name="country_of_reg"
                onChange={(value) => updateFormValue("country_of_reg", value)}
                options={countries.map((item) => ({
                  value: item.code,
                  label: item.name,
                }))}
                value={advIncome?.country_of_reg}
                error={error}
                disabled={true}
              />
            </div>

            <div className="col-sm-4">
              <SelectInput
                label="Category"
                name="category"
                onChange={(value) => updateFormValue("category", value)}
                options={categories?.results?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={advIncome?.category}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <SelectInput
                label="Purpose"
                name="purpose"
                onChange={(value) => updateFormValue("purpose", value)}
                options={purposes?.results?.map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
                value={advIncome?.purpose}
                disabled={true}
              />
            </div>
            <div className="col-sm-4">
              <FormInput
                label="Seating Capacity"
                type="number"
                name="seating_capacity"
                onChange={onChange}
                value={advIncome?.seating_capacity}
                error={error}
                readOnly={true}
              />
            </div>

            <div class="col-sm-4">
              <FormInput
                label="Tonnage"
                type="number"
                name="tonnage"
                onChange={onChange}
                value={advIncome?.tonnage}
                error={error}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">SECTION E: ASSESSMENT DETAILS</div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-sm-6">
                <SelectInput
                  options={(period ? [period] : [])?.map((sup) => ({
                    label: sup?.name,
                    value: sup?.id,
                  }))}
                  name="period"
                  label="Assessment Period"
                  onChange={({ value }) => updateFormValue("period", value)}
                  value={period_id}
                  error={error}
                  required
                  labelInValue
                  disabled={true}
                />
              </div>
              {/* <div className="col-sm-4">
                  <FormInput
                    label="Tax Rate"
                    type="text"
                    name="tax_rate"
                    onChange={onChange}
                    value={form?.tax_rate}
                    error={error}
                    disabled={true}
                  />
                </div> */}
              <div className="col-sm-6">
                <FormInput
                  label="Amount Payable"
                  type="text"
                  name="amount_payable"
                  onChange={onChange}
                  value={Number(advIncome?.amount || 0).toLocaleString()}
                  error={error}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">Section F: PAYMENT DETAILS</div>
          </div>

          <div className="panel-body">
            {/* <FormInput
                value={Number(form.amount_payable || 0).toLocaleString()}
                readOnly
                label="Amount"
                name="amount"
                error={error}
              /> */}

            {modes.map((mode, idx) => (
              <div className="form-group field-paf-payment_mode" key={idx}>
                <label>
                  <input
                    name="payment_mode"
                    type="radio"
                    onClick={() => updateFormValue("payment_mode", mode.value)}
                    required
                  />{" "}
                  {mode.name}
                </label>
                <div className="help-block" />
              </div>
            ))}

            <Visibility show={form?.payment_mode === "EFT"}>
              <SelectInput
                options={(bankCodes || []).map((sup) => ({
                  label: sup.bank_name,
                  value: sup.code,
                }))}
                id="bank_code"
                name="bank_code"
                label="URA Banks"
                onChange={(value) => updateFormValue("bank_code", value)}
                value={form?.bank_code || ""}
                maxMenuHeight={250}
                error={error}
                required
              />
            </Visibility>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdvanceIncomeAssessment;
