import {
  APP_CAN_APPROVE,
  CAN_ADMIN,
  CAN_DIVISION,
  CAN_MUNICIPALITY,
  CAN_PARISH,
  CAN_PHYSICAL_PLANNING,
  CAN_PHYSICAL_PLANNING_DIVISION,
  VALUATION_APPROVER,
} from "../constants/webapp";
import { useAppContext } from "../context/AppContext";
import { useAuth } from "../context/authContext";

// permission set
export const mainPermissions = [
  CAN_ADMIN,
  CAN_MUNICIPALITY,
  CAN_DIVISION,
  CAN_PARISH,
  APP_CAN_APPROVE,
];
// level 1 permissions
export const level1Permissions = [CAN_ADMIN, CAN_MUNICIPALITY];
// for dev control
export const developmentControlPermissions = [
  CAN_PHYSICAL_PLANNING,
  CAN_PHYSICAL_PLANNING_DIVISION,
];

export const GetPermissions = (keys = []) => {
  let visible = false;
  const { contextData } = useAppContext() || {};
  const { role_details } = contextData;

  if (keys?.length > 0) {
    keys?.some(
      (key) =>
        (visible = role_details?.[key] === 1 || role_details?.[key] === true)
    );
  }

  return visible;
};

export const CreatedByUser = (idData, isTrueId) => {
  if (!isTrueId) {
    idData = idData?.created_by?.id;
  }
  // if application is created by logged in user, return false
  const { user } = useAuth();
  const uid = user?.id;
  if (idData !== uid) return true;
  return false;
};

/* 

app_can_admin
: 
0
app_can_approve
: 
1
app_can_public
: 
0
app_can_register
: 
0
can_adhoc
: 
null
can_admin
: 
1
can_approve_building_operation
: 
0
can_approve_condominium_permit
: 
null
can_approve_demolition_permit
: 
null
can_approve_development_permit
: 
0
can_approve_excavation_permit
: 
null
can_approve_lease_application
: 
null
can_approve_outdoor_advertisement_permit
: 
null
can_approve_renovation_permit
: 
null
can_approve_subdivision_consolidation
: 
null
can_collect
: 
1
can_collect_tax
: 
1
can_dashboard
: 
null
can_division
: 
1
can_enumerate
: 
1
can_forest
: 
null
can_managetaxpayer
: 
0
can_market_admin
: 
0
can_municipality
: 
1
can_parish
: 
0
can_physical_planning
: 
null
can_public
: 
0
can_receipt
: 
null
can_register_planning
: 
0
can_registertax
: 
0
can_street
: 
0
can_viewglobal
: 
1
can_village
: 
0

*/

export const IsValuationApprover = () => {
  const { user } = useAuth();
  return user?.role?.name === VALUATION_APPROVER;
};

export const IsSuperUser = () => {
  const { user } = useAuth();
  console.log(user);
  return user?.is_superuser;
}
