import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// eslint-disable-line import/no-webpack-loader-syntax
// eslint-disable-next-line
import mapboxgl from "!mapbox-gl";
import { useToasts } from "react-toast-notifications";

mapboxgl.accessToken =
  "pk.eyJ1IjoieW95YWRldmVsb3AiLCJhIjoiY2x5NWs2Y29zMDBkbjJscXV0YjB5cDFkdiJ9.C8tAi6NbfM00LjxjkqB05A";

const styles = {
  menu: {
    position: "absolute",
    background: "#efefef",
    padding: "10px",
    fontFamily: "Open Sans, sans-serif",
    position: "relative",
  },
};

/* Given a query in the form "lng, lat" or "lat, lng"
 * returns the matching geographic coordinate(s)
 * as search results in carmen geojson format,
 * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
const coordinatesGeocoder = (query) => {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
    return null;
  }

  const coordinateFeature = (lng, lat) => {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place_name: ` Lat: " ${lat} Lng: ${lng}`,
      place_type: ["coordinate"],
      properties: {},
      type: "Feature",
    };
  };

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  geocodes.push(coordinateFeature(coord2, coord1));

  return geocodes;
};

const MapContainer = (props) => {
  const { onUpdateValues, longitude, latitude } = props;
  const [error, setError] = useState(false);
  const { addToast } = useToasts();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);

  const [lng, setLng] = useState(32.5755);
  const [lat, setLat] = useState(0.3334);
  const zoom = 16;
  const [selectedOption, setSelectedOption] = useState("satellite");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [lng, lat],
      // maxBounds: bounds,
      zoom,
    });

    const layerList = document.getElementById("menu");
    const inputs = layerList.getElementsByTagName("input");

    for (const input of inputs) {
      input.onclick = (layer) => {
        const layerId = layer?.target?.id;
        map.current.setStyle("mapbox://styles/mapbox/" + layerId);
      };
    }

    // Add the control to the map.
    map.current.addControl(
      new MapboxGeocoder({
        countries: "ug",
        accessToken: mapboxgl.accessToken,
        localGeocoder: coordinatesGeocoder,
        placeholder: "Search for a location",
        mapboxgl: mapboxgl,
        marker: true,
        draggable: false,
        types:
          "region,place,postcode,locality,neighborhood,address,poi,poi.landmark",
      })
    );

    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    );
    // Add zoom and rotation controls to the map.
    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.addControl(new mapboxgl.FullscreenControl());

    /** On click event */
    map.current.on("style.load", () => {
      map.current.on("click", (e) => {
        const coordinates = e.lngLat;

        setLat(coordinates.lat.toFixed(6));
        setLng(coordinates.lng.toFixed(6));
        onUpdateValues({
          r_lat: coordinates.lat.toFixed(6),
          r_lng: coordinates.lng.toFixed(6),
        });
      });
    });

    try {
      marker.current = new mapboxgl.Marker({
        draggable: false,
      })
        .setLngLat([lng, lat])
        .addTo(map.current);
    } catch (e) {
      setError(true);
      return;
    }
    /** Add dragable marker */

    const onDragEnd = () => {
      const lngLat = marker.current.getLngLat();
      setLat(lngLat.lat.toFixed(6));
      setLng(lngLat.lng.toFixed(6));
      onUpdateValues({
        r_lat: lat.toFixed(6),
        r_lng: lngLat.lng.toFixed(6),
      });
    };

    marker.current.on("dragend", onDragEnd);
  }, []);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
  }, []);

  useEffect(() => {
    if (marker.current && lat && lng) {
      try {
        marker.current.setLngLat([lng, lat]);
      } catch (e) {
        setError(true);

        return;
      }
      // If marker is out of view, re-center
      if (!map.current.getBounds().contains(marker.current.getLngLat())) {
        map.current.setCenter([lng, lat]);
      }
    }
  }, [lng, lat, zoom]);

  useEffect(() => {
    if (longitude !== undefined && latitude !== undefined) {
      setLat(Number(latitude));
      setLng(Number(longitude));

      onUpdateValues({
        r_lat: Number(latitude),
        r_lng: Number(longitude),
      });
    }
  }, [longitude, latitude]);

  useEffect(() => {
    if (error) {
      addToast("Invalid longitude and latitude value ", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [error]);

  return (
    <div className="row">
      <div style={styles.menu} id="menu">
        <input
          id="satellite-streets-v12"
          type="radio"
          name="rtoggle"
          value="satellite"
          checked={selectedOption === "satellite"}
          onChange={handleOptionChange}
        />
        <label for="satellite-streets-v12">satellite streets</label>
        <input
          id="light-v11"
          type="radio"
          name="rtoggle"
          value="light"
          checked={selectedOption === "light"}
          onChange={handleOptionChange}
        />
        <label for="light-v11">light</label>
        <input
          id="dark-v11"
          type="radio"
          name="rtoggle"
          value="dark"
          checked={selectedOption === "dark"}
          onChange={handleOptionChange}
        />
        <label for="dark-v11">dark</label>
        <input
          id="streets-v12"
          type="radio"
          name="rtoggle"
          value="streets"
          checked={selectedOption === "streets"}
          onChange={handleOptionChange}
        />
        <label for="streets-v12">streets</label>
        <input
          id="outdoors-v12"
          type="radio"
          name="rtoggle"
          value="outdoors"
          checked={selectedOption === "outdoors"}
          onChange={handleOptionChange}
        />
        <label for="outdoors-v12">outdoors</label>
      </div>
      <div style={{ marginTop: "3em", marginBottom: "2em" }}>
        <div ref={mapContainer} className="map-container" />
      </div>
    </div>
  );
};

MapContainer.propTypes = {
  onUpdateValues: PropTypes.func,
};

export default MapContainer;
