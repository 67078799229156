import React from "react";
import { Timeline } from "antd";
import { stringify } from "../../utils/helperUtil";
export const TimelineView = ({ data, title }) => {
  const Item = ({ data }) => (
    <div>
      <b className="text-capitalize">{stringify(data?.label)}</b>
      <p>{data?.value}</p>
    </div>
  );

  return (
    <div className="x100 flexColumn gap20">
      {title && <h3>{title}</h3>}
      <Timeline
        items={data?.map((item, i) => {
          return { children: <Item key={i} data={item} /> };
        })}
      />
    </div>
  );
};
