import { sTaxpayerName } from "../components/layouts/LeftMenu";
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_SENT_BACK,
  STATUS_SUBMITTED,
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_REJECTED,
  STATUS_DEFFERED,
  STATUS_PENDING_ASSESSMENT,
  STATUS_UNDER_SCRUTINY,
  STATUS_PENDING_DECISION,
  PAID,
  STATUS_ASSESSED,
} from "../constants/webapp";

export const statusTitle = (status) => {
  switch (status) {
    case STATUS_SUBMITTED:
      return "Pending";

    case STATUS_APPROVED:
      return "Approved";

    case STATUS_REJECTED:
      return "Rejected";

    case STATUS_DEFFERED:
      return "Deferred";

    case STATUS_SENT_BACK:
      return "Sent Back";

    case STATUS_ACTIVE:
      return "Active";

    case STATUS_INACTIVE:
      return "Inactive";

    case STATUS_PENDING:
      return "Pending";

    case STATUS_ASSESSED:
      return "Approved";

    case STATUS_PENDING_ASSESSMENT:
      return "Pending Assesssment";

    case STATUS_UNDER_SCRUTINY:
      return "Under Scrutiny";

    case STATUS_PENDING_DECISION:
      return "Pending Decision";

    case PAID:
      return "Paid";

    default:
      return "";
  }
};

export const paymentStatus = (amount, amount_paid) => {
  let status = null;
  if (amount_paid === 0) {
    status = "Not Paid";
  } else if (amount_paid === amount) {
    status = "Paid";
  } else {
    status = "Partially Paid";
  }
  return status;
};

export const objectionStatuses = (status) => {
  switch (status) {
    case "pending":
      return "Objections";

    case "awaiting_court_hearing":
      return "Awaiting Court Hearing";

    case "approved":
      return "Approved Objections";

    case "rejected":
      return "Rejected Objections";

    case "withdrawn":
      return "Withdrawn Objections";

    case "hearing_schedule_change":
      return "Hearing Date Change";

    case "objector_representative":
      return "Objector Representative";

    case "add_court_proceedings":
      return "Court Proceedings";

    default:
      return "";
  }
};

export const enquiriesStatuses = (status) => {
  switch (status) {
    case "100":
      return "Pending";

    case "101":
      return "Resolved";

    case true:
      return "Overdue";
    default:
      return "";
  }
};

export const taxpayerAmdendables = [
  { id: "mobile_number", field: "mobile_number", type: "tel" },
  { id: "tin", field: "tin", type: "number" },
  { id: "nin", field: "nin", type: "text" },
  { id: "brn", field: "brn", type: "text" },
  { id: sTaxpayerName, field: "owner_name", type: "text" },
  { id: "email", field: "email", type: "text" },
];

export const vehiclePurposes = [
  {id: "PRIVATE", name: "PRIVATE"},
  {id: "COMMERCIAL", name: "COMMERCIAL"},
  {id: "GOVERNMENT", name: "GOVERNMENT"},
]
