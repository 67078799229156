import React, { useEffect } from "react";
import FormInput from "./FormInput";
import { Visibility } from "./Visibility";
import SelectInput from "./SelectInput";
import { modes } from "../../constants/advance-income";
import { API_ALL_BANK_CODES } from "../../constants/api";
import useGenericGet from "../../hooks/common/useGenericGet";

const PaymentModeInput = ({
  form,
  updateFormValue,
  title = "PAYMENT DETAILS",
  showAmount = false,
  error = "",
  required = true,
}) => {
  const { data: bankCodes, loadData: getBankCodes } = useGenericGet();
  useEffect(() => {
    getBankCodes({ api: API_ALL_BANK_CODES });
  }, []);
  return (
    <div className="panel panel-primary">
      <div className="panel-heading">
        <div className="panel-title">{title}</div>
      </div>

      <div className="panel-body">
        {showAmount && (
          <FormInput
            value={Number(form.amount_payable || 0).toLocaleString()}
            readOnly
            label="Amount"
            name="amount"
            error={error}
          />
        )}

        {modes.map((mode, idx) => (
          <div className="form-group field-paf-payment_mode" key={idx}>
            <label>
              <input
                name="payment_mode"
                type="radio"
                onClick={() => updateFormValue("payment_mode", mode.value)}
                required={required}
              />{" "}
              {mode.name}
            </label>
            <div className="help-block" />
          </div>
        ))}

        <Visibility show={form?.payment_mode === "EFT"}>
          <SelectInput
            options={(bankCodes || []).map((sup) => ({
              label: sup.bank_name,
              value: sup.code,
            }))}
            id="bank_code"
            name="bank_code"
            label="URA Banks"
            onChange={(value) => updateFormValue("bank_code", value)}
            value={form?.bank_code || ""}
            maxMenuHeight={250}
            error={error}
            required={required}
          />
        </Visibility>
      </div>
    </div>
  );
};

export default PaymentModeInput;
